import { Form,Select } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";
import getRequiredMsg from "utils/formRequiredMsg";


const { Option } = Select;
const CompanyComponent = ({
  isUserNotFmtv,
  initialValue,
  defaultValue,
  companies
}) => (
  <Form.Item
    label={SETUP_VALUES.COMPANY_TEXT}
    name={SETUP_VALUES.COMPANY_ID_}
    className="campaign-form-labels"
    rules={getRequiredMsg(SETUP_VALUES.ERR_ENTER_COMP, isUserNotFmtv)}
    initialValue={initialValue}
  >
    <Select
      defaultValue={defaultValue}
      disabled={isUserNotFmtv}
    >
      {companies.data.map(({id, company_name}) => (<Option key={id} value={id}>{company_name}</Option>))}
    </Select>
  </Form.Item>
)

export default CompanyComponent;