import React, { useEffect, useState } from "react";
import { Checkbox, notification, Row, Col, Modal, Button, Input } from "antd";
import { getAllVideoTemplates, updateVideoTemplate } from "Requests/video-template";
import { useSelector } from "react-redux";
import { companyName } from "Redux/App";
import Loader from "Pages/Companies/Edit/Loader";
import { PromotionEditor } from "Pages/Promotions/CreatePromotion/utils/promotionEditor";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import { CREATE_PROMOTION_ARCHIVE, CREATE_PROMOTION_RENAME, CREATE_PROMOTION_SUSPEND } from "constants/Templates";

export const TemplateTiles = () => {
  const selectedCompany = useSelector(companyName);
  const [loading, setLoading] = useState(false);
  const [showGeneric, setShowGeneric] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [templateToMutate, setTemplateToMutate] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState("");

  const [refreshVideos, toggleRefreshVideos] = useState(false);
  const [updatingTemplate, setUpdatingTemplate] = useState(false);

  const centeredFlex = {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  };

  const handleRename = (template) => {
    setNewTemplateName(template.name);
    setTemplateToMutate(template);
    setRenameModalOpen(true);
  };

  const saveRename = async () => {
    if (!newTemplateName) return notification.error({ message: "New template name cannot be empty" });
    try {
      setUpdatingTemplate(true);
      const payload = {
        id: templateToMutate.id,
        name: newTemplateName,
        status: templateToMutate.status,
        company: templateToMutate.company
      };
      await updateVideoTemplate(payload);
      toggleRefreshVideos(!refreshVideos);
      setUpdatingTemplate(false);
      resetRenameModal();
    } catch (e) {
      setUpdatingTemplate(false);
      resetRenameModal();
      notification.error({ message: "Unable to update template" });
    }
  };

  const resetRenameModal = () => {
    setTemplateToMutate(null);
    setRenameModalOpen(false);
    setNewTemplateName("");
  };

  const handleStatus = async (template, status) => {
    try {
      setLoading(true);
      const payload = {
        id: template.id,
        name: template.name,
        status: status,
        company: template.company
      };
      await updateVideoTemplate(payload);
      toggleRefreshVideos(!refreshVideos);
    } catch (e) {
      setLoading(false);
      notification.error({ message: "Unable to update template" });
    }
  };

  useEffect(() => {
    const getTemplates = async () => {
      if (!selectedCompany) return;
      try {
        setLoading(true);
        const response = await getAllVideoTemplates(selectedCompany, showGeneric);
        setTemplates(response);
        setLoading(false);
      } catch (e) {
        notification.error({
          message: "Error getting templates"
        });
      }
    };
    getTemplates();
  }, [selectedCompany, showGeneric, refreshVideos]);

  if (selectedTemplate) return <PromotionEditor templateId={selectedTemplate.id} />;

  return (
    <div
      style={{
        ...centeredFlex,
        padding: "20px"
      }}>
      <div
        style={{
          backgroundColor: "white",
          height: "100%",
          width: "100%"
        }}>
        {loading ? (
          <div style={centeredFlex}>
            <Loader loading={true} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
              rowGap: "12px"
            }}>
            <div
              style={{
                ...centeredFlex,
                justifyContent: "end",
                flexDirection: "row",
                columnGap: "12px",
                padding: "12px"
              }}>
              Include Generic Templates
              <Checkbox onChange={(e) => setShowGeneric(e.target.checked)} checked={showGeneric} />
            </div>
            <Modal
              title="Rename Template"
              open={renameModalOpen}
              onCancel={() => resetRenameModal()}
              className="custom-modal-context-menu"
              footer={[
                <Button key="submit" type="primary" onClick={saveRename} loading={updatingTemplate}>
                  Save
                </Button>
              ]}>
              <h3>Template Name</h3>
              <Input value={newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)} />
            </Modal>
            <Row gutter={16}>
              {templates.map((template) => {
                return (
                  <Col key={template.id} span={6}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                        padding: "20px",
                        rowGap: "8px"
                      }}>
                      <ContextMenuTrigger id={template.id}>
                        <img
                          src={template.thumbnail_url}
                          alt={template.name}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "12px",
                            cursor: "pointer"
                          }}
                          onClick={() => setSelectedTemplate(template)}
                        />
                      </ContextMenuTrigger>

                      <ContextMenu id={template.id}>
                        <ContextMenuItem onClick={() => handleRename(template)}>
                          {CREATE_PROMOTION_RENAME}
                        </ContextMenuItem>
                        <ContextMenuItem onClick={() => handleStatus(template, "Pending")}>
                          {CREATE_PROMOTION_SUSPEND}
                        </ContextMenuItem>
                        <ContextMenuItem onClick={() => handleStatus(template, "Archive")}>
                          {CREATE_PROMOTION_ARCHIVE}
                        </ContextMenuItem>
                      </ContextMenu>
                      <div
                        style={{
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "calc(95%)"
                        }}>{`Name: ${template.name}`}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%"
                        }}>
                        <div>{`Duration: ${template.duration} secs`}</div>
                        <div
                          style={{
                            display: !template?.company ? "block" : "none"
                          }}>
                          Generic
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};
