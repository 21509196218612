import { Form, Input, Button, Row, Col } from "antd";
import { LoginCard, LoginWrapper, FormWrapper } from "./styles";
import Logo from "Assets/Logos/LogoPrimary";
import { useSelector, useDispatch } from "react-redux";
import { RequestPassword as RequestPasswordAction } from "Redux/App/Actions/Users";
import { loading as stateLoading } from "Redux/App";
import { useNavigate } from "react-router-dom";
const RequestPassword = () => {
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const encodEmail = (email) => {
    let encodedEmail = "";
    for (let i = 0; i < email.length; i++) {
      encodedEmail += `&#${email.charCodeAt(i)};`;
    }
    return encodedEmail;

  };
  const onFinish = (values) => {
    dispatch(RequestPasswordAction(values, navigate));
  };

  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center" }}>
              <Logo width={80} />
            </div>
          </Col>
        </Row>
        <FormWrapper>
          <Form
            form={form}
            layout="vertical"
            className="FormWrapper"
            onFinish={onFinish}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input />
            </Form.Item>

            <Button
              style={{ color: "white" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Reset password
            </Button>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default RequestPassword;
