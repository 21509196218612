import { Image } from 'antd';

const RenderMedia = ({ promoVideo }) => {
  if(promoVideo) {
    return (
      <Image
        preview={false}
        width="100%"
        src={promoVideo?.template_url}
      ></Image>
    );
  }
};

export default RenderMedia;