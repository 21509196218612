import { createGlobalStyle } from "styled-components";

const FontStyles = createGlobalStyle`

p {
  font-family: 'Open Sans', sans-serif;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}
.ant-tabs-tab-btn,
.ant-checkbox-wrapper > span
{
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}
.textureNumber{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700
}

body{
font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  background-color: #E7E6E6 !important;
}
`;
export default FontStyles;
