import styled from "styled-components";

export const UploadPromotionWrapper = styled.div`
  .ant-select-multiple .ant-select-selection-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 4px;
    max-width: 150px;
    height: 30px;
    color: white;
    background: #39b54a;
    border-radius: 32px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .upload-box {
    width: 326px;
    height: 166px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload-box p:nth-child(1) {
    font-weight: 500;
    margin-bottom: 5px !important;
    text-align: center;
    color: #3d3d3d;
    font-size: 16px;
  }
  .horizontal-line {
    border: 1px solid rgba(58, 181, 74, 0.1);
  }
  .ant-picker {
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-right: 5px;
  }
  .ant-input-number {
    height: 42px;
    width: 157px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .action-buttons {
    margin-top: 45px;
  }
  .edit-coupon-wrapper {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: -20px;
  }
  .edit-coupon-heading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #3d3d3d;
    margin-right: 16px;    
  }
  .edit-coupon {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-decoration-line: underline;
    color: #0097ec;
  }
  .edit-coupon:hover {
    cursor: pointer;
  }
`;
