import styled from "styled-components";

export const ActionsDiv = styled.div`
  width: 386px;
  height: 52px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  color: #9b9b9b;
  &:hover {
    cursor: pointer;
    background: #fbfffb;
    border: 0.5px solid #39b54a;
    color: #39b54a;
  }
  .action {
    display: flex;
    align-items: center;
    gap: 13px;
  }
  .actions-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &:hover > .action > .copy-icon > path {
    fill: #39b54a;
  }
  &:hover > .gray-tick-icon > path {
    fill: #39b54a;
  }
  &:hover > .action > .message-icon > path {
    fill: #39b54a;
  }
  &:hover > .action > .smart-home-icon > path {
    fill: #39b54a;
  }
  &:hover > .action > .save-icon > path {
    fill: #39b54a;
  }
`;
export const ModalBtn = styled.button`
  width: 56px;
  height: 48px;
  background: #39b54a;
  border-radius: 5px;
  margin-bottom: 14px;
  padding-top: 5px;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
