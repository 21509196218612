import axios from "axios";
import { requestStart, requestCompleted, requestSuccess } from "../..";
import { notification } from "antd";

export function GetTemplates() {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + `/template`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data) {
            dispatch(requestSuccess(res.data));
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail
              ? error?.response?.data?.detail
              : "Something went wrong",
          });
        });
    } catch (error) {}
  };
}

export function EditTemplate(values) {
  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      await axios
        .put(process.env.REACT_APP_API_BASE_URL + `/template/edit`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let stateData = state();

            let records = stateData.app.data;

            let filteredRecords = records.filter(
              (record) => record.id !== values.id
            );

            let newData = [res.data, ...filteredRecords];

            dispatch(requestSuccess(newData));
            notification.success({
              message: "Template edited successfully",
            });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail
              ? error?.response?.data?.detail
              : "Something went wrong",
          });
        });
    } catch (error) {}
  };
}
