import { TemplateTiles } from "Pages/Promotions/CreatePromotion/utils/templateTiles";
const CreatePromotion = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        justifyContent: "start",
        alignItems: "start",
        height: "100%",
        width: "100%"
      }}>
      <TemplateTiles />
    </div>
  );
};

export default CreatePromotion;
