import { Switch } from "antd";

const CouponTable = ({
  record,
  item,
  set,
  dispatch,
  edit,
  setLoading,
  setModal,
  fetchCompanies,
  toggleFetchCompanies
}) => (
  <Switch
    key={record.id}
    checked={item}
    onChange={(coupon) => {
      set(record);
      if (!coupon) {
        setModal(true);
      } else {
        dispatch(
          edit(
            {
              ...record,
              coupon: true
            },
            setLoading,
            null,
            null,
            fetchCompanies,
            toggleFetchCompanies
          )
        );
      }
    }}
  />
);

export default CouponTable;
