import NewPromotionIcon from "Assets/Icons/NewPromotionIcon";

const NewPromoIcon = ({open, handleClick}) => (
  open && (
    <div
      className="new-promotion"
      onClick={handleClick}
    >
      <NewPromotionIcon />
    </div>
  )
)

export default NewPromoIcon;