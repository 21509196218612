export default function StatusIcon(props) {
  return (
    <svg
      width="20"
      height="43"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.7334 15.125V13.4" stroke-width="1.5" stroke-linecap="round" />
      <path d="M10 15.125V11.675" stroke-width="1.5" stroke-linecap="round" />
      <path
        d="M14.2666 15.125V9.94165"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M14.2667 4.875L13.8834 5.325C11.7584 7.80833 8.9084 9.56667 5.7334 10.3583"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11.8252 4.875H14.2669V7.30833"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.49984 18.3334H12.4998C16.6665 18.3334 18.3332 16.6667 18.3332 12.5V7.50002C18.3332 3.33335 16.6665 1.66669 12.4998 1.66669H7.49984C3.33317 1.66669 1.6665 3.33335 1.6665 7.50002V12.5C1.6665 16.6667 3.33317 18.3334 7.49984 18.3334Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
