export default function SaveIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="save-icon"
    >
      <path
        d="M2.20017 15.1667C1.96017 15.1667 1.72684 15.1067 1.52684 14.9867C1.08017 14.7267 0.826843 14.2067 0.826843 13.56V5.98667C0.826843 4.56667 1.98017 3.41333 3.40017 3.41333H8.58685C10.0069 3.41333 11.1602 4.56667 11.1602 5.98667V13.56C11.1602 14.2067 10.9069 14.72 10.4602 14.9867C10.0135 15.2467 9.44018 15.22 8.87351 14.9067L6.25352 13.4467C6.12685 13.3733 5.86017 13.3733 5.7335 13.4467L3.1135 14.9067C2.80684 15.08 2.49351 15.1667 2.20017 15.1667ZM3.40684 4.42C2.54017 4.42 1.83352 5.12666 1.83352 5.99333V13.5667C1.83352 13.84 1.91351 14.0533 2.04018 14.1267C2.16685 14.2 2.39352 14.1667 2.63352 14.0333L5.25352 12.5733C5.68019 12.34 6.32018 12.34 6.74685 12.5733L9.36684 14.0333C9.60684 14.1667 9.82685 14.2 9.96018 14.1267C10.0869 14.0533 10.1668 13.84 10.1668 13.5667V5.99333C10.1668 5.12666 9.46019 4.42 8.59353 4.42H3.40684Z"
        fill="#9B9B9B"
      />
      <path
        d="M9.79331 15.1667C9.49998 15.1667 9.19332 15.08 8.87998 14.9134L6.25999 13.4533C6.13332 13.3867 5.85996 13.3867 5.7333 13.4533L3.11997 14.9134C2.55331 15.2267 1.9733 15.2533 1.5333 14.9933C1.08664 14.7333 0.833313 14.2133 0.833313 13.5733V6.00002C0.833313 4.58002 1.98664 3.4267 3.40664 3.4267H8.59332C10.0133 3.4267 11.1666 4.58002 11.1666 6.00002V13.5733C11.1666 14.2133 10.9133 14.7333 10.4667 14.9933C10.2667 15.1067 10.04 15.1667 9.79331 15.1667ZM5.99998 12.4C6.26665 12.4 6.52664 12.46 6.74664 12.58L9.36664 14.04C9.60664 14.1734 9.83331 14.2067 9.95998 14.1267C10.0866 14.0534 10.1666 13.84 10.1666 13.5667V5.99335C10.1666 5.12668 9.45999 4.42002 8.59332 4.42002H3.40664C2.53997 4.42002 1.83331 5.12668 1.83331 5.99335V13.5667C1.83331 13.84 1.91331 14.0534 2.03998 14.1267C2.16664 14.2 2.39332 14.1667 2.63332 14.0333L5.25332 12.5733C5.46665 12.46 5.73331 12.4 5.99998 12.4Z"
        fill="#9B9B9B"
      />
      <path
        d="M13.7933 12.58C13.5 12.58 13.1933 12.4933 12.88 12.3267L10.42 10.9533C10.26 10.8667 10.1666 10.7 10.1666 10.52V6.00002C10.1666 5.13335 9.45999 4.4267 8.59332 4.4267H5.33331C5.05998 4.4267 4.83331 4.20003 4.83331 3.9267V3.41335C4.83331 1.99335 5.98664 0.840027 7.40664 0.840027H12.5933C14.0133 0.840027 15.1666 1.99335 15.1666 3.41335V10.9867C15.1666 11.6267 14.9133 12.1467 14.4667 12.4067C14.2667 12.52 14.04 12.58 13.7933 12.58ZM11.1666 10.22L13.3666 11.4533C13.6066 11.5867 13.8266 11.62 13.96 11.54C14.0933 11.46 14.1666 11.2534 14.1666 10.98V3.40668C14.1666 2.54001 13.46 1.83335 12.5933 1.83335H7.40664C6.53997 1.83335 5.83331 2.54001 5.83331 3.40668V3.42002H8.59332C10.0133 3.42002 11.1666 4.57335 11.1666 5.99335V10.22Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
