import axios from "axios";
import { requestStart, requestSuccess } from "../..";
import { notification } from "antd";

export function GetVideoTemplates(selectedCompany) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + `/video-template-all?offset=0&limit=100`, {
          params: {
            company: selectedCompany ? selectedCompany : undefined
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res.data) {
            dispatch(requestSuccess(res.data));
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {}
  };
}

export function GetSingleVideoTemplate(templateId, setPromoData) {
  return async (dispatch) => {
    try {
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + `/video-template/${templateId}`, {
          params: {
            video_template_id: templateId
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setPromoData(res.data);
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {}
  };
}

export function BuildAsset(templateId) {
  return async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL + `/build-asset`,
          {
            video_id: templateId
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res) {
            notification.success({
              message: "Asset build successfully"
            });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {}
  };
}

export function CreateVideoTemplates(data, props, setCreateTemplateLoading, resetFields) {
  return async (dispatch, state) => {
    if (setCreateTemplateLoading) setCreateTemplateLoading(true);
    try {
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + `/video-template`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res.data) {
            let stateData = state();
            if (setCreateTemplateLoading) setCreateTemplateLoading(false);
            let newStateData = [res.data, ...stateData.app.data];
            dispatch(requestSuccess(newStateData));
            if (props.setDrawerOpen)
              props.setDrawerOpen({
                ...props.drawerOpen,
                open: false
              });
            notification.success({
              message: "Video Uploaded Successfully"
            });
            resetFields();
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          notification.error({
            message: "Video not Uploaded "
          });
        });
    } catch (error) {}
  };
}

export function EditVideoTemplates(data, props, setCreateTemplateLoading, resetFields) {
  return async (dispatch, state) => {
    setCreateTemplateLoading(true);
    try {
      await axios
        .put(process.env.REACT_APP_API_BASE_URL + `/video-template/edit`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res.data) {
            let stateData = state();
            let records = stateData.app.data;

            let filteredRecords = records.filter((record) => record.id !== data.id);

            let newData = [res.data, ...filteredRecords];

            dispatch(requestSuccess(newData));
            if (setCreateTemplateLoading) setCreateTemplateLoading(false);
            notification.success({
              message: "Template edited successfully"
            });
            resetFields();
            if (props.setDrawerOpen)
              props.setDrawerOpen({
                ...props.drawerOpen,
                open: false
              });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {}
  };
}

export function GetVideoTemplatesMedia(promo_id, promoVideo, setPromoVideo) {
  return async (dispatch) => {
    setPromoVideo({ ...promoVideo, loading: true, modalVisible: true });

    try {
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + `/video-template/media`, {
          params: {
            video_template_id: promo_id
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res.data) {
            if (res) {
              setPromoVideo({
                ...promoVideo,
                loading: false,
                data: res?.data.video
              });
            }
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {}
  };
}
