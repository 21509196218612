import React, { useEffect, useState } from "react";
import { Layout, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import NavMenu from "../NavMenu/index";
import { LogoWrapper } from "./styles";
import LogoPrimary from "Assets/Logos/LogoPrimaryNew";
// import CollapseLogo from "Assets/Icons/CollapsedLogo";
import { getCompanies } from "Redux/App/Actions/Companies";
import { useDispatch, useSelector } from "react-redux";
import { selectedCompaign, userProfileData } from "Redux/App";
import { setCompany, setCompanySelected } from "Redux/App/";
import CollapsedLogo from "Assets/Logos/CollapsedLogo";
import { SELECTED_COMPANY } from "../../../constants/constants";

const { Sider } = Layout;

const Sidebar = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(userProfileData);
  const companyId = useSelector(selectedCompaign);
  const location = useLocation();
  const [companies, setCompanies] = useState({
    data: [],
    loading: false
  });

  useEffect(() => {
    if (!companyId) {
      dispatch(setCompanySelected(userData?.company));
    }

    if (location.pathname === "/create-promotion") {
      setCollapsed(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userData && userData?.permission_level === "Fmtv") {
      dispatch(getCompanies(companies, setCompanies));
    }
  }, [userData]);

  const currentSelectedCompany = parseInt(localStorage.getItem(SELECTED_COMPANY) ?? "1");

  useEffect(() => {
    if (userData?.permission_level === "Fmtv") {
      dispatch(setCompany(currentSelectedCompany ?? userData?.company));
      dispatch(setCompanySelected(currentSelectedCompany ?? userData?.company));
    }
  }, [userData, currentSelectedCompany]);

  return (
    <Sider
      collapsible
      width={250}
      collapsedWidth={location.pathname === "/create-promotion" ? 0 : 70}
      collapsed={collapsed}
      onCollapse={(c) => setCollapsed(c)}
      theme="light"
      trigger={null}>
      <LogoWrapper onClick={() => navigate("/")}>
        {collapsed ? <CollapsedLogo /> : <LogoPrimary width={130} />}
      </LogoWrapper>
      {userData?.permission_level === "Fmtv" && !collapsed && (
        <Select
          defaultValue={currentSelectedCompany ?? userData?.company}
          loading={companies.loading}
          style={
            collapsed
              ? { display: "none" } //{ width: "70%", marginLeft: 15, marginBottom: 10 }
              : { width: "85%", marginLeft: 15, marginBottom: 10 }
          }
          onChange={(e) => {
            dispatch(setCompany(e));
            dispatch(setCompanySelected(e));
            if (userData?.permission_level === "Fmtv") localStorage.setItem(SELECTED_COMPANY, e);
          }}>
          {companies.data.map((company) => (
            <Select.Option value={company.id}>{company.company_name}</Select.Option>
          ))}
        </Select>
      )}
      <NavMenu collapsed={collapsed} />
    </Sider>
  );
};

export default Sidebar;
