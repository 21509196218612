export default function CopyIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="copy-icon"
    >
      <path
        d="M10.6666 8.59998V11.4C10.6666 13.7333 9.73331 14.6666 7.39998 14.6666H4.59998C2.26665 14.6666 1.33331 13.7333 1.33331 11.4V8.59998C1.33331 6.26665 2.26665 5.33331 4.59998 5.33331H7.39998C9.73331 5.33331 10.6666 6.26665 10.6666 8.59998Z"
        fill="#9B9B9B"
      />
      <path
        d="M11.4 1.33331H8.60003C6.67933 1.33331 5.7117 1.97024 5.42926 3.49258C5.32767 4.04015 5.79502 4.49998 6.35193 4.49998H7.40003C10.2 4.49998 11.5 5.79998 11.5 8.59998V9.64808C11.5 10.205 11.9599 10.6723 12.5074 10.5707C14.0298 10.2883 14.6667 9.32068 14.6667 7.39998V4.59998C14.6667 2.26665 13.7334 1.33331 11.4 1.33331Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
