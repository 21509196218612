import { doGet, doPut } from "Requests/requests";

export const getAllVideoTemplates = async (companyId, generic = false) => {
  try {
    return await doGet(`/video-template?company=${companyId}&generic=${generic}`);
  } catch (e) {
    throw e;
  }
};

export const updateVideoTemplateThumbnail = async (id, timestamp) => {
  try {
    return await doPut(`/video-template/thumbnail/update?id=${id}&timestamp=${timestamp}`, null);
  } catch (e) {
    throw e;
  }
};

export const updateVideoTemplate = async (payload) => {
  try {
    return await doPut(`/video-template/edit`, payload);
  } catch (e) {
    throw e;
  }
};
