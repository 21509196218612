import { Row, Image, Col, Drawer, Button } from "antd";
import DocumentIcon from "Assets/Icons/Document";
import EditIcon from "Assets/Icons/EditIcon";
import GalleryIcon from "Assets/Icons/GalleryIcon";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DocumentLookup from "./DocumentLookup";
import EditImage from "./EditImage";
import { useDispatch } from "react-redux";
import {
  GetVideoTemplatesMedia,
  GetSingleVideoTemplate,
  CreateVideoTemplates,
  BuildAsset,
} from "Redux/App/Actions/VideoTemplates";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import Iframe from "react-iframe";

const PromotionPlayback = () => {
  const [documentDrawer, setDocumentDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const params = useParams("id");
  const dispatch = useDispatch();
  const [promoData, setPromoData] = useState({});
  const navigate = useNavigate();

  const [promoVideo, setPromoVideo] = useState({
    data: "",
    loading: false,
  });

  useEffect(() => {
    if (params.id) {
      dispatch(GetVideoTemplatesMedia(params.id, promoVideo, setPromoVideo));
      dispatch(GetSingleVideoTemplate(params.id, setPromoData));
    }
  }, [dispatch, params]);

  return (
    <>
      {promoVideo.loading ? (
        <LoadingSpinner />
      ) : (
        <Row gutter={24} style={{ height: "100vh" }}>
          <Iframe
            url={`https://fuel-media-editor.web.app/?token=${localStorage.getItem(
              "token"
            )}`}
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
            position="relative"
          />
          {/*   <Col span={documentDrawer || editDrawer ? 18 : 22}>
            <ReactPlayer
              width={"100%"}
              height={"100%"}
              url={`data:video/mp4;base64,${promoVideo.data}`}
              playing={videoPlaying}
            ></ReactPlayer>
            <div
              style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
            >
              <Button
                onClick={() => {
                  setVideoPlaying(!videoPlaying);
                }}
              >
                {videoPlaying ? "Pause" : "Playback"}
              </Button>
              <Button
                onClick={() => {
                  console.log(promoData);
                  dispatch(BuildAsset(promoData?.id));
                }}
              >
                Finish
              </Button>
              <Button
                onClick={() => {
                  setDocumentDrawer(false);
                  setEditDrawer(false);
                  navigate("/create-promotion");
                }}
              >
                Cancel
              </Button>
            </div>
          </Col> */}
          {/*    <Col span={1}>
            <div>
              <GalleryIcon />
              <DocumentIcon
                onClick={() => {
                  setDocumentDrawer(!documentDrawer);
                  setEditDrawer(false);
                }}
              />
              <EditIcon
                onClick={() => {
                  console.log("Hi");
                  setEditDrawer(!editDrawer);
                  setDocumentDrawer(false);
                }}
              />
            </div>
          </Col>
          {documentDrawer && (
            <Col span={5}>
              <DocumentLookup />
            </Col>
          )}
          {editDrawer && (
            <Col span={5}>
              <EditImage />
            </Col>
          )} */}
        </Row>
      )}
    </>
  );
};

export default PromotionPlayback;
