import ArchiveSubmit from "./ArchiveSubmit";
import SubmitSection from "./SubmitSection";

const Submit = ({
  isScreenEdit,
  editCampaign,
  setEditCampaign,
  dispatch,
  navigate,
  loading
}) => {
  return (
    <div
      className="5"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <ArchiveSubmit
        isScreenEdit={isScreenEdit}
        editCampaign={editCampaign}
        setEditCampaign={setEditCampaign}
        dispatch={dispatch}
        navigate={navigate}
      />
      <SubmitSection 
        navigate={navigate}
        loading={loading}
      />
    </div>
  )
}

export default Submit;