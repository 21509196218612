import { Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ActionsDiv } from "../Promotions/PromotionModal/styles";
import SaveIcon from "Assets/Icons/SaveIcon";
import GrayTickIcon from "Assets/Icons/GrayTickIcon";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { SetLogsArchiveStatus } from "Redux/App/Actions/Logs";

const StatusModal = ({ isModalOpen, setModalOpen, selectedLogs, setSelectedLogs }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const closeStatusModal = () => {
    setModalOpen(false);
  };

  const handleArchiveLogs = () => {
    dispatch(SetLogsArchiveStatus(selectedLogs, setLoading, setSelectedLogs));
  };
  return (
    <Modal title="Log Status" open={isModalOpen} onCancel={closeStatusModal} closable={true} footer={null} width={450}>
      {loading && <LoadingSpinner />}
      {!loading && (
        <ActionsDiv onClick={handleArchiveLogs}>
          <div className="action">
            <SaveIcon />
            <span className="actions-title">Archive</span>
          </div>
          <GrayTickIcon />
        </ActionsDiv>
      )}
    </Modal>
  );
};

export default StatusModal;
