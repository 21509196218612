import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "antd";
import { CampaignSetupWrapper } from "./styles";
import { useDispatch } from "react-redux";
import { getCompanies } from "Redux/App/Actions/Companies";
import { CreateCampaign, GetSingleCampaign, UpdateSingleCampaign } from "Redux/App/Actions/Campaigns";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "./setup/LoadingSpinner";
import { useSelector } from "react-redux";
import { userProfileData, companyName } from "Redux/App";
import { SETUP_VALUES } from "constants/Campaign";
import Submit from "./setup/Submit";
import * as FormItems from "./setup/formItems";
import "./setup/style.css";

const CampaignSetup = ({ screen }) => {
  const isScreenEdit = screen === "edit";
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedCompany = useSelector(companyName);
  const userData = useSelector(userProfileData);
  // const [vistarValidation, setVistarValidation] = useState(SETUP_VALUES.VISTAR_INITIAL_STATE);
  const [status, setStatus] = useState("Pending");
  const [screenType, setScreenType] = useState("");
  const [comapnies, setCompanies] = useState({ ...SETUP_VALUES.INITIAL_ARR });
  const [editCampaign, setEditCampaign] = useState({ ...SETUP_VALUES.INITIAL_ARR });
  const [loading, setLoading] = useState(false);
  const [isBonusTagReq, setBonusTagReq] = useState(false);
  const dispatch = useDispatch();

  const isUserNotFmtv = userData?.permission_level !== "Fmtv";
  const company_id = isUserNotFmtv ? userData?.company_name : selectedCompany;

  useEffect(() => {
    if (!isUserNotFmtv) {
      form.setFieldsValue({
        company_id: userData.company
      });
      dispatch(getCompanies(comapnies, setCompanies));
    }
  }, [userData]);

  useEffect(() => {
    if (isScreenEdit) {
      dispatch(GetSingleCampaign(editCampaign, setEditCampaign, location.state.campaign));
    }
  }, []);

  useEffect(() => {
    if (isScreenEdit) {
      form.setFieldsValue({
        ...editCampaign?.data,
        campaign_name: editCampaign?.data?.campaign_name,
        updated_name: editCampaign?.data?.campaign_name,
        xibo_status: editCampaign?.data?.xibo_status
      });
    } else if (screen === "setup") {
      form.setFieldsValue({
        company_id,
        xibo_status: "pending",
        vistar_status: "pending"
      });
    }
  }, [editCampaign?.data, form, screen, userData?.company_name]);

  useEffect(() => {
    form.setFieldsValue({ company_id });
  }, [selectedCompany]);

  const onFinish = (values) => {
    values.match_flag = true;
    if (isUserNotFmtv) {
      values.company_id = userData.company;
    }

    if (isScreenEdit) {
      if (values.updated_name === editCampaign?.data?.campaign_name) {
        values.updated_name = "";
      }

      values.id = editCampaign?.data?.id;
      values.campaign_name = editCampaign?.data?.campaign_name;

      dispatch(UpdateSingleCampaign(values, navigate, setLoading));
    } else {
      if (values.company_id === userData.company_name) {
        values.company_id = userData.company;
      }

      if (!values.company_id) {
        values.company_id = userData.company;
      }

      dispatch(CreateCampaign(values, navigate));
    }
  };

  const getVistarRequired = () => {
    return screenType === "Both" || screenType === "Gas-Dispenser";
  };

  const getXIBORequired = () => {
    return screenType === "Both" || screenType === "In-Store";
  };
  const handleChange = (formStatus) => {
    setStatus(formStatus);
  };

  const handleChangeScreen = (formScreenType) => {
    setScreenType(formScreenType);
  };

  const handleChangeCampType = (value) => {
    setBonusTagReq(value === "Bonus");
  };

  return (
    <CampaignSetupWrapper>
      <LoadingSpinner isNotLoading={!editCampaign.loading} />
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter="50">
          <Col span="12">
            <FormItems.CampName isScreenEdit={isScreenEdit} />
            <FormItems.CompanyComponent
              isUserNotFmtv={isUserNotFmtv}
              initialValue={isUserNotFmtv ? userData?.company_name : ""}
              defaultValue={company_id}
              companies={comapnies}
            />
            <FormItems.ReqChange />
            <FormItems.CampStatus onChange={handleChange} />
          </Col>
          <Col span="12">
            <FormItems.ScreenType onChange={handleChangeScreen} />
            <FormItems.RequestBy userName={userData?.username} disable={isScreenEdit} />
            <FormItems.CurrentDescription />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <FormItems.CampType onChange={handleChangeCampType} />
          </Col>
          <Col span={12}>
            <FormItems.BonusTags isRequired={isBonusTagReq} />
          </Col>
        </Row>
        ``
        <Row gutter={24}>
          <Col span="12">
            <h2>{SETUP_VALUES.VISTAR_INFO}</h2>
            <FormItems.VistarStatus required={getVistarRequired()} />
            <FormItems.OrderLineID required={getVistarRequired()} />
            <FormItems.VistarAdvertiserOverride />
          </Col>

          <Col span="12">
            <h2>{SETUP_VALUES.XIBO_INFO}</h2>
            <FormItems.XIBOStatus required={getXIBORequired()} />
          </Col>
        </Row>
        <Submit
          isScreenEdit={isScreenEdit}
          editCampaign={editCampaign}
          setEditCampaign={setEditCampaign}
          dispatch={dispatch}
          navigate={navigate}
          loading={loading}
        />
      </Form>
    </CampaignSetupWrapper>
  );
};

export default CampaignSetup;
