import { useEffect, useState } from "react";
import axios from "axios";
import { Form, Input, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getToken, getRequired } from "utils";
import { LoginCard, LoginWrapper, FormWrapper } from "./styles";
import Logo from "Assets/Logos/LogoPrimary";
import { LoginAction } from "Redux/App/Actions/Auth";
import { loading as stateLoading } from "Redux/App";
import AUTH_VALUES from "constants/Auth";
import ROUTES from 'Routes/route';
import { fetchTerms } from "Redux/App/Actions/Auth";
import TCModal from './TermsConditionsModal';
import TermsText from "./Terms";

import "./style.css";
const Login = () => {
  const navigate = useNavigate();

  const loading = useSelector(stateLoading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [terms, setTerms] = useState("");
  const [termsLoading, setTermsLoading] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);  
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () =>  setIsModalOpen(false);

  useEffect(() => {
    fetchTerms(setTermsLoading,setTerms);
  }, []);

  useEffect(() => {
    if (getToken()) navigate(ROUTES.HOME_PAGE);    
  }, [navigate]);

  const onFinish = (values) => {
    values.username = values.username.toLowerCase();
    dispatch(LoginAction(values, navigate));
  };

  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div className="text_align_center">
              <Logo width={80} />
            </div>
          </Col>
        </Row>
        <FormWrapper>
          <Form
            form={form}
            layout="vertical"
            className="FormWrapper"
            onFinish={onFinish}
          >
            <Form.Item
              label={AUTH_VALUES.EMAIL_LABEL}
              name="username"
              rules={getRequired(AUTH_VALUES.EMAIL_ERROR_MSG)}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={AUTH_VALUES.PASS_LABEL}
              name="password"
              rules={getRequired(AUTH_VALUES.PASSWORD_ERROR_MSG)}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <div className="text_align_center">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {AUTH_VALUES.LOGIN_BTN_TEXT}
                </Button>
              </div>
            </Form.Item>

            <Link to={ROUTES.REQUEST_PASS}>{AUTH_VALUES.RESET_PASS_TITLE}</Link>
            <TermsText showModal={showModal}/>
          </Form>
          <TCModal
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            termsLoading={termsLoading}
            terms={terms}
          />
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default Login;
