export default function ExpanIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="21" cy="21" r="20.5" fill="white" stroke="black" />
      <path d="M26 15L16 21.5L26 28" stroke="black" />
    </svg>
  );
}
