import { createSlice } from "@reduxjs/toolkit";

const logsInitialState = {
  data: [],
};

// logs slice
const logsSlice = createSlice({
  name: "logs",
  initialState: logsInitialState,
  reducers: {
    setLogsData(state, { payload }) {
      state.data = payload;
    },
  },
});

export const {
  setLogsData,
} = logsSlice.actions;

export const logsData = (state) => state.logs.data;

export const logsReducer = logsSlice.reducer;
