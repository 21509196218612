import styled from "styled-components";

// Drawer Styling
export const DrawersWrapper = styled.div`
  .anticon-down {
    padding-left: 70px;
  }
`;
export const DrawerFormHeading = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3d3d3d;
  margin-top: 12px;
  margin-bottom: 10px;
  display: inline-block;
`;
export const StatusWrapper = styled.div`
  position: relative;
  padding: 0px 0px 2px 24px;
`;

export const Status = styled.p`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 80px;
  width: 74px;
  height: 19px;
  padding: 4px 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #ffffff;
`;

// End of Drawer Styling
