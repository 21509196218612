export default function ActivityIcon({ fill }) {
  return (
    <svg
      width="20"
      height="40"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4998 18.9584H7.49984C2.97484 18.9584 1.0415 17.025 1.0415 12.5V7.50002C1.0415 2.97502 2.97484 1.04169 7.49984 1.04169H12.4998C17.0248 1.04169 18.9582 2.97502 18.9582 7.50002V12.5C18.9582 17.025 17.0248 18.9584 12.4998 18.9584ZM7.49984 2.29169C3.65817 2.29169 2.2915 3.65835 2.2915 7.50002V12.5C2.2915 16.3417 3.65817 17.7084 7.49984 17.7084H12.4998C16.3415 17.7084 17.7082 16.3417 17.7082 12.5V7.50002C17.7082 3.65835 16.3415 2.29169 12.4998 2.29169H7.49984Z"
        fill={fill}
      />
      <path
        d="M6.10826 12.7C5.97493 12.7 5.8416 12.6583 5.72493 12.5667C5.44993 12.3583 5.39993 11.9667 5.60826 11.6917L7.5916 9.11667C7.83326 8.80834 8.17493 8.60834 8.5666 8.55834C8.94993 8.50834 9.3416 8.61667 9.64993 8.85834L11.1749 10.0583C11.2333 10.1083 11.2916 10.1083 11.3333 10.1C11.3666 10.1 11.4249 10.0833 11.4749 10.0167L13.3999 7.53334C13.6083 7.25834 14.0083 7.20834 14.2749 7.42501C14.5499 7.63334 14.5999 8.02501 14.3833 8.30001L12.4583 10.7833C12.2166 11.0917 11.8749 11.2917 11.4833 11.3333C11.0916 11.3833 10.7083 11.275 10.3999 11.0333L8.87493 9.83334C8.8166 9.78334 8.74993 9.78334 8.7166 9.79167C8.68326 9.79167 8.62493 9.80834 8.57493 9.87501L6.5916 12.45C6.48326 12.6167 6.29993 12.7 6.10826 12.7Z"
        fill={fill}
      />
    </svg>
  );
}
