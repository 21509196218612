import styled from "styled-components";

export const TemplateWrapper = styled.div`
  border: 1px solid #efefef;
  border-radius: 6px;
  width: 60vw;
  margin: 25px 12px;
  padding: 28px 36px;

  .actions-btn {
    display: flex;
    justify-content: right;
    width: 100%;
    gap: 20px;
  }
  .ant-input {
    border-radius: 6px;
    margin-top: 12px;
    border: 1px solid #efefef;
    height: 56px;
  }
  .ant-input-number {
    border-radius: 6px;
    margin-top: 12px;
    border: 1px solid #efefef;
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ExportButton = styled.div`
  margin-top: 5px;
  font-size: 1rem;
  text-decoration: underline;
  outline: none;
  background-color: transparent;
  color: #0096ff;
  cursor: pointer;
`;
