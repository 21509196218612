import { doPut } from "Requests/requests";

export const updateUser = async (data) => {
  try {
    return await doPut("/user/edit", data);
  } catch (e) {
    throw e;
  }
};

export const updateUserTimezone = async (data) => {
  try {
    return await doPut("/user/edit/timezone", data);
  } catch (e) {
    throw e;
  }
};
