import DrawerComponent from "Components/Shared/Drawer";
import { ActionButton, CloseBtnWrapper, DrawerTitle, MainTitle } from "Styles/global";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { Button, Col, Form, Input, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAllTimezones } from "Requests/timezone";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile, userProfileData } from "Redux/App";
import { updateUserTimezone } from "Requests/user";

export const ChangeTimezoneDrawer = ({ timezoneDrawerOpen, setTimezoneDrawerOpen }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const userData = useSelector(userProfileData);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTimezones = async () => {
      const response = await getAllTimezones();
      setTimezones(response);
    };
    fetchTimezones();
  }, []);

  useEffect(() => {
    if (userData?.time_zone) {
      form.setFieldsValue({
        timezone_id: userData.time_zone
      });
    }
  }, [userData]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        id: userData.id,
        ...values
      };

      const request = await updateUserTimezone(data);
      if (request) {
        dispatch(setUserProfile(request));
        notification.success({
          message: "Timezone updated successfully"
        });
      }
      setLoading(false);
      setTimezoneDrawerOpen(false);
    } catch (error) {
      setLoading(false);
      notification["error"]({
        message: error?.response?.data?.detail ? error.response.data.detail : "Something went wrong"
      });
      setTimezoneDrawerOpen(false);
    }
  };

  return (
    <DrawerComponent drawerOpen={timezoneDrawerOpen} setDrawerOpen={setTimezoneDrawerOpen} loading={loading}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={() => setTimezoneDrawerOpen(false)}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">Change Timezone</p>
        </MainTitle>
      </DrawerTitle>
      <Form onFinish={onFinish} form={form} layout="vertical" style={{ marginTop: 50 }}>
        <Form.Item name="timezone_id" label="Timezone">
          <Select>
            {timezones.map((timezone) => (
              <Select.Option value={timezone?.id}>{timezone?.time_zone}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row className="action-buttons ">
          <Col span="12">
            <ActionButton background="##FFFFFF;" color=" #9B9B9B" width="140">
              <Button onClick={() => setTimezoneDrawerOpen(false)}>Cancel</Button>
            </ActionButton>
          </Col>
          <Col span="12">
            <ActionButton background="#39B54A" color="#FFFFFF" width="140">
              <Button htmlType="submit" loading={loading}>
                Update
              </Button>
            </ActionButton>
          </Col>
        </Row>
      </Form>
    </DrawerComponent>
  );
};
