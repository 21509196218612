import styled from "styled-components";

export const CustomInput = styled.div`
  .ant-input,
  .ant-input-password {
    background: #f8f9fa;
    border-radius: 4px;
    height: 45px;
    max-width: 525px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: 35px;
  }
  .ant-input-suffix {
    display: none;
  }
`;
export const TableWrapper = styled.div`
  .table-height {
    max-height: 272px;
    overflow-y: scroll;
  }
  .ant-table {
    border: 0.5px solid #eeeeee;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .ant-table-thead > tr > th {
    background: rgba(58, 181, 74, 0.1);
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #3d3d3d;
  }
  .ant-table-thead > tr > th:before {
    display: none;
  }

  .ant-table-tbody > tr > td {
    font-size: 13px;
  }
  .ant-avatar-square {
    width: 100px !important;

    height: 55px;
  }
  .ant-table-tbody {
    color: #555555;
  }
  .table-cell {
    color: #0097ec;
    cursor: pointer;
    max-width: 210px;
  }
  .table-cell:hover {
    color: #0079bd;
  }
  .new-promotion {
    z-index: 10000000;
    position: fixed;
    right: 60px;
    bottom: 50px;
    &:hover {
      cursor: pointer;
    }
  }
  .pointer-cursor:hover {
    cursor: pointer;
  }
  .active > .anticon-filter > svg {
    fill: #39b54a;
  }
`;
export const FormHeading = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3d3d3d;
  padding-bottom: 12px;
`;
export const ActionButton = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: ${(props) => props.width}px;

  .ant-btn {
    background: ${(props) => props.background};
    border-radius: 4px;
    width: 100%;
    height: 45px;
    color: ${(props) => props.color};
  }
  .ant-btn:hover {
    background-color: ${(props) => props.hoverBackground};
    border: 1px solid ${(props) => props.borderColor}px;
  }
`;
export const DrawerTitle = styled.div`
  background: #39b54a;
  border-radius: 8px 8px 0px 0px;
  height: ${(props) => props.height}px;
`;
export const MainTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;

  padding-left: 24px;
  padding-bottom: 35px;

  .main-title {
    padding-top: 20px;
  }

  .menu-title {
    color: #0097ec;
    padding-top: 12px;
    font-size: 18px;
    line-height: 22px;
  }
`;
export const CloseBtnWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
  margin-right: 10px;
`;
export const Header = styled.div`
  height: 72px;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
`;

export const ParentWrapper = styled.div`
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 10px !important;
  }
  .ant-layout-sider,
  .ant-layout-sider-has-trigger {
    border-right: 1px solid #f0f0f0;

    background: #092834 !important;
  }

  .ant-layout-sider-children {
    height: 100vh;
  }
  .ant-layout-header,
  .nav-fixed {
    border-bottom: 0.5px solid #e5e5e5;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #fff;
  }
  .signIn-loading {
    padding-right: 10px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #e1e1e1;
  }
  .ant-tabs {
    background: #ffff;
    padding: 10px;
  }

  .ant-menu {
    background: #092834;
    color: #ffff;
  }
`;
export const DrawerFormHeading = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3d3d3d;
  margin-bottom: 16px;
  margin-top: 24px;
  display: inline-block;
`;

export const BorderStyle = styled.div`
  border: 0.5px solid rgba(155, 155, 155, 0.2);
  width: 300px;
  padding: ${(props) => props.padding}px;
  &:hover {
    cursor: ${(props) => props.cursor};
  }
`;

export const Paraghraph = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #3d3d3d;
  text-align: left;
`;

export const ExtraContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #3d3d3d;
  margin-top: ${(props) => props.marginTop}px;
`;
export const BarcodeWrapper = styled.div`
  width: 200px;
`;

export const DrawerBodyPadding = styled.div`
  padding: 10px;
  .ant-dropdown-menu {
    position: fixed;
    width: 295px;
    margin-top: 18px;
    margin-left: -20px;
  }
  .ant-input-number-input-wrap {
    padding-top: 4px;
  }
`;

export const PageSubTitle = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #39b54a;
`;

export const PageSubTitleBlack = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
`;


export const SpinStyling = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-spin,
  ant-spin-spinning {
    margin-top: 250px;
  }
`;
