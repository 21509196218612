import styled from "styled-components";

export const CampaignSetupWrapper = styled.div`
  width: 70%;
  .ant-select {
    height: 48px;
    margin-top: 5px;
    border: 1px solid #efefef;
    border-radius: 4px;
  }
  .ant-input {
    border: 1px solid #efefef;
    margin-top: 5px;
    border-radius: 4px;
    height: 48px;
  }
  .ant-select {
    margin-top: 10px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 46px;
    border-radius: 4px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-top: 6px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
  .ant-select-focused {
    border: none;
  }
  .ant-select-arrow {
    color: #3d3d3d;
    width: 18px;
    height: 9px;
  }
  .ant-select-focused:not(.ant-select-disabled),
  .ant-select:not(.ant-select-customize-input),
  .ant-select-selector {
    box-shadow: none;
  }
  .ant-form-item-label {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
  }
  .action-btn {
    display: flex;
    justify-content: right;
    gap: 20px;
  }

  .ant-form-item-label {
    font-family: "Open Sans", sans-serif;
  }
`;
export const CampaignTitle = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #39b54a;
`;

export const CampaignsTable = styled.div`
  .ant-table-tbody > tr > td:nth-child(2) {
    color: #0097ec;
  }
`;
export const DrawersWrapper = styled.div`
  .anticon-down {
    padding-left: 70px;
  }
`;

export const StatusWrapper = styled.div`
  position: relative;
  padding: 0px 0px 2px 24px;
`;

export const Status = styled.p`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 80px;
  width: 74px;
  height: 19px;
  padding: 4px 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #ffffff;
`;
export const Highlighter = styled.p`
  background: yellow;
`;

export const DrawerFormHeading = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3d3d3d;
  margin-top: 12px;
  margin-bottom: 10px;
  display: inline-block;
`;

// End of Drawer Styling
