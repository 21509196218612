
import { Select, Form } from "antd"
import getRequiredMsg from "utils/formRequiredMsg"

const CompanyDropdown = ({ companiesData }) => (
  <Form.Item
    name="company"
    label="Company Name"
    rules={getRequiredMsg('Please select company', false)}
  >
    <Select allowClear>
      {companiesData.map((company) => (
        <Select.Option key={company?.id} value={company?.id}>
          {company.company_name}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
)

export default CompanyDropdown;