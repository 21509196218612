import AUTH_VALUES from 'constants/Auth';
import './style.css';

const TermsText = ({showModal}) => {
  return (
    <div className="text_margin_top">
      <p>
      {AUTH_VALUES.LOGIN_MSG}{" "}
        <span
          onClick={showModal}
          className="auth_login_span"
        >
          {AUTH_VALUES.TERM_CONDITION}
        </span>
        {" "}{AUTH_VALUES.USAGE_OF}
      </p>
    </div>
  )
}

export default TermsText;