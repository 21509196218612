export default function NewPromotionIcon({ fill }) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#39B54A" />
      <path
        d="M52 41.5H28C27.18 41.5 26.5 40.82 26.5 40C26.5 39.18 27.18 38.5 28 38.5H52C52.82 38.5 53.5 39.18 53.5 40C53.5 40.82 52.82 41.5 52 41.5Z"
        fill="white"
      />
      <path
        d="M40 53.5C39.18 53.5 38.5 52.82 38.5 52V28C38.5 27.18 39.18 26.5 40 26.5C40.82 26.5 41.5 27.18 41.5 28V52C41.5 52.82 40.82 53.5 40 53.5Z"
        fill="white"
      />
    </svg>
  );
}
