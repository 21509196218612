import { SETUP_VALUES } from "constants/Campaign";
import LoadingSpinner from "Components/Shared/LoadingSpinner";

const Spinner = ({isNotLoading}) => {
  if (isNotLoading) return null;  
  return (
    <div style={SETUP_VALUES.LOADING_OVERLAY}>
      <div style={SETUP_VALUES.LOADING_OVERLAY_SPINNER}>
        <LoadingSpinner />
      </div>
    </div>
  )
}
export default Spinner;