import { notification } from "antd";
import { ADD_IMAGE } from "constants/Images";

const uploadFileProps = (file, setFile) => {
  return {
    ...ADD_IMAGE.UPLOAD_PROPS(file.selectedFileList),
    // maxCount: 1,
    onChange: (info) => {
      const nextState = {};
      switch (info.file.status) {
        case "uploading":
          nextState.selectedFileList = [info.file];
          break;
        case "done":
          nextState.selectedFile = info.file;
          nextState.selectedFileList = [info.file];
          break;
        default:
      }
    },
    onRemove: (f) => {
      const newFiles = file.filter((file) => file.uid !== f.uid);
      setFile(newFiles);

      return true;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload: (file) => {
      if (file.type !== "image/png" || file.type === "image/jpeg") {
        notification.error({
          message: "Uploaded file format not supported "
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile((prev) => {
          const fileData = [{ ...file, fileAsText: e.target.result, selectedFile: file }];
          if (prev.length > 0) {
            return [...prev, ...fileData];
          }
          return fileData;
        });
      };
      reader.readAsDataURL(file);

      return false;
    }
  };
};

export default uploadFileProps;
