import React, { Fragment, useEffect, useState } from 'react';
import { TemplateWrapper } from './styles';
import { ActionButton } from 'Styles/global';
import { Input, Form, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { GetTemplates, EditTemplate } from 'Redux/App/Actions/Templates';
import {
  data as stateData,
  isTemplate,
  loading as stateLoading,
  setIsTemplate,
} from 'Redux/App';
import { useNavigate } from 'react-router-dom';

function Template() {
  const { TextArea } = Input;
  const [text, setText] = useState('');
  const dispatch = useDispatch();
  const data = useSelector(stateData);
  const loading = useSelector(stateLoading);
  const [selectedTemplate, setSelectedTemplate] = useState('jkhkjh');
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(GetTemplates());
  }, [dispatch]);

  const navigate = useNavigate();
  const [finishStatus, setfinishStatus] = useState(false);
  const showAlert = useSelector(isTemplate);
  if (showAlert) {
    window.onbeforeunload = function () {
      return 'Changes you made may not be saved.';
    };
  } else {
    window.onbeforeunload = null;
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (showAlert) {
      if (!finishStatus) {
        if (window.confirm('Do you want to go back ?')) {
          setfinishStatus(true);
          dispatch(setIsTemplate(false));
          navigate(-1);
        } else {
          navigate(window.location.pathname);
          // window.history.pushState(null, null, window.location.pathname);
          setfinishStatus(false);
        }
      }
    }
  };

  useEffect(() => {
    if (showAlert) {
      window.history.pushState(null, null, window.location.pathname);
    }
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [showAlert]);

  // useEffect(() => {
  //   const handler = (e) => {
  //     e.preventDefault();
  //     if (text === "") {
  //       return;
  //     }
  //     e.returnValue = true;
  //   };

  //   window.addEventListener("beforeunload", handler);
  //   return () => window.removeEventListener("beforeunload", handler);
  // }, [text]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const selectedTemplateFilter = data.filter(
        (template) => template.id === selectedTemplate
      )[0];
  
      if (selectedTemplate && selectedTemplateFilter)
        form.setFieldsValue({
          ...selectedTemplateFilter,
        });
    }
  }, [data, form, selectedTemplate]);

  const onFinish = (values) => {
    dispatch(EditTemplate(values));
  };

  const onChangeInput = (e) => {
    dispatch(setIsTemplate(true));
  };

  return (
    <Fragment>
      {/* <PageSubTitle>Invite User Email</PageSubTitle> */}
      <TemplateWrapper>
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name='name'
          >
            <Select
              onChange={(templateId) => {
                setSelectedTemplate(templateId);
                onChangeInput();
              }}
              options={(Array.isArray(data) ? data : []).map((t) => ({
                value: t.id,
                label: t.name,
              }))}
            />
          </Form.Item>

          <Form.Item
            label='Subject'
            name='subject'
          >
            <Input
              style={{ height: '56px' }}
              onChange={onChangeInput}
            />
          </Form.Item>

          <Form.Item
            name='id'
            noStyle
          >
            <Input hidden />
          </Form.Item>
          <Form.Item
            label='Body'
            name='body'
          >
            <TextArea
              rows={8}
              onChange={onChangeInput}
            />
          </Form.Item>
          <div className='actions-btn'>
            <ActionButton
              color='#39B54A'
              width='160'
            >
              <Button
                onClick={() => {
                  const selectedTemplateFilter = data.filter(
                    (template) => template.id === selectedTemplate
                  )[0];

                  if (selectedTemplate && selectedTemplateFilter)
                    form.setFieldsValue({
                      ...selectedTemplateFilter,
                    });
                }}
              >
                Cancel
              </Button>
            </ActionButton>
            <ActionButton
              background='#39B54A'
              color='#FFFFFF'
              width='160'
            >
              <Button
                htmlType='submit'
                loading={loading}
              >
                Submit
              </Button>
            </ActionButton>
          </div>
        </Form>
      </TemplateWrapper>
    </Fragment>
  );
}

export default Template;
