
import { ActionButton } from "Styles/global";
import { Button } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";
import ROUTES from "Routes/route";

const SubmitSection = ({loading, navigate}) =>{
  return (
    <div style={{ display: "flex", gap: 10 }}>
      <ActionButton background="#FFFFFF" color=" #9B9B9B" width="150">
        <Button onClick={() => navigate(ROUTES.CAMP_PAGE)}>{SETUP_VALUES.CANCEL_TEXT}</Button>
      </ActionButton>

      <ActionButton background="#39B54A" color="#FFFFFF" width="150">
        <Button htmlType="submit" loading={loading}>
          {SETUP_VALUES.SUBMIT_TEXT}
        </Button>
      </ActionButton>
    </div>
  )
}


export default SubmitSection;