export default function UserIcon({ fill }) {
  return (
    <svg
      width="20"
      height="40"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.63314 9.05835C7.5498 9.05002 7.4498 9.05002 7.35814 9.05835C5.3748 8.99169 3.7998 7.36669 3.7998 5.36669C3.7998 3.32502 5.4498 1.66669 7.4998 1.66669C9.54147 1.66669 11.1998 3.32502 11.1998 5.36669C11.1915 7.36669 9.61647 8.99169 7.63314 9.05835Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6752 3.33331C15.2919 3.33331 16.5919 4.64165 16.5919 6.24998C16.5919 7.82498 15.3419 9.10831 13.7836 9.16665C13.7169 9.15831 13.6419 9.15831 13.5669 9.16665"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.4666 12.1333C1.44993 13.4833 1.44993 15.6833 3.4666 17.025C5.75827 18.5583 9.5166 18.5583 11.8083 17.025C13.8249 15.675 13.8249 13.475 11.8083 12.1333C9.52494 10.6083 5.7666 10.6083 3.4666 12.1333Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2832 16.6667C15.8832 16.5417 16.4499 16.3 16.9165 15.9417C18.2165 14.9667 18.2165 13.3584 16.9165 12.3834C16.4582 12.0334 15.8999 11.8 15.3082 11.6667"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
