export default function CloseBtnIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...props}
    >
      <circle cx="15" cy="15" r="15" fill="white" fill-opacity="0.1" />
      <path
        d="M15 5C9.49 5 5 9.49 5 15C5 20.51 9.49 25 15 25C20.51 25 25 20.51 25 15C25 9.49 20.51 5 15 5ZM18.36 17.3C18.65 17.59 18.65 18.07 18.36 18.36C18.21 18.51 18.02 18.58 17.83 18.58C17.64 18.58 17.45 18.51 17.3 18.36L15 16.06L12.7 18.36C12.55 18.51 12.36 18.58 12.17 18.58C11.98 18.58 11.79 18.51 11.64 18.36C11.35 18.07 11.35 17.59 11.64 17.3L13.94 15L11.64 12.7C11.35 12.41 11.35 11.93 11.64 11.64C11.93 11.35 12.41 11.35 12.7 11.64L15 13.94L17.3 11.64C17.59 11.35 18.07 11.35 18.36 11.64C18.65 11.93 18.65 12.41 18.36 12.7L16.06 15L18.36 17.3Z"
        fill="white"
      />
    </svg>
  );
}
