import { Col, Layout, Row, Form } from "antd";
import { LayoutWrapper, PageMainTitle } from "./styles";
import Sidebar from "Components/Shared/Sidebar";
import { Dropdown, Menu, Space, Typography, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import ProfileLogo from "Assets/Icons/ProfileLogo";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { resetData, requestCompleted, userProfileData } from "Redux/App";
import ExpandIcon from "Assets/Icons/Expand";
import CollapseIcon from "Assets/Icons/Collapsed";
import { EditPassword } from "Redux/App/Actions/Users";
import { SELECTED_COMPANY } from "constants/constants";
import { NotificationsModal } from "Components/Layout/Main/utils/notificationsModal";
import { getAllNotificationsForUser } from "Requests/notifications";
import { useInterval } from "Hooks/useInterval";
import { ChangePasswordDrawer } from "Components/Layout/Main/utils/changePasswordDrawer";
import { ChangeTimezoneDrawer } from "Components/Layout/Main/utils/changeTimezoneDrawer";

const { Content } = Layout;
const { Header } = Layout;

const CustomLayout = ({ padding, children }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(userProfileData);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [timezoneDrawerOpen, setTimezoneDrawerOpen] = useState(false);
  const [openNotificationsModal, setOpenNotificationsModal] = useState(false);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  const getNotifications = async () => {
    const notifications = await getAllNotificationsForUser();
    if (notifications.length > 0) {
      const seenNotifications = [];
      const unseenNotifications = [];
      notifications.forEach((notification) => {
        if (notification.is_seen) {
          seenNotifications.push(notification);
        } else {
          unseenNotifications.push(notification);
        }
      });
      setReadNotifications(seenNotifications);
      setUnreadNotifications(unseenNotifications);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  // Refresh the notifications every 30 seconds
  useInterval(getNotifications, 30000);

  const menu = (
    <Menu>
      <Menu.Item disabled style={{ cursor: "unset", color: "black" }}>
        {userData?.username}
      </Menu.Item>
      <Menu.Item onClick={() => setOpenNotificationsModal(!openNotificationsModal)}>Notifications</Menu.Item>
      <Menu.Item onClick={() => setTimezoneDrawerOpen(true)}>Timezone</Menu.Item>
      <Menu.Item onClick={() => setDrawerOpen(true)}>Change password</Menu.Item>
      <Menu.Item
        onClick={() => {
          setLoading(true);
          setTimeout(() => {
            localStorage.removeItem("token");
            localStorage.removeItem(SELECTED_COMPANY);
            dispatch(resetData());
            dispatch(requestCompleted());
            navigate("/login");
            window.location.reload();
          }, 2000);
        }}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const onFinish = (values) => {
    dispatch(EditPassword(values, setLoading, drawerOpen, setDrawerOpen, navigate));
  };
  return (
    <LayoutWrapper>
      <Layout className="layout-container">
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout className="site-layout">
          <Header className="nav-fixed header-bg-color ">
            <NotificationsModal
              openNotificationsModal={openNotificationsModal}
              setOpenNotificationsModal={setOpenNotificationsModal}
              readNotifications={readNotifications}
              unreadNotifications={unreadNotifications}
              getNotifications={getNotifications}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: 72,
                  width: 330,
                  gap: 40
                }}>
                {collapsed ? (
                  <CollapseIcon
                    className="trigger"
                    style={{
                      position: "relative",
                      right: location.pathname === "/create-promotion" ? 50 : 68,
                      cursor: "pointer"
                    }}
                    onClick={() => setCollapsed(!collapsed)}
                  />
                ) : (
                  <ExpandIcon
                    className="trigger"
                    style={{
                      position: "relative",
                      right: location.pathname === "/create-promotion" ? 50 : 68,
                      cursor: "pointer"
                    }}
                    onClick={() => setCollapsed(!collapsed)}
                  />
                )}

                <PageMainTitle>{children.props.title}</PageMainTitle>
              </div>
              <div>
                <Row justify="space-between">
                  <Col span={4}>
                    <div
                      style={{
                        position: "relative"
                      }}
                      className="user-profile">
                      {unreadNotifications.length > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: "green",
                            borderRadius: "6px",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            height: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            left: 30,
                            top: 8
                          }}>
                          {unreadNotifications.length}
                        </div>
                      )}
                      <Dropdown trigger={["hover"]} overlay={menu} placement="bottomLeft">
                        <Typography.Link>
                          <Space>
                            {loading && <LoadingSpinner />}
                            <ProfileLogo />
                          </Space>
                        </Typography.Link>
                      </Dropdown>
                    </div>
                  </Col>
                  <ChangePasswordDrawer
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    userData={userData}
                    onFinish={onFinish}
                    form={form}
                    loading={loading}
                  />
                  <ChangeTimezoneDrawer
                    timezoneDrawerOpen={timezoneDrawerOpen}
                    setTimezoneDrawerOpen={setTimezoneDrawerOpen}
                  />
                </Row>
              </div>
            </div>
          </Header>
          <Content className={padding ? "content-padding" : ""}>{children}</Content>
        </Layout>
      </Layout>
    </LayoutWrapper>
  );
};

export default CustomLayout;
