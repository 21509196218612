import { Upload, Form } from "antd"
import Uploads from "Assets/Icons/Uploads"
import { ChoosFile } from '../../styles';
import { ADD_IMAGE } from "constants/Images";

const {Dragger} = Upload;

const UploadCSV = ({ setSelectedFile }) => (
  <Form.Item>
    <Dragger
      beforeUpload={() => false}
      onChange={(info) => {
        const fileList = info.fileList;
        if (fileList.length > 0) {
          setSelectedFile(fileList[0]['originFileObj']);
        }
      }}
      accept=".csv"
    >
      <p className="ant-upload-drag-icon" style={ADD_IMAGE.MARGIN_TOP_15PX}>
        <Uploads size={30} color='#39b54a' />
      </p>
      <span style={ADD_IMAGE.STYLE_DROP_FILE}>
        <p className="ant-upload-text" style={ADD_IMAGE.STYLE_PARAGRAPH_DROP_FILE}>
          Drag & drop your file
        </p>
        <ChoosFile className="ant-upload-hint"> Choose File</ChoosFile>
      </span>
    </Dragger>
  </Form.Item>
)

export default UploadCSV;