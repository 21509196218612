import styled from "styled-components";

export const EditPromotionWrapper = styled.div`
  padding: 8px;

  .ant-picker {
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-right: 5px;
  }
  .ant-input-number {
    height: 42px;
    width: 132px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .action-buttons {
    margin-top: 45px;
  }
  .edit-coupon-wrapper {
    margin-top: 45px;
    display: flex;
    align-items: center;
    margin-left: 0px;
    gap: 10px;
  }
  .edit-coupon {
    margin-top: 12px;
    margin-right: 18px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-decoration-line: underline;
    color: #0097ec;
  }
  .coupon-icon {
    margin-top: 14px;
  }
  .edit-coupon:hover {
    cursor: pointer;
  }
  .menu-title {
    color: #0097ec;
    padding-top: px;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
  }
  .ant-input-number-status-success {
    width: 148px;
  }
  .ant-input-number-group > .ant-input-number:first-child {
    border-right: none;
  }
  ant-input-number-group-addon {
    background: none;
  }
`;

export const PromotionName = styled.div`
  overflow-wrap: break-word;
`;
export const DateHeading = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3d3d3d;
  display: inline-block;
`;
