import { SETUP_VALUES } from "constants/Campaign";
import { Form } from 'antd';
import getRequiredMsg from "utils/formRequiredMsg";
import DropDown from "../DropDown";

const CampStatus = ({ onChange }) => (
    <Form.Item
    label={SETUP_VALUES.CAMP_STATUS_CAPS}
    name={SETUP_VALUES.CAMP_STATUS}
    initialValue={SETUP_VALUES.PENDING}
    className="campaign-form-labels"
    rules={getRequiredMsg(SETUP_VALUES.ERR_SELECT_STATUS)}
  >
    <DropDown
      valArray={SETUP_VALUES.CAMP_STATUS_ARR}
      onChange={onChange}
    />
  </Form.Item>
)


export default CampStatus;