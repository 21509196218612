import {Modal, Input} from 'antd';

const FileNameModal = ({open, handleOk, handleCancel, setFileName, fileName}) => (
  <Modal
    title="File Name"
    okText="Export"
    open={open}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    <Input placeholder='Enter file name' value={fileName} onChange={(e) => setFileName(e.target.value)}  />
  </Modal>
)

export default FileNameModal;