import { Select, Form } from "antd"
import { ADD_IMAGE } from "constants/Images"
import getRequiredMsg from "utils/formRequiredMsg"

const Status = () => (                      
  <Form.Item
    name="status"
    label="Status"
    rules={getRequiredMsg('Please select status')}
  >
    <Select>
      {ADD_IMAGE.STATUS_ARR.map(each=> (
        <Select.Option
          key={each.key}
          value={each.key}
        >
          {each.value}
        </Select.Option>                            
      ))}
    </Select>
</Form.Item>
)

export default Status;