import styled from "styled-components";

export const UserTable = styled.div``;

export const Name = styled.p`
  .link {
    color: #0097ec;
  }
  .link:hover {
    cursor: pointer;
  }
`;
export const UsersModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
