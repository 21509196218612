import { useState } from 'react';
import { Popconfirm, Button } from 'antd';
import { ActionButton } from "Styles/global";
import {
  CopyCampaign,
  ArchiveCampaign,
} from "Redux/App/Actions/Campaigns";
import { SETUP_VALUES } from 'constants/Campaign';

const ArchiveSubmit = ({
  isScreenEdit,
  dispatch,
  navigate,
  editCampaign,
  setEditCampaign
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);

  if (isScreenEdit){
    return (
        <>
        <div style={{ display: "flex", gap: 20, marginBottom: 30 }}>
          <ActionButton background="#39B54A" color="#FFFFFF" width="150">
            <Button
              loading={actionLoading}
              disabled={actionLoading}
              onClick={() =>
                dispatch(
                  CopyCampaign(
                    editCampaign?.data?.id,
                    editCampaign,
                    setEditCampaign,
                    setActionLoading
                  )
                )
              }
            >
              {SETUP_VALUES.COPY_CAMPAIGN}
            </Button>
          </ActionButton>

          <ActionButton background="#39B54A" color="#FFFFFF" width="150">
            <Popconfirm
              title={SETUP_VALUES.CAMP_ARCHIVE}
              description={SETUP_VALUES.CAMP_ARCHIVE_DESC}
              onConfirm={() =>
                dispatch(
                  ArchiveCampaign(
                    editCampaign?.data?.id,
                    navigate,
                    setArchiveLoading
                  )
                )
              }
              onCancel={() => {}}
              okText={SETUP_VALUES.OK_TEXT}
              cancelText={SETUP_VALUES.CANCEL_TEXT_NO}
              icon={null}
            >
              <Button
                htmlType="submit"
                loading={archiveLoading}
                disabled={archiveLoading}
              >
                {SETUP_VALUES.ARCHIVE_TEXT}
              </Button>
            </Popconfirm>
          </ActionButton>
        </div>
      </>  
    )
  }
  return <></>
}

export default ArchiveSubmit;