import { Modal } from "antd";
import { useState } from "react";
import { ActionsDiv } from "../../Promotions/PromotionModal/styles";
import GrayTickIcon from "Assets/Icons/GrayTickIcon";
import LoadingSpinner from "Components/Shared/LoadingSpinner";

export const ActionsModal = ({ isModalOpen, setModalOpen, handleAction, statusOptions }) => {
  const [loading, setLoading] = useState(false);

  const closeStatusModal = () => {
    setModalOpen(false);
  };

  return (
    <Modal title="Actions" open={isModalOpen} onCancel={closeStatusModal} closable={true} footer={null} width={450}>
      {loading && <LoadingSpinner />}
      {!loading &&
        statusOptions.map((it) => {
          return (
            <ActionsDiv onClick={() => handleAction(it.value)}>
              <div className="action">
                {it?.logo}
                <span className="actions-title">{it.label}</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
          );
        })}
    </Modal>
  );
};
