import { Modal, Tabs } from "antd";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { NOTIFICATIONS } from "constants/constants";
import { useEffect, useState } from "react";
import moment from "moment";
import { updateNotificationsSeenStatus } from "Requests/notifications";

export const NotificationsModal = ({
  openNotificationsModal,
  setOpenNotificationsModal,
  readNotifications,
  unreadNotifications,
  getNotifications
}) => {
  const [selectedTab, setSelectedTab] = useState("Unread");
  const [notificationsData, setNotificationsData] = useState([]);
  const [updatingNotifications, setUpdatingNotifications] = useState(false);

  const tabItems = [
    {
      key: "Unread",
      label: "Unread",
      children: null
    },
    {
      key: "Read",
      label: "Read",
      children: null
    }
  ];

  const handleOk = async () => {
    setUpdatingNotifications(true);
    const notificationsIdsList = unreadNotifications.map((it) => it.id);
    await updateNotificationsSeenStatus(notificationsIdsList);
    await getNotifications();
    setUpdatingNotifications(false);
  };

  const handleCancel = () => {
    setOpenNotificationsModal(false);
  };

  const handleTabChange = (e) => {
    setSelectedTab(e);
  };

  useEffect(() => {
    if (selectedTab === "Unread") {
      setNotificationsData(unreadNotifications);
    } else {
      setNotificationsData(readNotifications);
    }
  }, [selectedTab, readNotifications, unreadNotifications]);

  return (
    <Modal
      title={NOTIFICATIONS}
      open={openNotificationsModal}
      onOk={handleOk}
      confirmLoading={updatingNotifications}
      onCancel={handleCancel}
      okText={"Mark All As Read"}
      cancelText={"Close"}
      width={"50vw"}
      height={"50vh"}
      okButtonProps={{
        disabled: selectedTab === "Read" || notificationsData.length === 0
      }}>
      <Tabs defaultActiveKey={selectedTab} items={tabItems} onChange={handleTabChange} />
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          flexDirection: "column",
          minHeight: "48vh",
          maxHeight: "48vh",
          overflow: "auto",
          backgroundColor: "#FEF9EB",
          border: "solid #E04F44",
          borderRadius: "8px",
          borderWidth: "1px"
        }}>
        {notificationsData.length > 0 ? (
          notificationsData.map((data, i) => {
            return (
              <div
                key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  flexDirection: "column",
                  margin: "6px 0px",
                  padding: "8px",
                  borderRadius: "8px",
                  color: data.status === "Error" ? "#E04F44" : "black",
                  fontWeight: data.status === "Error" ? "Bold" : "normal"
                }}>
                <div>{`${moment(data.created_at).format("DD MMM YYYY hh:mm")}`}</div>
                <div>{`${data.description}`}</div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              margin: "6px 0px",
              padding: "8px",
              borderRadius: "8px",
              color: "black"
            }}>
            No notifications to show
          </div>
        )}
      </div>
    </Modal>
  );
};
