const EDIT_COMPANY = {
  STATUS_ARR: [
    {
      value: "Active",
      label: "Active"
    },
    {
      value: "Pending",
      label: "Pending"
    },
    {
      value: "Archived",
      label: "Archived"
    }
  ],
  COUPON_ARR: [
    {
      value: true,
      label: "Active"
    },
    {
      value: false,
      label: "Inactive"
    }
  ],
  DRAWER_TITLE_STYLE: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999, // Make sure it's on top of other elements
    backgroundColor: "rgba(0, 0, 0, 0.1)" // Adjust opacity as needed
  },
  CREATE_PROMO_ARR: [
    {
      value: true,
      label: "Active"
    },
    {
      value: false,
      label: "Inactive"
    }
  ],
  INITIAL_VALUE_COMPANY: {
    company_name: "",
    coupon: null,
    create_promo_flag: null,
    id: null,
    logo_url: "",
    status: "",
    xibo_folder_id: ""
  }
};

const COMPANY = {
  PAGE_NO_ITEMS: ["10", "20", "50", "100"],
  COMPANY_TABLE_TITLE: [
    {
      title: "Logo URL",
      dataIndex: "logo_url"
    },
    {
      title: "Xibo Folder ID",
      dataIndex: "xibo_folder_id"
    },
    {
      title: "Status",
      dataIndex: "status"
    },
    {
      title: "Vistar Name",
      dataIndex: "vistar_name"
    },
    {
      title: "Advertiser ID",
      dataIndex: "company_ad_id"
    },
    {
      title: "Timezone",
      dataIndex: "time_zone"
    }
  ],
  COMPANY_TABLE_ONLY_TITLE: {
    title: "ID",
    dataIndex: "id"
  },
  INITIAL_COMPANY_DATA: {
    data: [],
    loading: false
  },
  INITIAL_DRAWER_VALUES: {
    screen: "",
    open: false
  },
  COMPANY_NAME_STYLE: { cursor: "pointer", color: "#39B54A" },
  COUPON_VALUE_TABLE: {
    title: "Coupon",
    dataIndex: "coupon"
  },
  CREATE_PROMO_TABLE: {
    title: "Create Promotion",
    dataIndex: "create_promo_flag"
  },
  COMPANY_NAME_TABLE: {
    title: "Company Name",
    dataIndex: "company_name"
  }
};

export { EDIT_COMPANY, COMPANY };
