export default function MessageIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="message-icon"
    >
      <path
        d="M10.6666 15.1666H1.99998C1.35998 15.1666 0.833313 14.64 0.833313 14V5.33331C0.833313 2.38665 2.38665 0.833313 5.33331 0.833313H10.6666C13.6133 0.833313 15.1666 2.38665 15.1666 5.33331V10.6666C15.1666 13.6133 13.6133 15.1666 10.6666 15.1666ZM5.33331 1.83331C2.94665 1.83331 1.83331 2.94665 1.83331 5.33331V14C1.83331 14.0933 1.90665 14.1666 1.99998 14.1666H10.6666C13.0533 14.1666 14.1666 13.0533 14.1666 10.6666V5.33331C14.1666 2.94665 13.0533 1.83331 10.6666 1.83331H5.33331Z"
        fill="#9B9B9B"
      />
      <path
        d="M11.3334 6.83331H4.66669C4.39335 6.83331 4.16669 6.60665 4.16669 6.33331C4.16669 6.05998 4.39335 5.83331 4.66669 5.83331H11.3334C11.6067 5.83331 11.8334 6.05998 11.8334 6.33331C11.8334 6.60665 11.6067 6.83331 11.3334 6.83331Z"
        fill="#9B9B9B"
      />
      <path
        d="M9.33335 10.1667H4.66669C4.39335 10.1667 4.16669 9.94002 4.16669 9.66669C4.16669 9.39335 4.39335 9.16669 4.66669 9.16669H9.33335C9.60669 9.16669 9.83335 9.39335 9.83335 9.66669C9.83335 9.94002 9.60669 10.1667 9.33335 10.1667Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
