import axios from "axios";
import { notification } from "antd";
import https from "https";
import { requestCompleted, requestStart, setCompany, setUserProfile } from "../..";
import API_ROUTES from "Routes/api";
import AUTH_VALUES from "constants/Auth";
import { getURL } from "utils";

const agent = new https.Agent({
  rejectUnauthorized: false,
  requestCert: false,
  agent: false
});

export function LoginAction(data, navigate) {
  return async (dispatch) => {
    dispatch(requestStart());
    var bodyFormData = new FormData();
    bodyFormData.append("username", data.username);
    bodyFormData.append("password", data.password);
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/login`, bodyFormData, { httpsAgent: agent })
        .then((res) => {
          if (res) {
            localStorage.setItem("token", res.data.access_token);
            dispatch(GetUserProfile());

            navigate("/");
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetUserProfile() {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res.data.permission_level !== "Fmtv") {
            dispatch(setCompany(res.data.company));
          }
          dispatch(setUserProfile(res.data));
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export async function fetchTerms(setTermsLoading, setTerms) {
  try {
    setTermsLoading(true);
    const response = await axios.get(getURL(API_ROUTES.GET_TERMS));
    setTerms(response.data);
  } catch (error) {
    console.error(AUTH_VALUES.API_TERMS_ERR, error);
  } finally {
    setTermsLoading(false);
  }
}
