import {Modal} from 'antd';

const EditModal = ({isOpen, handleOk, handleCancel}) => (
  <Modal
    title="Company name change confirmation"
    open={isOpen}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    <p>
      Confirm that the Company Name should be changed throughout the system
    </p>
  </Modal>
)

export default EditModal;