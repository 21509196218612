import { Drawer, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import {notification, Tabs } from 'antd';
import { DrawerBodyPadding, TemplateWrapper } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from 'Redux/App/Actions/Companies';
import { userProfileData, companyName } from "Redux/App";
import {
  CreateImageTemplates,
  EditImageTemplates,
  saveMultipleImgs,
  GetImageTemplates,
} from 'Redux/App/Actions/ImageTemplates';
import Loader from './add/Loader';
import TitleSection from './add/TitleSection';
import Name from './add/FormItems/Name';
import Tag from './add/FormItems/Tag';
import StatusDropdown from './add/FormItems/StatusDropdown';
import CompanyDropdown from './add/FormItems/CompanyDropdown';
import UploadSection from './add/FormItems/UploadSection';
import SubmitSection from './add/FormItems/Submit';
import UploadCSV from './add/FormItems/UploadCSV';
import uploadPropsFile from './add/uploadProps';
import { ADD_IMAGE } from 'constants/Images';

const UsersDrawer = (props) => {
  const isEditImg = props.drawerOpen.screen === 'Edit Image'
  const [form] = Form.useForm();
  const [fontfileForm] = Form.useForm();
  const userData = useSelector(userProfileData);
  const selectedCompany = useSelector(companyName);
  const [companies, setCompanies] = useState(ADD_IMAGE.COMPANY_INITIAL_STATE);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [createTemplateLoading, setCreateTemplateLoading] = useState(false);

  const [file, setFile] = useState([]);
  const dispatch = useDispatch();
  const [selectedTabId, setSelectedTabId] = useState();
  const onChange = (key) => {
    setSelectedTabId(key);
  };

  useEffect(() => {
    form.setFieldsValue({company: userData?.permission_level === "Fmtv" ? selectedCompany : userData?.company})
  }, [selectedCompany]);

  useEffect(() => {
    dispatch(getCompanies(companies, setCompanies));
    if (props.selectedTemplate && isEditImg) {
      form.setFieldsValue(props.selectedTemplate);
    }
  }, [props, form]);
  useEffect(() => {
    dispatch(GetImageTemplates());
  }, [])


  const onFinishImgUpload = (values) => {
    if (isEditImg) {
      file.forEach((i) => {
        if (i.selectedFile) {
          values.name = i.selectedFile.name;
          values.image = i.fileAsText.split('base64,')[1];
        }
      });

      values.id = props.selectedTemplate.id;
      dispatch(EditImageTemplates(values, props, setCreateTemplateLoading));
    } else {
      if (file.length === 0) {
        notification.error({ message: 'Please select atleast one image!' });
        return;
      }
      const mappedValues = file.map((i) => {
        if (i.selectedFile) {
          values.name = i.selectedFile.name;
          values.image = i.fileAsText.split('base64,')[1];
        }
        return {
          tags: values.tags,
          name: i.selectedFile.name,
          image: (values.image = i.fileAsText.split('base64,')[1]),
          company: values.company,
          status: values.status,
        };
      });
      dispatch(
        CreateImageTemplates(mappedValues, props, setCreateTemplateLoading,setFile)
      );

    }
  };

  const onFinishCSVUpload = values => {
    const formDataToSend = new FormData();
    formDataToSend.append('csv_file', selectedFile);
    checkNumberOfLines(selectedFile)

    dispatch(saveMultipleImgs(formDataToSend, props, setCreateTemplateLoading, values.company));
  }
  const checkNumberOfLines = (uploadedFile) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      const lines = fileContent.split('\n');
      if (lines.length > 100) {
        notification.info({ message: 'The file contains more than 100 items.  Only the first 100 will be processed!' });
      }
    };
    reader.readAsText(uploadedFile);
  };

  const uploadProps = uploadPropsFile(file,setFile);

  const handleCancelClick = () => props.setDrawerOpen(false)

  return (
    <Drawer
      headerStyle={{ display: 'none' }}
      title="Add new video template"
      placement={'right'}
      closable={false}
      onClose={() => {
        setFile([]);
        form.resetFields();
        handleCancelClick();
      }}
      open={props.drawerOpen.open}
      destroyOnClose={true}
    >
      <Loader loading={createTemplateLoading}/>
      <TitleSection
        handleClick={handleCancelClick}
        screen={props.drawerOpen.screen}
      />
      <DrawerBodyPadding>
        <TemplateWrapper>
          <Tabs
            onChange={onChange}
            type="card"
            items={new Array(2).fill(null).map((_, i) => {
              const id = String(i + 1);
              if (isEditImg && id === '2') return null
              return {
                label: id === '1' ? 'Source Link' : 'Upload file',
                key: id,
                children:
                  <div>{id === "1" ?
                    <Form onFinish={onFinishImgUpload} form={form} layout="vertical">
                      <Name isEdit={isEditImg}/>
                      <Tag />
                      <StatusDropdown />
                      <CompanyDropdown companiesData={companies?.data}
                      />
                      <UploadSection
                        isEditImgNotTrue={!isEditImg}
                        uploadProps={uploadProps}
                      />                      
                      <SubmitSection
                        handleCancelClick={handleCancelClick}
                      />
                    </Form>
                    :
                    <Form form={fontfileForm} layout='vertical' onFinish={onFinishCSVUpload}>
                      <div>
                        <CompanyDropdown companiesData={companies.data} />
                        <UploadCSV setSelectedFile={setSelectedFile} />
                      </div>
                      <SubmitSection handleCancelClick={handleCancelClick} />
                    </Form>
                  }</div>,
              };
            })}
          />
        </TemplateWrapper>
      </DrawerBodyPadding>
    </Drawer>
  );
};

export default UsersDrawer;
