import { Drawer, Form } from "antd";
import React, { useEffect, useState } from "react";
import { Select, Input, Button, Row, Col } from "antd";

import { DrawerTitle, MainTitle, ActionButton, CloseBtnWrapper } from "Styles/global";
import { DrawerBodyPadding } from "./styles";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { EditUsers, CreateUsers } from "Redux/App/Actions/Users";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "Redux/App/Actions/Companies";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { userProfileData, companyName } from "Redux/App";

const UsersDrawer = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userData = useSelector(userProfileData);
  const selectedCompany = useSelector(companyName);
  const [companies, setCompanies] = useState({
    data: [],
    loading: false
  });
  const dispatch = useDispatch();
  const [selectedPermissionLevel, setSelectedPermissionLevel] = useState("");

  useEffect(() => {
    if (userData && userData?.permission_level === "Fmtv") {
      dispatch(getCompanies(companies, setCompanies));
    }

    if (props.drawerOpen.screen === "Edit User") {
      const timezoneId = props.timezones.filter((it) => it.time_zone === props?.userData?.data?.time_zone)[0]?.id;
      form.setFieldsValue({
        username: props?.userData?.data?.username,
        email: props?.userData?.data?.email,
        permission_level: props?.userData?.data?.permission_level,
        company: props?.userData?.data?.company,
        timezone_id: timezoneId ? timezoneId : props?.userData?.data?.time_zone
      });
    } else {
      form.resetFields();
    }
  }, [props, form]);

  const handlePermissionChange = (value) => {
    setSelectedPermissionLevel(value);
    const defaultCompanyValue =
      selectedPermissionLevel === "FMTV"
        ? userData?.company_name // Add this line
        : userData?.permission_level === "Fmtv"
          ? selectedCompany
          : userData?.company_name;
    form.setFieldsValue({
      company: defaultCompanyValue
    });
  };

  const onFinish = (values) => {
    if (!values.company || values.company === userData.company_name) {
      values.company = userData?.company;
    }

    if (props.drawerOpen.screen === "Edit User") {
      if (userData && userData?.permission_level !== "Fmtv") {
        values.company = userData?.company;
      }
      values.id = props?.userData?.data?.id;

      dispatch(EditUsers(values, setLoading, props.drawerOpen, props.setDrawerOpen));
    } else {
      if (userData && userData?.permission_level !== "Fmtv") {
        values.company = userData?.company;
      }

      dispatch(CreateUsers(values, setLoading, props.drawerOpen, props.setDrawerOpen));
    }
  };

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      title="Basic Drawer"
      placement={"right"}
      closable={false}
      onClose={() => props.setDrawerOpen(false)}
      open={props.drawerOpen.open}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={() => props.setDrawerOpen(false)}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">{props.drawerOpen.screen}</p>
        </MainTitle>
      </DrawerTitle>
      <DrawerBodyPadding>
        <Form onFinish={onFinish} form={form} layout="vertical">
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Please enter username"
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter email"
              }
            ]}>
            <Input disabled={props.drawerOpen.screen === "Edit User" ? true : false} />
          </Form.Item>
          <Form.Item
            name="permission_level"
            label="Permission Level"
            rules={[
              {
                required: true,
                message: "Please select permission level"
              }
            ]}>
            <Select
              onChange={(value) => handlePermissionChange(value)}
              disabled={userData?.permission_level === "Fmtv" || userData?.permission_level === "Admin" ? false : true}>
              <Select.Option value="Admin">Admin</Select.Option>
              {userData?.permission_level === "Fmtv" && <Select.Option value="FMTV">FMTV</Select.Option>}
              <Select.Option value="SuperUser">Super User</Select.Option>
              <Select.Option value="Creator">Creator</Select.Option>
              <Select.Option value="Publisher">Publisher</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="company"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please select company"
              }
            ]}
            initialValue={userData?.company}>
            <Select
              // disabled={userData?.permission_level === "Fmtv" ? false : true}
              disabled={
                selectedPermissionLevel === "FMTV" ? true : userData?.permission_level === "Fmtv" ? false : true
              }>
              {companies.data.map((company) => (
                <Select.Option value={company?.id}>{company.company_name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="timezone_id"
            label="Timezone"
            rules={[
              {
                required: true,
                message: "Please select your preferred timezone"
              }
            ]}>
            <Select>
              {props.timezones.map((timezone) => (
                <Select.Option value={timezone?.id}>{timezone?.time_zone}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          {loading && <LoadingSpinner />}

          <Row className="action-buttons ">
            <Col span="12">
              <ActionButton background="##FFFFFF;" color=" #9B9B9B" width="140">
                <Button onClick={() => props.setDrawerOpen(false)}>Cancel</Button>
              </ActionButton>
            </Col>
            <Col span="12">
              <ActionButton background="#39B54A" color="#FFFFFF" width="140">
                <Button htmlType="submit">Submit</Button>
              </ActionButton>
            </Col>
          </Row>
        </Form>
      </DrawerBodyPadding>
    </Drawer>
  );
};

export default UsersDrawer;
