import { FilterFilled } from "@ant-design/icons";
import SearchCol from "./SearchCol";

const getColumnSearchPropsFn = (
  searchInput,
  dataIndex,
  handleSearch,
  buttonRef,
  handleReset,
  searchedColumn
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (      
    <SearchCol
      searchInput={searchInput}
      dataIndex={dataIndex}
      selectedKeys={selectedKeys}
      setSelectedKeys={setSelectedKeys}
      handleSearch={handleSearch}
      confirm={confirm}
      buttonRef={buttonRef}
      clearFilters={clearFilters}
      handleReset={handleReset}
    />
  ),
  filterIcon: (filtered) => (
    <FilterFilled
      style={{
        color: filtered ? "#1890ff" : undefined,
      }}
    />
  ),
  onFilter: (value, record) => {
    if (record[dataIndex]) {
      if (typeof record[dataIndex] === "object") {
        return Object.values(record[dataIndex]).some((val) => {
          return val?.toString()?.toLowerCase()?.includes(value.toLowerCase());
        });
      } else {
        return record[dataIndex]
          ?.toString()
          ?.toLowerCase()
          ?.includes(value.toLowerCase());
      }
    }
  },
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (text ? text.toString() : "") : text,
});

export default getColumnSearchPropsFn;