export default function CollapsedIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        r="20.5"
        transform="matrix(-1 0 0 1 21 21)"
        fill="white"
        stroke="black"
      />
      <path d="M16 15L26 21.5L16 28" stroke="black" />
    </svg>
  );
}
