import styled from "styled-components";

export const DrawerBodyPadding = styled.div`
  padding: 12px 24px;
  .ant-input {
    height: 46px;
    border-radius: 4px;
  }
  .ant-select {
    margin-top: 10px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 46px;
    border-radius: 4px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-top: 6px;
  }
  .ant-form-item-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #3d3d3d;
  }
`;
