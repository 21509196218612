import { Form } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";
import getRequiredMsg from "utils/formRequiredMsg";
import DropDown from "../DropDown";

const XIBOStatus = ({ required }) => (
  <Form.Item
    name={SETUP_VALUES.XIBO_STATUS_}
    label={SETUP_VALUES.XIBO_STATUS}
    className="campaign-form-labels"
    initialValue={SETUP_VALUES.PENDING}
    rules={getRequiredMsg(SETUP_VALUES.ERR_XIBO_STATUS, required)}
  >
    <DropDown
      valArray={SETUP_VALUES.XIBO_STATUS_ARR}
      defaultValue={SETUP_VALUES.PENDING}
    />
  </Form.Item>
)

export default XIBOStatus;