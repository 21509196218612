import { Modal } from "antd";
import React, { useState } from "react";
import { ActionsDiv, ModalBtn } from "./styles";
import GrayTickIcon from "Assets/Icons/GrayTickIcon";
import MouseSquare from "Assets/Icons/MouseSquare";
import SmartHomeIcon from "Assets/Icons/SmartHomeIcon";
import SaveIcon from "Assets/Icons/SaveIcon";
import MessageIcon from "Assets/Icons/MessageIcon";
import CopyIcon from "Assets/Icons/CopyIcon";
import { DeleteUsers } from "Redux/App/Actions/Users";
import { companyName } from "Redux/App";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "Components/Shared/LoadingSpinner";

const PromotionModal = (props) => {
  console.log(props);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedCompany = useSelector(companyName);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalBtn onClick={showModal}>
        <MouseSquare />
      </ModalBtn>
      <Modal
        title="Manage Users"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        width={500}
        footer={null}
      >
        {/* <ActionsDiv onClick={() => alert("Copy")}>
          <div className="action">
            <CopyIcon />
            <span className="actions-title"> Delete</span>
          </div>
          <GrayTickIcon />
        </ActionsDiv>

        <ActionsDiv>
          <div className="action">
            <SmartHomeIcon />
            <span className="actions-title"> Publish</span>
          </div>
          <GrayTickIcon />
        </ActionsDiv> */}
        <ActionsDiv
          onClick={() =>
            dispatch(
              DeleteUsers(
                props.selectedRowKeys,
                setLoading,
                setIsModalOpen,
                selectedCompany
              )
            )
          }
        >
          <div className="action">
            <MessageIcon />
            <span className="actions-title">Delete</span>
          </div>
          <GrayTickIcon />
        </ActionsDiv>
        {/* <ActionsDiv>
          <div className="action">
            <SaveIcon />
            <span className="actions-title">Archive</span>
          </div>
          <GrayTickIcon />
        </ActionsDiv> */}

        {loading && <LoadingSpinner />}
      </Modal>
    </>
  );
};

export default PromotionModal;
