import { ActionButton, CloseBtnWrapper, DrawerTitle, MainTitle } from "Styles/global";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { Button, Col, Form, Input, Row } from "antd";
import { PASSWORD_VALIDATION_RULES } from "constants/Auth";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import DrawerComponent from "Components/Shared/Drawer";
import React from "react";

export const ChangePasswordDrawer = ({ drawerOpen, setDrawerOpen, onFinish, form, userData, loading }) => {
  return (
    <DrawerComponent drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={() => setDrawerOpen(false)}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">Change Password</p>
        </MainTitle>
      </DrawerTitle>
      <Form onFinish={onFinish} form={form} layout="vertical" style={{ marginTop: 50 }}>
        <Form.Item
          name="email"
          label="Email"
          initialValue={userData?.email}
          rules={[
            {
              required: true,
              message: "Please enter email"
            }
          ]}>
          <Input disabled />
        </Form.Item>

        <Form.Item name="password" label="Password" rules={PASSWORD_VALIDATION_RULES}>
          <Input type="password" />
        </Form.Item>

        <Row className="action-buttons ">
          <Col span="12">
            <ActionButton background="##FFFFFF;" color=" #9B9B9B" width="140">
              <Button onClick={() => setDrawerOpen(false)}>Cancel</Button>
            </ActionButton>
          </Col>
          <Col span="12">
            <ActionButton background="#39B54A" color="#FFFFFF" width="140">
              <Button htmlType="submit" loading={loading}>
                Update
              </Button>
            </ActionButton>
          </Col>
        </Row>
      </Form>
    </DrawerComponent>
  );
};
