import styled from "styled-components";

export const PromotionsWrapper = styled.div`
  .ant-tabs-tab {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #9b9b9b;
  }

  .ant-checkbox-wrapper > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #9b9b9b;
  }
`;

export const NewPromotionWrapper = styled.div`
  .ant-table-thead > tr > th {
    background: rgba(58, 181, 74, 0.1);
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #3d3d3d;
  }
  .ant-table-thead > tr > th:before {
    display: none;
  }
  .ant-avatar-square {
    width: 100px !important;
    height: 55px;
  }
  .ant-table-tbody {
    color: #555555;
  }
`;
export const Name = styled.div`
  color: #0097ec;
`;

export const PromotionModalWrapper = styled.div`
  display: flex;
  justify-content: right;
`;
