const values = {
  CREATE_CAMP: "Create Campaign",
  CAMP_NAME: "Campaign Name",
  SCREEN_TYPE: "Screen Type",
  CAMP_SELECT_VALS: ["Forecourt", "In-Store", "Both"],
  CURRENT_DESC: "Current Description",
  SUBMIT_BTN: "Submit",
  CANCEL_BTN: "Cancel",
  ERR_MSG_SCREEN_TYPE: "Please enter screen type",
  CAMP_NAME_: "campaign_name",
  SCREEN_TYPE_: "screen_type",
  CURRENT_DESC_: "current_description"
};

const DRAWER_VALUES = {
  DRAWER_MENU_ITEMS: [
    {
      key: "1",
      label: "Item 1"
    },
    {
      key: "2",
      label: "Item 2"
    },
    {
      key: "3",
      label: "Item 3"
    }
  ],
  BASIC_DRAWER: "Basic Drawer",
  DISPLAY_NONE: { display: "none" },
  RIGHT: "right",
  STATUS: "Status",
  CAMP_DETAILS: "Campaign details",
  CAMPS: "Campaigns",
  CAMPS_LONG_TEXT: "ARCD-DOFO : JUL-AUG",
  SCREEN_TYPE: "Screen Type",
  ONE_TWENTY: "120",
  CURRENT_DESC: "Current Discription",
  REQ_CHNGS: "Request Changes",
  PARA: "Start your journey by purchasing our Creci Notes so you can start earning a 5% fixed return while supporting small and medium businesses that further the United Nations Sustainable Development Goals."
};

const SETUP_VALUES = {
  VISTAR_INFO: "Vistar Info",
  INITIAL_ARR: {
    data: [],
    loading: false
  },
  VISTAR_INITIAL_STATE: {
    status: "",
    screen_type: "",
    required: false,
    xiboRequired: false
  },
  ERR_ENTER_CAMP_NAME: "Please enter campaign name",
  ERR_ENTER_COMP: "Please select company",
  UPDATE_NAME_: "updated_name",
  CAMP_NAME_: "campaign_name",
  CAMP_NAME: "Campaign Name",
  SCREEN_TYPE_: "screen_type",
  COMPANY_TEXT: "Company",
  COMPANY_ID_: "company_id",
  SCREEN_TYPE: "Screen Type",
  ERR_SCREEN_TYPE: "Please enter screen type",
  ERR_SELECT_STATUS: "Please select status",
  ERR_BONUS_TAGS: "Please enter the bonus tags",
  ERR_CAMP_TYPE: "Please select campaign type",
  ERR_VISTAR_STATUS: "Please enter the vistar status",
  ERR_ORDER_LINE_ID: "Please enter the order line id",
  ERR_XIBO_STATUS: "Please enter the xibo status",
  REQ_CHANGE_: "requested_changes",
  REQ_CHANGE: "Requested Changes",
  CAMP_STATUS: "status",
  CAMP_STATUS_CAPS: "Status",
  PENDING: "pending",
  REQ_BY_: "requested_by",
  REQ_BY: "Requested by",
  CUR_DESC_: "current_description",
  CUR_DESC: "Current Description",
  BONUS_TAGS: "Bonus Tags",
  BONUS_TAGS_: "bonus_tags",
  CAMP_TYPE: "Campaign Type",
  CAMP_TYPE_: "campaign_type",
  VISTAR_STATUS: "Vistar Status",
  VISTAR_STATUS_: "vistar_status",
  ORDER_LINE_ID: "Order Line Id",
  ORDER_LINE_ID_: "order_line_id",
  ADVERTISER_OVERRIDE: "Advertiser Override",
  ADVERTISER_OVERRIDE_: "advertiser_override",
  XIBO_STATUS_: "xibo_status",
  XIBO_STATUS: "Xibo status",
  XIBO_INFO: "Xibo Info",
  COPY_CAMPAIGN: "Copy Campaign",
  SUBMIT_TEXT: "Submit",
  CANCEL_TEXT: "Cancel",
  CAMP_ARCHIVE: "Archive this campaign?",
  CAMP_ARCHIVE_DESC: "Are you sure to Archive this Campaign?",
  ARCHIVE_TEXT: "Archive",
  OK_TEXT: "Yes",
  CANCEL_TEXT_NO: "No",
  CAMP_TYPE_ARR: [
    { key: "Standard", value: "Standard" },
    { key: "Bonus", value: "Bonus" }
  ],
  VISTAR_STATUS_ARR: [
    { key: "pending", value: "Pending" },
    { key: "active", value: "Active" }
  ],
  XIBO_STATUS_ARR: [
    { key: "pending", value: "Pending" },
    { key: "active", value: "Active" }
  ],
  CAMP_STATUS_ARR: [
    { key: "pending", value: "Pending" },
    { key: "active", value: "Active" },
    { key: "archived", value: "Archived" }
  ],
  SCREEN_TYPE_ARR: [
    { key: "Gas-Dispenser", value: "Gas Dispenser" },
    { key: "In-Store", value: "In-Store" },
    { key: "Both", value: "Both" }
  ],
  HEIGHT_150: { height: "150px" },
  LOADING_OVERLAY: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(128, 128, 128, 0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999
  },
  LOADING_OVERLAY_SPINNER: {
    padding: "20px",
    borderRadius: "8px"
  }
};

export { DRAWER_VALUES, SETUP_VALUES };

export default values;
