import axios from "axios";
import { requestStart, requestCompleted, requestSuccess } from "../..";
import { notification } from "antd";
import { GetUserProfile } from "Redux/App/Actions/Auth";

export function GetUsers() {
  return async (dispatch, state) => {
    const stateData = state();
    dispatch(requestStart());
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/user?company=${stateData.app.selectedCompany}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            let dataWithKey = res.data.map((item) => {
              return { ...item, key: item.id };
            });
            dispatch(requestSuccess(dataWithKey));
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetUserDetails(email, userData, setUserData) {
  return async (dispatch) => {
    setUserData({ ...userData, loading: true });
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/user/${email}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          setUserData({ ...userData, data: res.data, loading: false });
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function EditUsers(data, setLoading, drawerOpen, setDrawerOpen) {
  return async (dispatch, state) => {
    setLoading(true);
    try {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + "/user/edit", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            dispatch(requestSuccess());
            setLoading(false);
            setDrawerOpen({ ...drawerOpen, open: false });
            notification.success({
              message: "User updated successfully"
            });
            // if (state().app.id === res.data.id) dispatch(GetUserProfile());
            dispatch(GetUserProfile());
            dispatch(GetUsers());
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function DeleteUsers(data, setLoading, setIsModalOpen, selectedCompany) {
  return async (dispatch) => {
    setLoading(true);
    try {
      axios
        .delete(process.env.REACT_APP_API_BASE_URL + `/user/delete?&company_id=${selectedCompany}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          data: data
        })
        .then((res) => {
          if (res) {
            setLoading(false);
            setIsModalOpen(false);
            notification.success({
              message: "Users deleted successfully"
            });

            dispatch(requestSuccess(res.data));
          }
        })
        .catch((error) => {
          console.log(error);
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function CreateUsers(data, setLoading, drawerOpen, setDrawerOpen) {
  return async (dispatch) => {
    setLoading(true);
    try {
      const res = await axios.post(process.env.REACT_APP_API_BASE_URL + "/user/add", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (res) {
        setLoading(false);
        setDrawerOpen({ ...drawerOpen, open: false });
        notification.success({
          message: "Users created successfully"
        });
        dispatch(GetUsers());
      }
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
      });
      setLoading(false);
      dispatch(requestCompleted());
    }
  };
}

export function SortUsers(column, order, selectedCompany) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            `/user/sort/${column}?order=${order}&offset=0&company_id=${selectedCompany}&limit=100`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res) {
            dispatch(requestSuccess(res.data));
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function EditPassword(data, setLoading, drawerOpen, setDrawerOpen, navigate) {
  return async (dispatch) => {
    setLoading(true);
    try {
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "/change-password", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            setLoading(false);
            setDrawerOpen(false);
            notification.success({
              message: res.data
            });
            navigate("/");
          }
        })
        .catch((error) => {
          setLoading(false);
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResetPassword(data, setLoading, drawerOpen, setDrawerOpen, navigate) {
  return async (dispatch) => {
    setLoading(true);
    console.log(data);
    try {
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "/reset-password", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            setLoading(false);
            setDrawerOpen(false);
            notification.success({
              message: res.data
            });
            navigate("/");
          }
        })
        .catch((error) => {
          setLoading(false);
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function RequestPassword(data, navigate, successMsg = "Password reset email sent successfully") {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/forgot-password/${data?.email}`, null)
        .then((res) => {
          if (res) {
            notification.success({
              message: successMsg
            });
            navigate("/");
            dispatch(requestCompleted());
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
