import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, Avatar, Form, DatePicker, InputNumber } from "antd";
import Barcode from "react-barcode/lib/react-barcode";
import {
  DrawerTitle,
  MainTitle,
  DrawerFormHeading,
  ActionButton,
  BorderStyle,
  BarcodeWrapper,
  DrawerBodyPadding,
  CloseBtnWrapper
} from "Styles/global";
import { DrawersWrapper } from "./styles";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import Calendar from "Assets/Icons/Calendar";
import Coupon from "../../../Assets/Images/coupon.png";
import { UpdateCoupan } from "Redux/App/Actions/Promos";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import moment from "moment";
import { userProfileData } from "Redux/App";

export const EditCoupon = (props) => {
  const { couponOptions, setCouponOptions } = props;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const userData = useSelector(userProfileData);

  useEffect(() => {
    form.setFieldsValue({
      title: props?.drawerOpen?.editPromotionData?.coupon_details?.title,
      detail: props?.drawerOpen?.editPromotionData?.coupon_details?.detail,
      restriction: props?.drawerOpen?.editPromotionData?.coupon_details?.restriction,
      validFrom: props?.drawerOpen?.editPromotionData?.coupon_details?.validFrom ?? "",
      barcode: props?.drawerOpen?.editPromotionData?.coupon_details?.barcode
    });
  }, [props, props.drawerOpen]);

  const onFinish = (values) => {
    if (props.isAdd) {
      setCouponOptions({ ...couponOptions, coupon_details: values });
      props.setDrawerOpen({
        ...props.drawerOpen,
        UploadPromotionDrawer: true,
        AddCouponDrawer: false
      });
      return;
    }
    dispatch(
      UpdateCoupan(
        "Edit Coupon Screen",
        {
          coupon_details: values,
          promo_id: props?.drawerOpen?.editPromotionData?.id,
          coupon: true
        },
        setLoading,
        props
      )
    );
  };

  useEffect(() => {
    if (props?.isAdd && couponOptions?.coupon_details?.title) form.setFieldsValue(couponOptions.coupon_details);
  }, []);

  const handleCancel = () => {
    if (props.isAdd) {
      props.setDrawerOpen({
        ...props.drawerOpen,
        AddCouponDrawer: false,
        UploadPromotionDrawer: true
      });
      return;
    }
    props.setDrawerOpen({
      ...props.drawerOpen,
      EditPromotionDrawer: true,
      EditCouponDrawer: false
    });
  };
  return (
    <Form onFinish={onFinish} form={form}>
      <DrawersWrapper>
        <DrawerTitle height={144}>
          <CloseBtnWrapper onClick={handleCancel}>
            <CloseBtnIcon />
          </CloseBtnWrapper>
          <MainTitle>
            <p className="main-title">Edit Coupon</p>
          </MainTitle>
        </DrawerTitle>
        <div className="avatar-wrapper">
          <Avatar className="coupon-avatar" size={90} src={Coupon} />
        </div>

        <DrawerBodyPadding>
          <DrawerFormHeading>Title</DrawerFormHeading>

          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: "Cannot be left empty"
              },
              { max: 35, message: "Title can be maximum 35 characters." }
            ]}>
            <Input />
          </Form.Item>

          <DrawerFormHeading>Details</DrawerFormHeading>

          <Form.Item
            name="detail"
            rules={[
              {
                required: true,
                message: "Cannot be left empty"
              },
              { max: 100, message: "Details section can be maximum 100 characters." }
            ]}>
            <TextArea />
          </Form.Item>

          <DrawerFormHeading>Restriction</DrawerFormHeading>

          <Form.Item
            name="restriction"
            rules={[
              {
                required: true,
                message: "Cannot be left empty"
              },
              { max: 25, message: "Restrictions section can be maximum 25 characters." }
            ]}>
            <TextArea />
          </Form.Item>

          <DrawerFormHeading>Valid From</DrawerFormHeading>
          <Form.Item
            name="validFrom"
            rules={[
              {
                required: true,
                message: "Cannot be left empty"
              },
              { max: 25, message: "Valid From section can be maximum 25 characters." }
            ]}>
            <Input />
          </Form.Item>

          <DrawerFormHeading>Barcode</DrawerFormHeading>

          <Form.Item
            name="barcode"
            rules={[
              {
                required: true,
                message: "Cannot be left empty"
              },
              { max: 12, message: "Barcode can be maximum 12 characters." },
              {
                pattern: /^(?:\d*)$/,
                message: "Barcode can only contain numbers"
              }
            ]}>
            <Input />
          </Form.Item>

          <DrawerFormHeading>Barcode</DrawerFormHeading>
          <BarcodeWrapper>
            <Barcode
              value={props?.drawerOpen?.editPromotionData?.coupon_details?.barcode}
              displayValue={false}
              width={1.5}
            />
          </BarcodeWrapper>

          {loading && <LoadingSpinner />}

          <Row className="action-buttons ">
            <Col span="12">
              <ActionButton background="##FFFFFF;" color=" #9B9B9B" width="150">
                <Button onClick={handleCancel}>Cancel</Button>
              </ActionButton>
            </Col>

            {userData?.permission_level !== "Publisher" && (
              <Col span="12">
                <ActionButton background="#39B54A" color="#FFFFFF" width="150">
                  <Button htmlType="submit">Submit</Button>
                </ActionButton>
              </Col>
            )}
          </Row>
        </DrawerBodyPadding>
      </DrawersWrapper>
    </Form>
  );
};
