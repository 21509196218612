
import { Modal } from 'antd';
import RenderMedia from './RenderMedia';

const ImgTableModal = ({open, onOk, promoVideo}) => (
  <Modal
  title="Preview"
  destroyOnClose={true}
  width={1000}
  centered
  open={open}
  footer={null}
  onOk={onOk}
  onCancel={onOk}
>
  <RenderMedia promoVideo={promoVideo} />
</Modal>
)

export default ImgTableModal;