import React, { useEffect, useState } from "react";
import { Tabs, Checkbox } from "antd";
import { PromotionsWrapper, PromotionModalWrapper } from "./styles";
import PromotionsTable from "./PromotionsTable";
import { GetPromos } from "Redux/App/Actions/Promos";
import { useDispatch, useSelector } from "react-redux";
import { loading as stateLoading, companyName, userProfileData } from "Redux/App";
import { promotionsData as stateData } from "Redux/App/dataSlice";
import PromotionModal from "./PromotionModal/PromotionModal";
import { GetSystemSettings } from "Redux/App/Actions/System";

function Promotions() {
  const dispatch = useDispatch();
  const data = useSelector(stateData);
  const loading = useSelector(stateLoading);
  const selectedCompany = useSelector(companyName);
  const [activeTab, setActiveTab] = useState("All promotions");
  const [tableData, setTableData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [archivedActive, setArchivedActive] = useState(false);
  const filterDateLength = useSelector((state) => state.app.data.filterData);
  const [countsStatusArray, setCountsArray] = useState({});
  const userData = useSelector(userProfileData);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  useEffect(() => {
    GetSystemSettings("");
  }, []);
  const getStatusCount = (data) => {
    const statusCounts = {
      Pending: 0,
      Expired: 0,
      Ready: 0,
      Archived: 0,
      Paused: 0,
      Active: 0,
      All: 0
    };
    if (data) {
      data.forEach((item) => {
        const status = item?.status ?? "";
        statusCounts[status] = (statusCounts[status] || 0) + 1;
      });

      statusCounts.All = data.length - statusCounts.Archived;

      if (archivedActive) {
        statusCounts.All = data.length;
      }
    }
    return statusCounts;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (selectedCompany) dispatch(GetPromos());
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    let dataWithKeys = [];
    const check = data.filter((item) => {
      if (item == undefined) {
        dispatch(GetPromos());
      }
    });

    setCountsArray(getStatusCount(data));

    dataWithKeys = data.map((item) => {
      return { ...item, key: item?.id };
    });

    if (activeTab === "All promotions") {
      if (archivedActive) {
        setTableData(dataWithKeys);
      } else {
        setTableData(dataWithKeys.filter((item) => item?.status !== "Archived"));
      }
    } else {
      setTableData(dataWithKeys?.filter((item) => item?.status === activeTab));
    }
  }, [activeTab, dispatch, data, archivedActive, setCountsArray]);

  const ArchivedHandler = (event) => {
    if (!event.target.checked) {
      setActiveTab("All promotions");
    }
    setArchivedActive(event.target.checked);
  };

  const ArchivedButtons = <Checkbox onChange={ArchivedHandler}>Archived</Checkbox>;

  return (
    <PromotionsWrapper>
      {selectedRowKeys.length > 0 ? (
        <PromotionModalWrapper>
          <PromotionModal
            refreshPromotions={() => dispatch(GetPromos())}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </PromotionModalWrapper>
      ) : (
        <Tabs defaultActiveKey="1" tabBarExtraContent={ArchivedButtons} onChange={onChangeTab} activeKey={activeTab}>
          <Tabs.TabPane
            //             tab={`All (${!isNaN(countsStatusArray.All) ? countsStatusArray.All : 0 })`}

            tab={`All (${countsStatusArray.All ?? 0})`}
            key="All promotions"></Tabs.TabPane>
          <Tabs.TabPane tab={`Active (${countsStatusArray.Active ?? 0})`} key="Active"></Tabs.TabPane>
          <Tabs.TabPane tab={`Ready (${countsStatusArray.Ready ?? 0})`} key="Ready"></Tabs.TabPane>
          <Tabs.TabPane tab={`Pending (${countsStatusArray.Pending ?? 0})`} key="Pending"></Tabs.TabPane>
          {/* <Tabs.TabPane
            tab={`Deactivated (${fetchPromotions("Deactivated")?? 0 })`}
            key="Deactivated"
          ></Tabs.TabPane> */}
          <Tabs.TabPane tab={`Upcoming (${countsStatusArray.Upcoming ?? 0})`} key="Upcoming"></Tabs.TabPane>

          <Tabs.TabPane tab={`Paused (${countsStatusArray.Paused ?? 0})`} key="Paused"></Tabs.TabPane>
          <Tabs.TabPane tab={`Expired (${countsStatusArray.Expired ?? 0})`} key="Expired"></Tabs.TabPane>
          {archivedActive && (
            <Tabs.TabPane tab={`Archived (${countsStatusArray.Archived ?? 0})`} key="Archived"></Tabs.TabPane>
          )}
        </Tabs>
      )}

      <PromotionsTable
        data={tableData}
        loading={loading}
        refreshPromotions={() => dispatch(GetPromos())}
        rowSelection={rowSelection}
        handleStatusCountChange={(data) => setCountsArray(getStatusCount(data))}
        userData={userData}
      />
    </PromotionsWrapper>
  );
}

export default Promotions;
