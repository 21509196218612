import styled from "styled-components";

export const MenuWrapper = styled.div`
  .ant-menu-title-content {
    margin-left: 15px;
    font-size: 16px;
    line-height: 19px;
  }

  .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-item-selected
    > .ant-menu-title-content
    > a {
    color: #39b54a;
  }
  .white {
    padding-top: 18px;
    color: white;
  }
  .gray {
    color: #9b9b9b;
    padding-top: 18px;
  }
  .base-color {
    color: #39b54a;
  }
  .ant-menu-submenu-arrow {
    padding-top: 3px;
    color: #9b9b9b;
  }
  .ant-menu-submenu-selected
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow {
    color: #fff;
    padding-top: 3px;
  }

  .ant-menu-inline .ant-menu-item:after {
    border: 0px solid #e5e5e5;
  }
`;
