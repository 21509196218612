import { Switch } from "antd";

const CreatePromo = ({
  record,
  item,
  setSelectedCompany,
  dispatch,
  EditCompany,
  setLoading,
  fetchCompanies,
  toggleFetchCompanies
}) => (
  <Switch
    key={record.id}
    checked={item}
    onChange={(coupon) => {
      setSelectedCompany(record);
      dispatch(
        EditCompany(
          {
            ...record,
            create_promo_flag: !record.create_promo_flag
          },
          setLoading,
          null,
          null,
          fetchCompanies,
          toggleFetchCompanies
        )
      );
    }}
  />
);

export default CreatePromo;
