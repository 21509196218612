import { Drawer } from "antd";
import React from "react";

const DrawerComponent = (props) => {
  return (
    <Drawer
      headerStyle={{ display: "none" }}
      title="Basic Drawer"
      placement={"right"}
      closable={false}
      onClose={() => props.setDrawerOpen(false)}
      open={props.drawerOpen}
    >
      {props.children}
    </Drawer>
  );
};

export default DrawerComponent;
