import axios from 'axios';
import { requestStart, requestCompleted } from "../..";
import { notification } from "antd";
import { setLogsData } from 'Redux/App/Reducers/Logs';

export function GetLogsData() {
  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/logs?offset=0&limit=100`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      
      if (response.data) {
        dispatch(setLogsData(response.data))
        dispatch(requestCompleted());
      }
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong",
      });
      dispatch(requestCompleted());
    }
  };
}

export function GetSortedLogData(column,order) {
  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/logs/sort/${column}?order=${order}`, {
          headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      
      if (response.data) {
        dispatch(setLogsData(response.data))
        // dispatch(setLogsData([{
        //   "id": 1,
        //   "created_at": "2023-07-30T19:27:28.387Z",
        //   "event": "UNIT",
        //   "level": "UNIT",
        //   "description": "UNIT",
        //   "details": "UNIT",
        //   "error": "UNIT",
        // },{
        //     "id": 0,
        //     "created_at": "2023-07-27T19:27:28.387Z",
        //     "event": "string",
        //     "level": "string",
        //     "description": "string string string string string  string string stringstringstringstringstringstring stringstring stringstring string string string stringstringstring string string string string stringstringstring string string stringstring stringstringstring string stringstring string string string string 99",
        //     "details": "string string string string string  string string stringstringstringstringstringstring stringstring stringstring string string string stringstringstring string string string string stringstringstring string string stringstring stringstringstring string stringstring string string string string 88",
        //     "error": "string string string string string  string string stringstringstringstringstringstring stringstring stringstring string string string stringstringstring string string string string stringstringstring string string stringstring stringstringstring string stringstring string string string string 77",
        //   }]));
        dispatch(requestCompleted());
      }
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong",
      });
      dispatch(requestCompleted());
    }
  };
}

export function SetLogsArchiveStatus(data, setLoading,setSelectedLogs) {
  return async (dispatch, state) => {
    let stateData = state();
    const logs = stateData.logs.data;
    dispatch(requestStart());
    setLoading(true)
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/logs/archive`,data, {
          headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      
      if (response.data) {
        const updatedArray = logs.map((originalRecord) => {
          // Check if there is a matching record in the new records
          const matchingRecord = response.data.find((newRecord) => newRecord.id === originalRecord.id);
        
          // If there is a matching record, use it; otherwise, keep the original record
          return matchingRecord ? matchingRecord : originalRecord;
        });        
        dispatch(setLogsData(updatedArray))
        dispatch(requestCompleted());
        setLoading(false);
        setSelectedLogs([]);
        notification["success"]({
          message: 'Successfully updated the record',
        });
  
      }
    } catch (error) {
      console.log(error)
      notification["error"]({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong",
      });
      dispatch(requestCompleted());
      setLoading(false)
    }
  };
}