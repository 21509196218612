import axios from "axios";
import { requestStart, requestCompleted, requestSuccess } from "../..";
import { notification } from "antd";
import { setSystemSettingsData } from "Redux/App/Reducers/System";

export function GetSystemSettings(email) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/settings`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.data) {
        const version = await GetBackendVersion();
        dispatch(
          setSystemSettingsData({
            backend_version: version,
            ...response.data
          })
        );
        dispatch(requestCompleted());
      }
    } catch (error) {
      if (!error.message.includes("401")) {
        notification.error({
          message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
        });
      }
    }
  };
}

export async function GetBackendVersion() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/openapi.json`);
    return response.data.info.version;
  } catch (error) {
    console.error("Error fetching OpenAPI data:", error);
  }
}

export function EditSystemSettings(values) {
  const paramsString = Object.keys(values)
    .map((key) => `${key}=${encodeURIComponent(values[key])}`)
    .join("&");

  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      await axios
        .put(`${process.env.REACT_APP_API_BASE_URL}/edit-settings?${paramsString}`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res.data) {
            dispatch(setSystemSettingsData(res.data));
            dispatch(requestCompleted());
            notification.success({
              message: "System settings edited successfully"
            });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {}
  };
}
