import { Form } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";
import getRequiredMsg from "utils/formRequiredMsg";
import DropDown from "../DropDown";

const VistarStatus = ({required}) => (
  <Form.Item
    label={SETUP_VALUES.VISTAR_STATUS}
    name={SETUP_VALUES.VISTAR_STATUS_}
    className="campaign-form-labels"
    rules={getRequiredMsg(SETUP_VALUES.ERR_VISTAR_STATUS, required)}
  >
    <DropDown
      valArray={SETUP_VALUES.VISTAR_STATUS_ARR}
    />
  </Form.Item>
)

export default VistarStatus;