import React from "react";
import { Input, Form} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DrawersWrapper } from "./styles";
import { CreateCampaign } from "Redux/App/Actions/Campaigns";
import { userProfileData } from "Redux/App";
import DrawerTitle from "./admin/DrawerTitle";
import AdminForm from "./admin/Form";

function CampaignDrawer(props) {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const userData = useSelector(userProfileData);

  const onFinish = (values) => {
    values.status = "pending";
    values.company_id = userData.company;
    dispatch(CreateCampaign(values, null, props));
  };
  const handleCancel = () => {
    props.setDrawerOpen(false);
  };

  return (
    <DrawersWrapper>
      <DrawerTitle handleCancel={handleCancel} />
      <AdminForm
        handleCancel={handleCancel}
        form={form}
        onFinish={onFinish}
        
      />
    </DrawersWrapper>
  );
}

export default CampaignDrawer;
