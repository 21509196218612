import { Form } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";
import getRequiredMsg from "utils/formRequiredMsg";
import DropDown from "../DropDown";

const ScreenType = ({ onChange }) => (
  <Form.Item
    name={SETUP_VALUES.SCREEN_TYPE_}
    label={SETUP_VALUES.SCREEN_TYPE}
    className="campaign-form-labels"
    rules={getRequiredMsg(SETUP_VALUES.ERR_SCREEN_TYPE)}
  >
    <DropDown
      valArray={SETUP_VALUES.SCREEN_TYPE_ARR}
      onChange={onChange}
    />
  </Form.Item>
)

export default ScreenType;