import styled from "styled-components";

export const LogoWrapper = styled.div`
  border-bottom: 2px solid #545454;
  padding-bottom: 15px;
  margin-top: 30px;
  margin-bottom: 150px;
  display: flex;

  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  svg {
    display: block;
    max-width: 100%;
  }
`;
export const BackGroungColor = styled.div`
  background: #39b54a;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
