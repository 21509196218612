import React from "react";
import { useSelector } from "react-redux";
import { companyName } from "Redux/App";

export const PromotionEditor = ({ templateId }) => {
  const companyId = useSelector(companyName);
  const token = localStorage.getItem("token");
  return (
    <>
      <embed
        src={`${process.env.REACT_APP_VIDEO_EDITOR_APP_URL}/?token=${token}&company=${companyId}&template=${templateId}`}
        width="100%"
        height="100%"
        id=""
        style={{
          padding: 0,
          margin: 0
        }}
        display="block"
        position="relative"
      />
    </>
  );
};
