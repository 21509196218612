import { Button, Modal, notification } from "antd";
import React, { useState } from "react";
import { ActionsDiv, ModalBtn } from "./styles";
import GrayTickIcon from "Assets/Icons/GrayTickIcon";
import MouseSquare from "Assets/Icons/MouseSquare";
import SmartHomeIcon from "Assets/Icons/SmartHomeIcon";
import SaveIcon from "Assets/Icons/SaveIcon";
import MessageIcon from "Assets/Icons/MessageIcon";
import { useDispatch, useSelector } from "react-redux";
import CopyIcon from "Assets/Icons/CopyIcon";
import { CopyPromotion, PublishPromotion, DeactivatePromotion, ArchievePromotion } from "Redux/App/Actions/Promos";

import {
  data,
  userProfileData,
  isModalStateTrue,
  isModalOpened,
  isModalClosed,
  setTheModalClose,
  isErrorMessage
} from "Redux/App";
import LoadingSpinner from "Components/Shared/LoadingSpinner";

const PromotionModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [confrimPopup, setConfirmPopup] = useState(false);
  const [archivePopup, setArchivePopup] = useState(false);
  const [publishPopup, setPublishPopup] = useState(false);
  const [inValidPromos, setInvalidPromos] = useState([]);
  const [inValidPromoNames, setInvalidPromoNames] = useState([]);
  const userData = useSelector(userProfileData);
  const dispatch = useDispatch();
  const promotions = useSelector(data)?.filterData ?? [];
  const nonActiveOrUpcomingPromotion = [];
  const inValidPomosNames = [];

  const openModal = useSelector(isModalOpened);
  const showErrorMessage = useSelector(isErrorMessage);
  const splitMessage = showErrorMessage?.split(":");
  const beforeColon = splitMessage.length > 0 ? splitMessage[0]?.trim() : "";

  const dottedString = beforeColon.split(".");
  const bb = dottedString[dottedString.length - 1];
  const sss = dottedString.slice(0, dottedString.length - 1);
  const joinedString = sss.join(". ");

  const afterColon = splitMessage.length > 0 ? splitMessage[1]?.trim().substring(1) : "";
  const splitBullets = afterColon?.split("*") || [];
  const bullet1 = splitBullets.length > 0 ? splitBullets[0]?.trim() : "";
  const bullet2 = splitBullets[1]?.trim();
  const bullet3 = splitBullets[2]?.trim();
  const bullet4 = splitBullets[3]?.trim();
  const bullet5 = splitBullets[4]?.trim();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDispatchClosed = () => {
    dispatch(setTheModalClose());
    props.setSelectedRowKeys([]);
  };

  const disablePromotion = () => {
    promotions.forEach((promotion) => {
      if (props?.selectedRowKeys.includes(promotion?.id)) {
        if (promotion.status !== "Active" && promotion.status !== "Upcoming" && promotion.status !== "Paused") {
          inValidPomosNames.push(promotion.promotion_name);
          nonActiveOrUpcomingPromotion.push(promotion?.id);
          // setInvalidPromos([...inValidPromos, promotion]);
        }
      }
    });

    setInvalidPromoNames(inValidPomosNames);
    setInvalidPromos(nonActiveOrUpcomingPromotion);

    setConfirmPopup(true);
  };
  const handleOkDeactivate = () => {
    const items = props?.selectedRowKeys.filter((item) => !inValidPromos.includes(item));
    if (items.length > 0 && items[0] !== null && !items.includes(null))
      dispatch(DeactivatePromotion(items, handleCancel, props.setSelectedRowKeys));
    setConfirmPopup(false);
  };

  const archievePromotion = () => {
    promotions.forEach((promotion) => {
      if (props?.selectedRowKeys.includes(promotion?.id)) {
        if (promotion.status === "Active" || promotion.status === "Upcoming") {
          inValidPomosNames.push(promotion.promotion_name);

          nonActiveOrUpcomingPromotion.push(promotion?.id);
          // setInvalidPromos([...inValidPromos, promotion]);
        }
      }
    });

    setInvalidPromoNames(inValidPomosNames);

    setInvalidPromos(nonActiveOrUpcomingPromotion);

    setArchivePopup(true);
  };
  const handleOkArchive = () => {
    const items = props?.selectedRowKeys.filter((item) => !inValidPromos.includes(item));
    if (items.length > 0 && !items.includes(null))
      dispatch(ArchievePromotion(items, setLoading, handleCancel, props.setSelectedRowKeys));
    setConfirmPopup(false);
    setArchivePopup(false);
  };

  const HandlePublishPromotion = () => {
    promotions.forEach((promotion) => {
      if (props?.selectedRowKeys.includes(promotion?.id)) {
        if (promotion.status !== "Ready") {
          inValidPomosNames.push(promotion.promotion_name);
          nonActiveOrUpcomingPromotion.push(promotion?.id);
          // setInvalidPromos([...inValidPromos, promotion]);
        }
      }
    });
    setInvalidPromoNames(inValidPomosNames);
    setInvalidPromos(nonActiveOrUpcomingPromotion);

    setPublishPopup(true);
  };
  const handleOkPublic = () => {
    const items = props?.selectedRowKeys.filter((item) => !inValidPromos.includes(item));

    if (items.length > 0 && items[0] !== null && !items.includes(null)) {
      dispatch(PublishPromotion(items, handleCancel, props.setSelectedRowKeys, props.refreshPromotions));
    }

    setPublishPopup(false);
  };
  return (
    <>
      <ModalBtn onClick={showModal}>
        <MouseSquare />
      </ModalBtn>
      {contextHolder}
      <Modal title="Confirm" open={publishPopup} onOk={handleOkPublic} onCancel={() => setPublishPopup(false)}>
        {inValidPromos.length > 0 ? (
          <p>
            The following promotions are not in a Ready status and can not be Published:
            <ul>
              {inValidPromoNames.map((promotion) => (
                <li>{promotion}</li>
              ))}
            </ul>
          </p>
        ) : (
          <p>
            Please confirm that the following Promotions should be published.
            <ul>
              {promotions
                .filter((promotion) => {
                  return props?.selectedRowKeys.includes(promotion?.id);
                })
                .map((promotion) => (
                  <li>{promotion.promotion_name}</li>
                ))}
            </ul>
          </p>
        )}
      </Modal>
      <Modal title="Confirm" open={archivePopup} onOk={handleOkArchive} onCancel={() => setArchivePopup(false)}>
        {inValidPromos.length > 0 ? (
          <p>
            The following promotions are in an Active or Upcoming status and can not be Archived until they are
            Deactivated:
            <ul>
              {inValidPromoNames.map((promotion) => (
                <li>{promotion}</li>
              ))}
            </ul>
          </p>
        ) : (
          <p>
            Please confirm that the following Promotions should be Archived.
            <ul>
              {promotions
                .filter((promotion) => {
                  return props?.selectedRowKeys.includes(promotion?.id);
                })
                .map((promotion) => (
                  <li>{promotion.promotion_name}</li>
                ))}
            </ul>
          </p>
        )}
      </Modal>
      <Modal title="Confirm" open={confrimPopup} onOk={handleOkDeactivate} onCancel={() => setConfirmPopup(false)}>
        {inValidPromos.length > 0 ? (
          <p>
            The following promotions are not in a Active , Upcoming or Paused status and can not be Deactivated:
            <ul>
              {inValidPromoNames.map((promotion) => (
                <li>{promotion}</li>
              ))}
            </ul>
          </p>
        ) : (
          <p>
            Please confirm that the following Active or Upcoming Promotions should be Deactivated.
            <ul>
              {promotions
                .filter((promotion) => {
                  return props?.selectedRowKeys.includes(promotion?.id);
                })
                .map((promotion) => (
                  <li>{promotion.promotion_name}</li>
                ))}
            </ul>
          </p>
        )}
      </Modal>
      <Modal
        title="View Promotion"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        width={450}>
        {/* userData?.permission_level !== "Fmtv" */}

        {userData?.permission_level !== "Creator" && (
          <>
            <ActionsDiv onClick={HandlePublishPromotion}>
              <div className="action">
                <SmartHomeIcon />
                <span className="actions-title"> Publish</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
            <ActionsDiv
              onClick={() => {
                disablePromotion();
              }}>
              <div className="action">
                <MessageIcon />
                <span className="actions-title">Deactivate</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
          </>
        )}
        {userData?.permission_level !== "Publisher" && (
          <>
            <ActionsDiv
              onClick={() =>
                dispatch(CopyPromotion(props?.selectedRowKeys, setLoading, handleCancel, props.setSelectedRowKeys))
              }>
              <div className="action">
                <CopyIcon />
                <span className="actions-title"> Copy</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>

            <ActionsDiv onClick={archievePromotion}>
              <div className="action">
                <SaveIcon />
                <span className="actions-title">Archive</span>
              </div>
              <GrayTickIcon />
            </ActionsDiv>
          </>
        )}
        {loading && <LoadingSpinner />}
      </Modal>

      {/* <Modal
        open={openModal}
        closable={false}
        footer={[
          <Button
            style={{
              background: '#39B54A',
              color: 'white',
              borderTop: '1px solid white',
            }}
            key="ok"
            onClick={handleDispatchClosed}
          >
            OK101
          </Button>,
        ]}
      >
        <p className="mb-0">
          {joinedString}
          <br />
          {bb ? bb : null}
          <ul>

            {bullet1 ? <li>{bullet1} </li> : null}
            {bullet2 ? <li>{bullet2} </li> : null}
            {bullet3 ? <li>{bullet3} </li> : null}
            {bullet4 ? <li>{bullet4} </li> : null}
            {bullet5 ? <li>{bullet5} </li> : null}
          </ul>
        </p>
      </Modal> */}
    </>
  );
};

export default PromotionModal;
