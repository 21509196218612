import { Input, Form } from "antd"

const Name = ({ isEdit }) => (
  isEdit && (
    <Form.Item name="name" label="Name">
      <Input />
    </Form.Item>
  )
)

export default Name;