export default function CollapsedLogo(props) {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 77 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.956 11.6714C26.1303 13.0192 19.6454 17.2871 15.0747 23.4382C8.11056 32.8111 7.07648 45.0519 12.2714 56.6054C12.6896 57.5354 13.2243 58.2956 13.46 58.2956C13.6951 58.2956 14.4929 56.6294 15.2322 54.5926C16.4745 51.171 16.5193 50.6092 15.8182 47.2061C13.0894 33.9629 21.4092 21.0406 34.4812 18.2158C37.149 17.6399 38.824 17.6436 42.1685 18.2343C47.6671 19.2054 51.5272 21.4032 55.5012 25.8242C66.2233 37.7533 62.5011 57.0523 48.1355 64.0091C42.5927 66.6931 35.6662 66.9648 29.9874 64.7196C29.3287 64.4596 28.7982 65.1191 28.0565 67.1203C27.4938 68.6372 27.1895 70.1387 27.3804 70.4564C28.0756 71.615 33.5503 72.7391 38.4982 72.7391C51.486 72.7391 62.7989 64.1529 66.6708 51.3566C69.1621 43.1226 68.1023 33.4331 63.9033 26.0528C61.6317 22.0596 56.0949 16.8188 51.9031 14.6928C45.5631 11.4784 39.1307 10.4526 32.956 11.6714ZM22.3741 47.6443L16.8421 63.4209L19.4652 65.7571C20.9086 67.0416 22.2589 68.1012 22.4672 68.1111C22.8533 68.1301 35.5532 33.1446 35.5532 32.0709C31.973 32.0709 33.7631 32.0709 31.6747 32.0709H27.7961L22.3741 47.6443ZM40.4798 32.9427C40.3268 33.6074 39.7489 34.9506 39.3049 36.0907C38.8616 37.2309 38.4982 38.4146 38.4982 38.7219C38.4982 39.036 41.6965 39.205 45.8006 39.1079C53.0386 38.9364 53.1102 38.9211 53.8585 37.3101C54.2738 36.4165 54.7804 34.8259 54.9844 33.7761L55.5427 32.0709H48.1225C43.6135 32.0709 41.8186 32.0709 40.9235 32.0709C40.6252 32.6855 40.6252 32.6855 40.4798 32.9427ZM34.9609 48.1778C34.2807 50.0493 33.7246 51.7088 33.7246 51.8655C33.7246 52.0216 36.822 52.1495 40.6081 52.1495H47.4911L48.6869 49.2761C50.4901 44.9437 50.2657 44.7741 42.7365 44.7741H36.1973L34.9609 48.1778Z"
        fill="#3AB54A"
      />
    </svg>
  );
}
