import axios from 'axios';
import { requestStart, requestSuccess } from '../..';
import { notification } from 'antd';
import Papa from 'papaparse';

export function GetImageTemplates() {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/image-template-all?offset=0&limit=100`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            dispatch(requestSuccess(res.data));
          }
        })
        .catch((error) => {
          notification['error']({
            message: error?.response?.data?.detail
              ? error?.response?.data?.detail
              : 'Something went wrong',
          });
        });
    } catch (error) { }
  };
}

export function CreateImageTemplates(data, props, setCreateTemplateLoading,setFile) {
  return async (dispatch, state) => {
    setCreateTemplateLoading(true);
    try {
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + `/image-template`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let stateData = state();
            let newStateData = [...res.data, ...stateData.app.data];
            dispatch(requestSuccess(newStateData));
            if (props.setDrawerOpen)
              props.setDrawerOpen({
                ...props.drawerOpen,
                open: false,
              });
            notification.success({
              message: ' Image upload Successfully',
            });
          }
          setCreateTemplateLoading(false);
          setFile([]);
        })
        .catch((error) => {
          notification['error']({
            message: error?.response?.data?.detail
              ? error?.response?.data?.detail
              : 'Something went wrong',
          });
          notification.error({
            message: 'Image not upload',
          });
          setCreateTemplateLoading(false);
        });
    } catch (error) { }
  };
}

export function EditImageTemplates(data, props, setCreateTemplateLoading) {
  return async (dispatch, state) => {
    setCreateTemplateLoading(true);
    try {
      await axios
        .put(
          process.env.REACT_APP_API_BASE_URL + `/image-template/edit`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            let stateData = state();
            let records = stateData.app.data;

            let filteredRecords = records.filter(
              (record) => record.id !== data.id
            );

            filteredRecords.unshift(res.data)

            dispatch(requestSuccess(filteredRecords));
            if (setCreateTemplateLoading) setCreateTemplateLoading(false);
            notification.success({
              message: 'Image edited successfully',
            });
            if (props.setDrawerOpen)
              props.setDrawerOpen({
                ...props.drawerOpen,
                open: false,
              });
          }
        })
        .catch((error) => {
          notification['error']({
            message: error?.response?.data?.detail
              ? error?.response?.data?.detail
              : 'Something went wrong',
          });
        });
    } catch (error) { }
  };
}

export function GetImageTemplatesMedia(promo_id, promoVideo, setPromoVideo) {
  return async (dispatch) => {
    setPromoVideo({ ...promoVideo, loading: true, modalVisible: true });

    try {
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + `/image-template/media`, {
          params: {
            image_template_id: promo_id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          if (res.data) {
            if (res) {
              setPromoVideo({
                ...promoVideo,
                loading: false,
                data: res?.data.image,
              });
            }
          }
        })
        .catch((error) => {
          notification['error']({
            message: error?.response?.data?.detail
              ? error?.response?.data?.detail
              : 'Something went wrong',
          });
        });
    } catch (error) { }
  };
}

export const saveMultipleImgs = (data, props, setLoading, company) => async (dispatch) => {
  try {
    setLoading(true)
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/pull-images?company=${company}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if (response.data) {
      GetImageTemplates()
      props.setDrawerOpen(false)
      // Split the string into rows
      const rows = response.data.split('\n');

      // Create an array to hold the parsed CSV data
      const parsedData = [];

      // Parse each row using PapaParse with custom options
      rows.forEach((row) => {
        const result = Papa.parse(row, {
          delimiter: ',',
          dynamicTyping: false, // Disable automatic type detection
          header: false, // Assuming there are no headers in your data
        });

        if (result.data.length > 0) {
          parsedData.push(result.data[0]); // Assuming each row has one set of data
        }
      });

      // Convert the parsed data back to CSV format
      const csvContent = Papa.unparse(parsedData);

      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

      // Create a download link
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'FTMV_Image_Bulk_Import_Log.csv';
      // Specify the desired file name

      // Trigger a click event on the link to initiate the download
      a.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(a.href);

      if (props.setDrawerOpen) {
        props.setDrawerOpen({
          ...props.drawerOpen,
          open: false,
        });
      }

      notification.success({
        message: 'Successfully uploaded the document',
      });
    }
  } catch (error) {
    console.log(error);
    notification['error']({
      message: error?.response?.data?.detail || 'Something went wrong',
    });
  } finally {
    setLoading(false);
  };
};

