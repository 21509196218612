import React, { useState, useEffect, useRef } from "react";
import { Table, Select, Modal, Checkbox, Row, Col, Input, Button, notification } from "antd";
import { FilterFilled } from "@ant-design/icons";
import { TableWrapper } from "Styles/global";
import { EditStatus, GetWorkflowData, GetSortedWorkflowData } from "Redux/App/Actions/Workflow";
import { useDispatch, useSelector } from "react-redux";
import { loading as stateLoading } from "Redux/App";
import { workflowData as stateData } from "Redux/App/dataSlice";
import { useNavigate } from "react-router-dom";
import { GetPromoVideo } from "Redux/App/Actions/Promos";
import { EditPromotion } from "Pages/Promotions/EditPromotion/EditPromotion";
import axios from "axios";
import { setCompany } from "Redux/App/";
import DrawerComponent from "Components/Shared/Drawer";
import { DownloadOutlined } from "@ant-design/icons";
import FileNameModal from "Components/Shared/FileNameModal/FileNameModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { ExportButton } from "./styles";
import { ModalBtn } from "Pages/Promotions/PromotionModal/styles";
import MouseSquare from "Assets/Icons/MouseSquare";
import { ActionsModal } from "Pages/Setting/utils/ActionsModal";

// File Type
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const Workflow = () => {
  const buttonRef = {
    activity: useRef(null),
    item: useRef(null),
    status: useRef(null),
    submitted_by: useRef(null),
    submitting_company: useRef(null)
  };
  const searchInput = {
    activity: useRef(null),
    item: useRef(null),
    status: useRef(null),
    submitted_by: useRef(null),
    submitting_company: useRef(null)
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const data = useSelector(stateData);
  const loading = useSelector(stateLoading);
  const [archived, setArchived] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState("");
  const [isSortClicked, setIsSortClicked] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState({
    CampaignDetailDrawer: false,
    EditPromotionDrawer: false,
    EditCouponDrawer: false,
    editPromotionData: null,
    editCampaignName: ""
  });
  const [media, setMedia] = useState({
    data: "",
    loading: false,
    mediaType: "",
    fileName: ""
  });
  const [openFileModal, setOpenFileModal] = useState(false);
  const [exportedData, setExportedData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fileName, setFileName] = useState(`Workflows_${moment().format("YYYYMMDD_HHmmss")}`);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const statusOptions = [
    {
      value: "open",
      label: "Open"
    },
    {
      value: "pending",
      label: "Pending"
    },
    {
      value: "done",
      label: "Done"
    },
    {
      value: "archived",
      label: "Archived"
    }
  ];

  useEffect(() => {
    const mutateData = data.map((it) => {
      return { ...it, key: it.id };
    });
    setFormattedData(archived ? mutateData : mutateData.filter((data) => data.status !== "archived"));
  }, [archived, data]);

  // Exporting
  const exportToCSV = (csvData, fileName) => {
    const myHeader = ["Activity", "Status", "Date Submitted", "Submitted By", "Company"];
    // Extension
    const fileExtension = ".xlsx";

    const finalData = csvData.map((item) => {
      return {
        Activity: item?.activity ?? "",
        "Campaign Id": item?.campaign_id ?? "",
        item: item?.item ?? "",
        Status: item?.status ?? "",
        "Promotion Id": item?.promotion_id ?? "",
        "Date Submitted": new Date(item.date_submitted).toLocaleString(),
        "Submitted By": item?.submitted_by ?? "",
        Company: item?.submitting_company ?? ""
      };
    });

    const ws = XLSX.utils.json_to_sheet(finalData, { header: myHeader });

    const wb = { Sheets: { Workflows: ws }, SheetNames: ["Workflows"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const e_data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(e_data, fileName + fileExtension);
    setFileName(`Workflows_${moment().format("YYYYMMDD_HHmmss")}`);
    setOpenFileModal(false);
  };

  const clickExport = () => {
    setOpenFileModal(true);
  };

  const handleExport = () => {
    const finalData = exportedData ? exportedData : formattedData;
    exportToCSV(finalData, fileName ?? "logs");
  };

  const downloadFile = (res, state) => {
    setMedia(state);
    // downloadVideoFromDataUri(state.data, res.data.media_type);
    downloadFileFromURL(res.file_url, res.promotion_name);
  };

  const fetchPromotionData = async (promotion_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/promo/${promotion_id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  };

  const downloadFileFromURL = async (url, name) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      notification.success({ message: "Successfully downloaded the file" });
    } catch (error) {
      notification.error({ message: "Something went wrong" });
      console.error("There has been a problem with your fetch operation:", error);
    }
  };

  function downloadVideoFromDataUri(dataUri, filename) {
    // Decode the base64 data URI to binary data
    const binaryData = atob(dataUri);

    // Create a Uint8Array from the binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([uint8Array], { type: filename }); // Adjust the MIME type as needed

    // Generate a Blob URL
    const blobUrl = URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = filename;

    // Trigger a click event to initiate the download
    a.click();

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(blobUrl);
  }
  const navigate = useNavigate();

  const handleButtonClickOutside = async () => {
    for (let i = 0; i < 5; i++) {
      for (let prop in buttonRef) {
        if (buttonRef[prop]?.current) {
          buttonRef[prop]?.current?.click();
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    if (isSortClicked) {
      dispatch(GetWorkflowData());
      setIsSortClicked(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
        className="search-props">
        <Row gutter={8}>
          <Col span="14">
            <Input
              ref={searchInput[dataIndex]}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                display: "block"
              }}
            />
          </Col>
          <Col span="5">
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
              Search
            </Button>
          </Col>
          <Col span="5">
            <Button
              ref={buttonRef[dataIndex]}
              onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
              type="primary"
              style={{
                marginLeft: "5px",
                background: "#FFFFFF",
                color: " #9B9B9B",
                border: "1px solid #d9d9d9"
              }}>
              Clear
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (typeof record[dataIndex] === "object") {
        return Object.values(record[dataIndex]).some((val) => {
          return val?.toString()?.toLowerCase()?.includes(value.toLowerCase());
        });
      } else {
        return record[dataIndex]?.toString()?.toLowerCase()?.includes(value.toLowerCase());
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput[dataIndex].current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (text ? text.toString() : "") : text)
  });

  const handleSortChange = (pagination, filters, sorter, extra) => {
    const column = sorter.field;

    const order = sorter.order === "descend" ? "desc" : "asc";
    setExportedData(extra.currentDataSource);
    if (column) {
      dispatch(GetSortedWorkflowData(column, order));
      setIsSortClicked(true);
    }
  };
  // const downloadFile = (res, state) => {
  //   setMedia(state)
  //   console.log(res, state.fileName, state.fileName.split('.')[0])
  //   downloadVideoFromDataUri(state.data, state.fileName);
  // }
  function downloadVideoFromDataUri(dataUri, filename) {
    // Decode the base64 data URI to binary data
    const binaryData = atob(dataUri);
    // Create a Uint8Array from the binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    // Create a Blob from the binary data
    const blob = new Blob([uint8Array], { type: filename }); // Adjust the MIME type as needed
    // Generate a Blob URL
    const blobUrl = URL.createObjectURL(blob);
    // Create a download link
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = filename;
    // Trigger a click event to initiate the download
    a.click();
    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(blobUrl);
  }
  useEffect(() => {
    if (media.data) {
      if (media.mediaType === "video") {
        var a = document.createElement("a"); //Create <a>
        a.href = `data:video/mp4;base64,` + media.data; //Image Base64 Goes here
        a.download = media.fileName; //File name Here
        a.click(); //Downloaded file
      } else if (media.mediaType === "image") {
        let a = document.createElement("a"); //Create <a>
        a.href = `data:image;base64,` + media.data; //Image Base64 Goes here
        a.download = media.fileName; //File name Here

        a.click(); //Downloaded file

        setMedia({
          data: "",
          loading: false,
          mediaType: "",
          fileName: ""
        });
      }
    }
  }, [media.data]);

  useEffect(() => {
    if (drawerOpen.editPromotionData) {
      setDrawerOpen({
        ...drawerOpen,
        EditPromotionDrawer: true
      });
    }
  }, [drawerOpen.editPromotionData]);

  useEffect(() => {
    dispatch(GetWorkflowData());
  }, [dispatch]);

  const handleBulkDoneAction = async (sendEmail) => {
    const data = selectedRowKeys.map((it) => {
      return {
        id: it,
        status: "done"
      };
    });
    await dispatch(EditStatus(data, sendEmail));
    setIsModalOpen(false);
    setShowStatusModal(false);
  };

  const handleOk = async () => {
    if (selectedRowKeys.length > 0) {
      return await handleBulkDoneAction(true);
    }
    dispatch(EditStatus([selectedWorkFlow]));
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSkip = async () => {
    if (selectedRowKeys.length > 0) {
      return await handleBulkDoneAction(false);
    }
    dispatch(EditStatus([selectedWorkFlow], false));
    setIsModalOpen(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  const handleWorkflowAction = async (status) => {
    if (status === "done") return setIsModalOpen(true);
    const data = selectedRowKeys.map((it) => {
      return {
        id: it,
        status: status
      };
    });
    await dispatch(EditStatus(data));
    setShowStatusModal(false);
  };

  const columns = [
    {
      title: "Activity",
      dataIndex: "activity",
      ...getColumnSearchProps("activity")
    },
    {
      title: "Item Requiring Attention",
      dataIndex: "item",
      ...getColumnSearchProps("item"),
      render: (item, record) => (
        <div
          style={{
            color: "#39B54A",
            cursor: "pointer",
            display: "flex",
            gap: 10
          }}>
          <p
            onClick={async () => {
              try {
                if (record.promotion_id || record.campaign_id) {
                  if (record.promotion_id) {
                    const promotionData = await fetchPromotionData(record.promotion_id);
                    if (
                      record.activity === "Publish Promotion" ||
                      record.activity === "Setup Campaign" ||
                      record.activity === "Deactivate Promotion" ||
                      record.activity === "Archive Promotion"
                    ) {
                      dispatch(setCompany(promotionData.company_id));
                      setDrawerOpen((prevState) => ({
                        ...prevState,
                        editPromotionData: promotionData
                      }));
                    }
                  } else if (record.activity.includes("Campaign")) {
                    console.log(record.campaign_id);
                    navigate("/campaign-edit", {
                      state: { campaign: record.campaign_id }
                    });
                  }
                }
              } catch (error) {
                notification.error("Something went wrong");
              }
            }}>
            {" "}
            {item}
          </p>
          <div
            onClick={() => {
              // setMedia(state)
              // downloadVideoFromDataUri(state.data, res.data.media_type);
              dispatch(GetPromoVideo(record.promotion_id, media, setMedia, downloadFile, record.item));
            }}>
            {record.promotion_id ? <DownloadOutlined style={{ fontSize: 20 }} /> : null}
          </div>
        </div>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      ...getColumnSearchProps("status"),
      render: (item, i) =>
        item && (
          <div>
            <Select
              value={item}
              size="small"
              onChange={(value) => {
                let values = { id: i.id, status: value };

                if (value === "done") {
                  setSelectedWorkFlow(values);
                  setIsModalOpen(true);
                } else {
                  dispatch(EditStatus([values]));
                }
              }}
              style={{
                width: 90,
                fontSize: "11px"
              }}
              bordered={false}
              options={statusOptions}
            />
          </div>
        )
    },
    {
      title: "Date submitted",
      dataIndex: "date_submitted",
      sorter: (a, b) => {
        const dateA = new Date(a.date_submitted).getTime();
        const dateB = new Date(b.date_submitted).getTime();
        return dateA - dateB;
      },
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text) => (
        // Display the formatted date
        <span>{new Date(text).toLocaleString()}</span>
      )
    },
    {
      title: "Submitted by",
      dataIndex: "submitted_by",
      ...getColumnSearchProps("submitted_by")
    },
    {
      title: "Company",
      dataIndex: "submitting_company",
      ...getColumnSearchProps("submitting_company")
    }
  ];

  return (
    <TableWrapper>
      <FileNameModal
        fileName={fileName}
        setFileName={setFileName}
        open={openFileModal}
        handleOk={handleExport}
        handleCancel={() => setOpenFileModal(false)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10
        }}>
        <div>
          <Button onClick={handleButtonClickOutside} type="primary">
            Reset Filters
          </Button>
        </div>
        <div>
          {selectedRowKeys.length <= 0 && (
            <Checkbox
              onChange={() => {
                setArchived(!archived);
              }}>
              Archived
            </Checkbox>
          )}
          {selectedRowKeys.length > 0 && (
            <ModalBtn onClick={() => setShowStatusModal(true)}>
              <MouseSquare />
            </ModalBtn>
          )}
          <div>
            <ExportButton onClick={clickExport}>Export Table</ExportButton>
          </div>
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        loading={loading}
        dataSource={formattedData}
        onChange={handleSortChange}
        columns={columns}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          defaultPageSize: 10
        }}
      />
      <ActionsModal
        isModalOpen={showStatusModal}
        setModalOpen={setShowStatusModal}
        handleAction={handleWorkflowAction}
        statusOptions={statusOptions}
      />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="skip" onClick={handleSkip}>
            Skip
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Yes
          </Button>
        ]}>
        Should an email be sent to the user requesting the campaign/promotion change?
      </Modal>

      {drawerOpen.EditPromotionDrawer && drawerOpen.editPromotionData && (
        <DrawerComponent placement="right" setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen.EditPromotionDrawer}>
          <EditPromotion editpromotion={true} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} screen="workflow" />
        </DrawerComponent>
      )}
    </TableWrapper>
  );
};

export default Workflow;
