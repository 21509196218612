import { SETUP_VALUES } from "constants/Campaign";
import { Form, Input } from 'antd';
import getRequiredMsg from "utils/formRequiredMsg";

const CampName = ({isScreenEdit}) => (
  <Form.Item
    name={isScreenEdit ? SETUP_VALUES.UPDATE_NAME_ : SETUP_VALUES.CAMP_NAME_}
    label={SETUP_VALUES.CAMP_NAME}
    className="campaign-form-labels"
    rules={getRequiredMsg(SETUP_VALUES.ERR_ENTER_CAMP_NAME)}
  >
    <Input />
  </Form.Item>
)

export default CampName;