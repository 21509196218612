import { createSlice } from "@reduxjs/toolkit";

const systemSettingsInitialState = {
  data: null
};

// logs slice
const systemSettingsSlice = createSlice({
  name: "systemSettings",
  initialState: systemSettingsInitialState,
  reducers: {
    setSystemSettingsData(state, { payload }) {
      state.data = payload;
    }
  }
});

export const { setSystemSettingsData } = systemSettingsSlice.actions;

export const systemSettingsData = (state) => state.systemSettings.data;

export const systemSettingsReducer = systemSettingsSlice.reducer;
