import Login from "Pages/Auth/Login";
import PageNotFound from "Pages/NotFound/index";
import Promotions from "Pages/Promotions";
import Campaigns from "Pages/Campaigns";
import CampaignSetup from "Pages/Campaigns/CampaignSetup";
import CreatePromotion from "Pages/Promotions/CreatePromotion";
import Users from "Pages/Users/Users";
import React from "react";
import Layout from "Components/Layout/Main/index";
import Theme from "Styles/theme";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetUserProfile } from "Redux/App/Actions/Auth";
import { GetSystemSettings } from "Redux/App/Actions/System";
import RequestPassword from "Pages/Auth/RequestPassword";
import ResetPassword from "Pages/Auth/ResetPassword";
import PromotionPlayback from "Pages/Promotions/PromotionPlayback";
import Companies from "Pages/Companies";
import VideoLibarary from "Pages/VideoTemplates";
import ImageLibrary from "Pages/ImageTemplates";
import Template from "Pages/Setting/Template";
import Workflow from "Pages/Setting/Workflow";
import Logs from "Pages/Setting/Logs";
import System from "Pages/Setting/System";
import { systemSettingsData } from "Redux/App/Reducers/System";
import { SELECTED_COMPANY } from "constants/constants";
import { getMe } from "Requests/me";
import { notification } from "antd";
import { TOKEN_EXPIRED } from "constants/Auth";
let logoutTime = null;

function App() {
  const dispatch = useDispatch();
  logoutTime = parseInt(useSelector(systemSettingsData)?.forced_logout_period, 10);

  const logoutIfExpired = async () => {
    try {
      await getMe();
    } catch (error) {
      if (error?.response?.data?.detail === TOKEN_EXPIRED) {
        notification.error({
          message: TOKEN_EXPIRED
        });
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem(SELECTED_COMPANY);
          window.location.reload();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    const getProfile = async () => {
      if (localStorage.getItem("token")) {
        await logoutIfExpired();
        dispatch(GetUserProfile());
        dispatch(GetSystemSettings());
      }
    };
    getProfile();
  }, []);

  const layoutWrapper = (component, padding = true) => (
    <PrivateRoute>
      <Layout padding={padding}>{component}</Layout>
    </PrivateRoute>
  );
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginRedirect>
                <Login />
              </LoginRedirect>
            }
          />

          <Route path="/request-password" element={<RequestPassword />} />

          <Route path="/reset-password/:id" element={<ResetPassword />} />

          <Route path="/" element={layoutWrapper(/*<Home />*/ <Promotions title="View Promotions" />)} />
          <Route path="/promotions" element={layoutWrapper(<Promotions title="View Promotions" />)} />

          <Route path="/video-library" element={layoutWrapper(<VideoLibarary title="Manage video library" />)} />

          <Route path="/image-library" element={layoutWrapper(<ImageLibrary title="Manage image library" />)} />

          <Route
            path="/create-promotion"
            element={layoutWrapper(<CreatePromotion title="Create Promotions" />, false)}
          />

          <Route path="/promotion-playback/:id" element={layoutWrapper(<PromotionPlayback title="" />)} />
          <Route path="/campaigns" element={layoutWrapper(<Campaigns title="View Campaigns" />)} />
          <Route
            path="/campaign-setup"
            element={layoutWrapper(<CampaignSetup title="Campaign Setup" screen="setup" />)}
          />
          <Route
            path="/campaign-edit"
            element={layoutWrapper(<CampaignSetup title="Campaign Setup" screen="edit" />)}
          />
          <Route path="/users" element={layoutWrapper(<Users title="Manage Users" />)} />

          <Route path="/template" element={layoutWrapper(<Template title="Templates" />)} />
          <Route path="/workflow" element={layoutWrapper(<Workflow title="FMTV Workflow" />)} />
          <Route path="/system-settings" element={layoutWrapper(<System title="System Settings" />)} />
          <Route path="/logs" element={layoutWrapper(<Logs title="Settings" />)} />
          <Route path="/companies" element={layoutWrapper(<Companies title="Companies" />)} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const LoginRedirect = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? <Navigate to="/" /> : children;
};

var inactivityTime = function () {
  var time;
  window.onload = resetTimer;
  // DOM Events
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem(SELECTED_COMPANY);
    window.location.reload();
  }

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, logoutTime ? logoutTime * 60 * 1000 : 1800000);
  }
};

window.onload = function () {
  inactivityTime();
};
export default App;
