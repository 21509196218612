import { Image } from 'antd';

const ImageThumbnail = ({url, handleClick }) => (
  <div className="pointer-cursor">
    <Image
      preview={false}
      size={60}
      shape="square"
      src={url}
      onClick={handleClick}
    />
  </div>
)

export default ImageThumbnail