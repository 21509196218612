export default function UploadIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="#39B54A" fill-opacity="0.15" />
      <path
        opacity="0.4"
        d="M33.6534 16.8666H22.3467C19.0534 16.8666 16.3867 19.5466 16.3867 22.8266V35.3733C16.3867 38.6533 19.0667 41.3333 22.3467 41.3333H33.6401C36.9334 41.3333 39.6001 38.6533 39.6001 35.3733V22.8266C39.6134 19.5333 36.9334 16.8666 33.6534 16.8666Z"
        fill="#39B54A"
      />
      <path
        d="M31.1333 14.6667H24.8667C23.48 14.6667 22.3467 15.7867 22.3467 17.1734V18.4267C22.3467 19.8134 23.4667 20.9334 24.8533 20.9334H31.1333C32.52 20.9334 33.64 19.8134 33.64 18.4267V17.1734C33.6533 15.7867 32.52 14.6667 31.1333 14.6667Z"
        fill="#39B54A"
      />
      <path
        d="M32.7067 33.96L26.4133 27.6667H28C28.5467 27.6667 29 27.2134 29 26.6667C29 26.12 28.5467 25.6667 28 25.6667H24C23.4533 25.6667 23 26.12 23 26.6667V30.6667C23 31.2134 23.4533 31.6667 24 31.6667C24.5467 31.6667 25 31.2134 25 30.6667V29.08L31.2933 35.3734C31.4933 35.5734 31.7467 35.6667 32 35.6667C32.2533 35.6667 32.5067 35.5734 32.7067 35.3734C33.0933 34.9867 33.0933 34.3467 32.7067 33.96Z"
        fill="#39B54A"
      />
    </svg>
  );
}
