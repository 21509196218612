import { doGet, doPut } from "Requests/requests";

export const getNotificationById = async (notificationId) => {
  try {
    return await doGet(`/notifications/${notificationId}`);
  } catch (e) {
    throw e;
  }
};

export const getAllNotificationsForUser = async () => {
  try {
    return await doGet(`/notifications/user`);
  } catch (e) {
    throw e;
  }
};

export const updateNotificationsSeenStatus = async (notificationIdList) => {
  try {
    return await doPut(`/notifications`, notificationIdList);
  } catch (e) {
    throw e;
  }
};
