import { Upload, Form } from "antd"
import UploadIcon from 'Assets/Icons/UploadIcon';

const {Dragger} = Upload;

const UploadSection = ({
  uploadProps,
  isEditImgNotTrue
}) => (
  isEditImgNotTrue && (
    <Form.Item>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <UploadIcon />
        </p>

        <p className="ant-upload-text">
          Click to upload the image file(s)
        </p>
      </Dragger>
    </Form.Item>
  )
)

export default UploadSection;