import NewPromotionIcon from "Assets/Icons/NewPromotionIcon"

const NewPromoIcon = ({
  setDrawerOpen,
  drawerOpen,
  createNewCompany,
  permissionLevel
}) => (
  <div
    className="new-promotion"
    onClick={() => {
      setDrawerOpen({
        ...drawerOpen,
        open: true, 
        screen: "Add New"
      })
      createNewCompany(true)
    }
    }
  >
    {(!drawerOpen.open && (permissionLevel === 'Fmtv' || permissionLevel === 'FMTV')) && <NewPromotionIcon />}
  </div>
)

export default NewPromoIcon;