export default function ProfileLogo() {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="#39B54A" fill-opacity="0.1" />
      <path
        d="M28 13C24.07 13 20.875 16.195 20.875 20.125C20.875 23.98 23.89 27.1 27.82 27.235C27.94 27.22 28.06 27.22 28.15 27.235C28.18 27.235 28.195 27.235 28.225 27.235C28.24 27.235 28.24 27.235 28.255 27.235C32.095 27.1 35.11 23.98 35.125 20.125C35.125 16.195 31.93 13 28 13Z"
        fill="#39B54A"
      />
      <path
        d="M35.6199 31.225C31.4349 28.435 24.6099 28.435 20.3949 31.225C18.4899 32.5 17.4399 34.225 17.4399 36.07C17.4399 37.915 18.4899 39.625 20.3799 40.885C22.4799 42.295 25.2399 43 27.9999 43C30.7599 43 33.5199 42.295 35.6199 40.885C37.5099 39.61 38.5599 37.9 38.5599 36.04C38.5449 34.195 37.5099 32.485 35.6199 31.225Z"
        fill="#39B54A"
      />
    </svg>
  );
}
