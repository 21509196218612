import SubmitSection from "./Submit"
import { Input, Form, Select } from "antd";
import CAMP_VALS from "constants/Campaign";
import { DrawerFormHeading } from "../styles";
import { DrawerBodyPadding } from "Styles/global";
import { getRequired } from "utils";

const AdminForm = ({onFinish, form, handleCancel}) => {
  return (
    <Form onFinish={onFinish} form={form}>
      <DrawerBodyPadding>
        <DrawerFormHeading>{CAMP_VALS.CAMP_NAME}</DrawerFormHeading>
        <Form.Item name={CAMP_VALS.CAMP_NAME_}>
          <Input />
        </Form.Item>

        <DrawerFormHeading>{CAMP_VALS.SCREEN_TYPE}</DrawerFormHeading>
        <Form.Item
          name={CAMP_VALS.SCREEN_TYPE_}
          className="campaign-form-labels"
          rules={getRequired(CAMP_VALS.ERR_MSG_SCREEN_TYPE)}
        >
          <Select>
            {CAMP_VALS.CAMP_SELECT_VALS.map((value) => {
              return <Select.Option value={value}>{value}</Select.Option>
            })}
          </Select>
        </Form.Item>
        <DrawerFormHeading>{CAMP_VALS.CURRENT_DESC}</DrawerFormHeading>
        <Form.Item name={CAMP_VALS.CURRENT_DESC_}>
          <Input.TextArea rows={8} />
        </Form.Item>

        <SubmitSection handleCancel={handleCancel} />
      </DrawerBodyPadding>
    </Form>
  )
}

export default AdminForm;