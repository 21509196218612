const TABLE_IMAGE = {
  DRAWER_INITIAL_VALUE: {
    screen: "",
    open: false,
  },
  PROMO_INITIAL_STATE: {
    data: "",
    loading: false,
  },
  TABLE_TITLES: {
    ID_COLUMN:{
      title: "Id",
      dataIndex: "id",
    },
    NAME_COLUMN: {
      title: "Name",
      dataIndex: "name"
    },
    STATUS_COLUMN:{
      title: "Status",
      dataIndex: "status",
    },
    COMPANY_NAME: {
      title: "Company",
      dataIndex: "company_name",
    },
    TAGS_COLUMN: {
      title: "Tags",
      dataIndex: "tags",
    },
    IMAGES_COLUMN: {
      title: "Thumbnails",
      dataIndex: "thumbnails",
    },
  },
  STYLE_NAME_COLUMN: { color: "#39B54A", cursor: "pointer" },
  STYLE_PRIMARY_BTN: {
    marginLeft: "5px",
    background: "#FFFFFF",
    color: " #9B9B9B",
    border: "1px solid #d9d9d9",
  },
  STYLE_NAME_COLUMN_PARENT:{ padding: 8 },
  SET_STYLE_FILTER_ICON: (filtered) => {
    return { color: filtered && "#1890ff" }
  }
}

const ADD_IMAGE = {
  STYLE_LOADER: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999, // Make sure it's on top of other elements
    backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust opacity as needed
  },
  COMPANY_INITIAL_STATE:{
    data: [],
    loading: false,
  },
  STATUS_ARR: [
    {key: 'Pending', value: 'Suspend'},
    {key: 'Active', value: 'Active'},
    {key: 'Archive', value: 'Archive'}
  ],
  STYLE_MARGIN_TOP_15PX: { marginTop: '15px' },
  STYLE_DROP_FILE: { position: "relative", top: "-5px" },
  STYLE_PARAGRAPH_DROP_FILE: { color: '#d9d9d9', marginBottom: '10px' },
  UPLOAD_PROPS: (fileList) => {
    return {
      multiple: true,
      accept: '.jpeg, .png',
      fileList,
    }
  }
}

export {
  ADD_IMAGE,
  TABLE_IMAGE
}