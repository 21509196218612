import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, Form, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DrawersWrapper,
  Status,
  StatusWrapper,
  DrawerFormHeading,
} from "./styles";
import {
  DrawerTitle,
  MainTitle,
  ActionButton,
  DrawerBodyPadding,
  CloseBtnWrapper,
  SpinStyling,
} from "Styles/global";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import {
  GetSingleCampaign,
  EditSingleCampaign,
} from "Redux/App/Actions/Campaigns";
import { userProfileData } from "Redux/App";

function CampaignDrawer(props) {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [campaignDetail, setCampaignDetail] = useState({
    data: {},
    loading: false,
  });
  const userData = useSelector(userProfileData);

  useEffect(() => {
    if (props.drawerOpen.editCampaignName) {
      setCampaignDetail({ ...campaignDetail, data: {} });
      dispatch(
        GetSingleCampaign(
          campaignDetail,
          setCampaignDetail,
          props.drawerOpen.editCampaignName
        )
      );
    }
  }, [props.drawerOpen.editCampaignName]);

  useEffect(() => {
    form.setFieldsValue({
      campaign_name: campaignDetail?.data?.campaign_name,
      screen_type: campaignDetail?.data?.screen_type,
      current_description: campaignDetail?.data?.current_description,
      requested_changes: campaignDetail?.data?.requested_changes,
    });
  }, [campaignDetail.data]);
  const onFinish = (values) => {
    values.campaign_id = props.drawerOpen.editCampaignName;

    dispatch(
      EditSingleCampaign(
        {
          campaign_id: values.campaign_id,
          requested_changes: values.requested_changes,
        },
        props.drawerOpen,
        props.setDrawerOpen,
        null
      )
    );
  };
  const handleCancel = () => {
    props.setDrawerOpen({
      ...props.drawerOpen,
      CampaignDetailDrawer: false,
    });
  };

  return (
    <DrawersWrapper>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={handleCancel}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <StatusWrapper>
          <Status> {campaignDetail?.data?.status}</Status>
        </StatusWrapper>
        <MainTitle>Campaign details</MainTitle>
      </DrawerTitle>
      {campaignDetail.loading ? (
        <SpinStyling>
          <Spin />
        </SpinStyling>
      ) : (
        <Form onFinish={onFinish} form={form}>
          <DrawerBodyPadding>
            <DrawerFormHeading>Campaign</DrawerFormHeading>
            <Form.Item name="campaign_name">
              <Input disabled style={{ color: "black" }} />
            </Form.Item>

            <DrawerFormHeading>Screen Type</DrawerFormHeading>
            <Form.Item name="screen_type">
              <Input disabled style={{ color: "black" }} />
            </Form.Item>
            <DrawerFormHeading>Current Description</DrawerFormHeading>
            <Form.Item name="current_description">
              <TextArea rows={8} disabled style={{ color: "black" }} />
            </Form.Item>
            <DrawerFormHeading>Requested changes</DrawerFormHeading>

            <Form.Item
              name="requested_changes"
              rules={[
                {
                  required: true,
                  message: "Please enter requested changes",
                },
              ]}
            >
              <TextArea
                rows={4}
                disabled={
                  userData?.permission_level === "Admin" ||
                  userData?.permission_level === "Superuser"
                    ? false
                    : true
                }
              />
            </Form.Item>
            {userData?.permission_level !== "Creator" &&
              userData?.permission_level !== "Publisher" && (
                <Row className="action-buttons ">
                  <Col span="12">
                    <ActionButton
                      background="##FFFFFF;"
                      color=" #9B9B9B"
                      width="150"
                    >
                      <Button onClick={handleCancel}>Cancel</Button>
                    </ActionButton>
                  </Col>
                  <Col span="12">
                    <ActionButton
                      background="#39B54A"
                      color="#FFFFFF"
                      width="150"
                    >
                      <Button htmlType="submit">Submit</Button>
                    </ActionButton>
                  </Col>
                </Row>
              )}
          </DrawerBodyPadding>
        </Form>
      )}
    </DrawersWrapper>
  );
}

export default CampaignDrawer;
