
import { Modal } from "antd";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import AUTH_VALUES from "constants/Auth";

const TermsConditions = ({
    isModalOpen,
    handleOk,
    handleCancel,
    termsLoading,
    terms
}) => {
    return (
      <Modal
        title={AUTH_VALUES.TERM_CONDITION}
        open={isModalOpen}
        onOk={handleOk}
        closable={false}
        onCancel={handleCancel}
        cancelButtonProps={AUTH_VALUES.STYLE_MODAL}
      >
        {termsLoading ? (
        <LoadingSpinner />
        ) : (
        <div dangerouslySetInnerHTML={{ __html: terms }} />
        )}
    </Modal>

    )
}

export default TermsConditions;