import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { ADD_IMAGE } from "constants/Images";

const Loader = ({ loading }) => (
  loading && (
    <div
      style={ADD_IMAGE.STYLE_LOADER}
    >
      <LoadingSpinner size="large" />
    </div>
  )
);


export default Loader;