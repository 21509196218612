import {Button, Col, Row} from 'antd';
import {
  ActionButton,
} from "Styles/global";

const Submit = ({ handleClick }) => (
  <Row className="action-buttons ">
    <Col span="12">
      <ActionButton background="#FFF" color=" #9B9B9B" width="140">
        <Button onClick={handleClick}>Cancel</Button>
      </ActionButton>
    </Col>
    <Col span="12">
      <ActionButton background="#39B54A" color="#FFF" width="140">
        <Button htmlType="submit">Submit</Button>
      </ActionButton>
    </Col>
  </Row>

)

export default Submit;