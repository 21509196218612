
import { SETUP_VALUES } from "constants/Campaign";
import { Form, Input } from 'antd';

const ReqChange = () => (
  <Form.Item
    name={SETUP_VALUES.REQ_CHANGE_}
    label={SETUP_VALUES.REQ_CHANGE}
    className="campaign-form-labels"
  >
    <Input.TextArea rows={6} style={SETUP_VALUES.HEIGHT_150} />
  </Form.Item>
)

export default ReqChange;