const AUTH_CONST_VALUES = {
  EMAIL_ERROR_MSG: "Please input your Email!",
  PASSWORD_ERROR_MSG: "Please input your password!",
  RESET_PASS_TITLE: "Reset Password",
  LOGIN_MSG: "By clicking Login you agree to the",
  TERM_CONDITION: "Terms & Conditions",
  USAGE_OF: "of usage.",
  EMAIL_LABEL: "Email",
  PASS_LABEL: "Password",
  LOGIN_BTN_TEXT: "Login",
  API_TERMS_ERR: "Error fetching terms:",
  STYLE_MODAL: { style: { display: "none" } }
};

export const PASSWORD_VALIDATION_RULES = [
  {
    required: true,
    message: "Please enter password"
  },
  { min: 8, message: "Password must have a minimum length of 8" },
  { max: 64, message: "Password must have a maximum length of 64" },
  {
    pattern: new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.* ).{8,64}$"),
    message: "Password must contain at least one lowercase letter, uppercase letter, number, and special character"
  }
];

export const TOKEN_EXPIRED = "Token expired";

export default AUTH_CONST_VALUES;
