import styled from "styled-components";

export const DrawersWrapper = styled.div`
.avatar-wrapper{
    display:flex;
    justify-content:center;
}
.coupon-avatar{
    border:1px solid #f1d1d6;
    margin-top:-40px;
    filter: drop-shadow(0px 22px 30px rgba(0, 0, 0, 0.1));
}
`


