import { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { LoginCard, LoginWrapper, FormWrapper } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "Assets/Logos/LogoPrimary";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "Redux/App/Actions/Auth";
import { loading as stateLoading } from "Redux/App";
import { Link } from "react-router-dom";
import { EditPassword, ResetPassword } from "Redux/App/Actions/Users";
import { PASSWORD_VALIDATION_RULES } from "../../../constants/Auth";

const Login = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isResetPasswordPage, setIsResetPasswordPage] = useState(false);

  // Regular expression for email validation
  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setIsResetPasswordPage(emailPattern.test(email));
  }

  useEffect(() => {
    let authToken = sessionStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/");
    }
  }, [navigate]);
  useEffect(() => {
    if (isValidEmail(params.id)) {
      form.setFieldsValue({
        email: params.id
      });
    }
  }, [params]);

  const onFinish = (values) => {
    if (isResetPasswordPage) {
      dispatch(EditPassword(values, setLoading, drawerOpen, setDrawerOpen, navigate));
    } else {
      dispatch(
        ResetPassword(
          {
            token: params.id,
            new_password: values.password
          },
          setLoading,
          drawerOpen,
          setDrawerOpen,
          navigate
        )
      );
    }
  };
  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center" }}>
              <Logo width={80} />
            </div>
          </Col>
        </Row>
        <FormWrapper>
          <Form form={form} layout="vertical" className="FormWrapper" onFinish={onFinish}>
            {isResetPasswordPage && (
              <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
                <Input disabled />
              </Form.Item>
            )}
            <Form.Item label="Password" name="password" rules={PASSWORD_VALIDATION_RULES}>
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ textAlign: "center" }}>
                <Button style={{ color: "white", width: "160px" }} type="primary" htmlType="submit" loading={loading}>
                  Change password
                </Button>
              </div>
            </Form.Item>

            <Link to=""></Link>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default Login;
