import { Col, Input, Row, Button} from 'antd'
import { TABLE_IMAGE } from 'constants/Images'

const SearchCol = ({
  searchInput,
  dataIndex,
  selectedKeys,
  setSelectedKeys,
  handleSearch,
  confirm,
  buttonRef,
  clearFilters,
  handleReset,
}) => (
  <div style={TABLE_IMAGE.STYLE_NAME_COLUMN_PARENT}>
    <Row gutter={8}>
      <Col span="14">
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            display: "block",
          }}
        />
      </Col>
      <Col span="5">
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          Search
        </Button>
      </Col>
      <Col span="5">
        <Button
          ref={buttonRef}
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          type="primary"
          style={TABLE_IMAGE.STYLE_PRIMARY_BTN}
        >
          Clear
        </Button>
      </Col>
    </Row>
  </div>
)

export default SearchCol;