import LoadingSpinner from "Components/Shared/LoadingSpinner";

const Loader = ({ loading, STYLE }) => {
  if (loading ) {
    return (
      <div
        style={STYLE}
      >
        <LoadingSpinner size="large"/>
      </div>
    )
  }
    return null;
}

export default Loader;