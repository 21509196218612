import { min } from "moment";

export const TemplateActions = {
  ADD_TEMPLATE: "ADD_TEMPLATE",
  EDIT_TEMPLATE: "EDIT_TEMPLATE",
};


export const formatDate = (dateTimeString) => {
  const lessThan10 = (value) => {
    if (value < 10) return `0${value}`;
    return value;
  }
 
  const dateObj = new Date(dateTimeString);
  const seconds = dateObj.getSeconds();
  const miliSecs = dateObj.getMilliseconds();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const date = dateObj.getDate();
  const month = dateObj.getMonth() + 1; 
  const year = dateObj.getFullYear();

  return `${year}/${month}/${date}  ${lessThan10(hours)}:${lessThan10(minutes)}:${lessThan10(seconds)}.${lessThan10(miliSecs)}`;
}