import { TABLE_IMAGE } from "constants/Images";
import ImageThumbnail from "./ImageThumbnail";

const {TABLE_TITLES} = TABLE_IMAGE;

const makeColumn = (
  colTitle,
  getProps,
  title
) => {
  return {
    ...colTitle,
    ...getProps(title)
  }
}

const tableColumns = (getColumnSearchProps, nameColumnClick, handleImgClick) => {
  return [
    TABLE_TITLES.ID_COLUMN,
    {
      ...TABLE_TITLES.NAME_COLUMN,
      ...getColumnSearchProps('name'),
      render: (name, item) => {
        return (
          <span
            style={TABLE_IMAGE.STYLE_NAME_COLUMN}
            onClick={() => nameColumnClick(item)}
          >
            {name}
          </span>
        );
      },
    },
      makeColumn(TABLE_TITLES.STATUS_COLUMN, getColumnSearchProps,'status' ),
      makeColumn(TABLE_TITLES.COMPANY_NAME, getColumnSearchProps, 'company_name'),
      makeColumn(TABLE_TITLES.TAGS_COLUMN, getColumnSearchProps, 'tags'),
    {
      ...TABLE_TITLES.IMAGES_COLUMN,
      render: (_, record) => {
        return (
          <ImageThumbnail
            handleClick={() => handleImgClick(record)}
            url={`${record.thumbnail_url}`}
          />
        );
      },
    },
]};

export default tableColumns;