import { Form } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";
import getRequiredMsg from "utils/formRequiredMsg";
import DropDown from "../DropDown";

const CampType = ({onChange}) => (
    <Form.Item
      label={SETUP_VALUES.CAMP_TYPE}
      name={SETUP_VALUES.CAMP_TYPE_}
      className="campaign-form-labels"
      initialValue={SETUP_VALUES.CAMP_TYPE_ARR[0].key}
      rules={getRequiredMsg(SETUP_VALUES.ERR_CAMP_TYPE)}
    >
      <DropDown 
        valArray={SETUP_VALUES.CAMP_TYPE_ARR}
        onChange={onChange}
        defaultValue={SETUP_VALUES.CAMP_TYPE_ARR[0].key}
      />
  </Form.Item>
)

export default CampType