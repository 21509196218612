import getRequiredMsg from "utils/formRequiredMsg";
import { SETUP_VALUES } from "constants/Campaign";
import { Form, Input } from 'antd';

const BonusTags = ({ isRequired }) => (
  <Form.Item
    label={SETUP_VALUES.BONUS_TAGS}
    name={SETUP_VALUES.BONUS_TAGS_}
    className="campaign-form-labels"
    rules={getRequiredMsg(SETUP_VALUES.ERR_BONUS_TAGS, isRequired)}
    style={{ marginTop: '5px' }}
  >
    <Input />
  </Form.Item>
)

export default BonusTags;