import styled from "styled-components";

export const LayoutWrapper = styled.div`
  height: 100%;
  background: #e7e6e6;

  .ant-layout-content {
    background: #e7e6e6;
  }
  .ant-layout-sider-light .ant-layout-sider-trigger {
    left: 0;
    border-right: 1px solid #f0f0f0;
  }
  .ant-layout {
    background: #e7e6e6;
  }
  .content-padding {
    padding: 20px;
  }
  .col-adjust {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .header-bg-color {
    background-color: #ffffff;
  }
  .user-profile {
    text-align: right;
    padding-top: 20px;
    padding-bottom: 18px;
  }
  .user-profile > .ant-dropdown-menu {
    width: 50px;
  }
  .ant-layout-header {
    height: 72px;
  }
  .view-promotion-text {
    padding-top: 25px;
    padding-bottom: 24px;
  }
  .ant-menu-submenu-selected {
    background: #39b54a;
    color: white;
    border-radius: 10px;
  }

  .ant-menu-item-selected {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .ant-menu-submenu-selected > .ant-menu-title-content {
    color: white;
  }
  .ant-dropdown-menu {
    position: absolute !important;
    width: 150px !important;
    margin-left: -100px !important;
  }
`;
export const PageMainTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #3d3d3d;
  justify-items: center;
  margin-bottom: 0;
`;
export const ProfileBackground = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgba(58, 181, 74, 0.1);
`;
