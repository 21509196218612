import { Form, Input } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";

const RequestBy = ({userName, disabled}) => (
  <Form.Item
    name={SETUP_VALUES.REQ_BY_}
    label={SETUP_VALUES.REQ_BY}
    className="campaign-form-labels"
    initialValue={userName}
  >
    <Input disabled={disabled} />
  </Form.Item>
)

export default RequestBy;