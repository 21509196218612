import {
  ActionButton,
} from "Styles/global";
import CAMP_VALS from "constants/Campaign";
import { Button, Row, Col } from "antd";

const SubmitSection = ({handleCancel}) => {
  return (
    <Row className="action-buttons ">
      <Col span="12">
        <ActionButton background="##FFFFFF;" color=" #9B9B9B" width="150">
          <Button onClick={handleCancel}>{CAMP_VALS.CANCEL_BTN}</Button>
        </ActionButton>
      </Col>
      <Col span="12">
        <ActionButton background="#39B54A" color="#FFFFFF" width="150">
          <Button htmlType="submit">{CAMP_VALS.SUBMIT_BTN}</Button>
        </ActionButton>
      </Col>
    </Row>
  )
}

export default SubmitSection;