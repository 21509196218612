import React, { useState, useEffect, useRef, Fragment } from "react";
import { Table, Row, Col, Input, Button } from "antd";
import { UserTable, Name, UsersModalWrapper } from "./styles";
import { TableWrapper, PageSubTitle } from "Styles/global";
import { useDispatch, useSelector } from "react-redux";
import { data as stateData, loading as stateLoading, userProfileData } from "Redux/App";
import { GetUsers, SortUsers } from "Redux/App/Actions/Users";
import { companyName } from "Redux/App";
import UsersModal from "./UsersModal/UsersModal";
import UsersDrawer from "./UsersDrawer/UserDrawer";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import moment from "moment";
import NewPromotionIcon from "Assets/Icons/NewPromotionIcon";
import { getAllTimezones } from "Requests/timezone";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const selectedCompany = useSelector(companyName);
  const data = useSelector(stateData);
  const loading = useSelector(stateLoading);
  const me = useSelector(userProfileData);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [timezones, setTimezones] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState({
    screen: "",
    open: false
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let updatedData;

  if (Array.isArray(data)) {
    updatedData = data.map((item) => {
      return { ...item, key: item.id };
    });
  } else {
    updatedData = [];
  }
  const [userData, setUserData] = useState({
    data: [],
    loading: false
  });
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}>
        <Row gutter={8}>
          <Col span="14">
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                display: "block"
              }}
            />
          </Col>
          <Col span="5">
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
              Search
            </Button>
          </Col>
          <Col span="5">
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              type="primary"
              style={{
                marginLeft: "5px",
                background: "#FFFFFF",
                color: " #9B9B9B",
                border: "1px solid #d9d9d9"
              }}>
              Clear
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (text ? text.toString() : "") : text)
  });

  const columns = [
    {
      key: 1,
      title: "Name",
      dataIndex: "username",
      ...getColumnSearchProps("username"),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (a, item) => (
        <Name
          onClick={() => {
            setDrawerOpen({ ...drawerOpen, screen: "Edit User", open: true });
            setUserData({ ...userData, data: item });
          }}>
          <span className="link">{item?.username}</span>
        </Name>
      )
    },
    {
      key: 2,
      title: "Email Address",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (a, item) => <Name>{item?.email?.toLowerCase()}</Name>
    },
    {
      key: 3,
      title: "Permission Level",
      dataIndex: "permission_level",
      ...getColumnSearchProps("permission_level"),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],

      render: (a, item) => <Name>{item?.permission_level}</Name>
    },
    {
      key: 4,
      title: "Last Login",
      render: (a, item) => (
        <Name style={{ textTransform: "uppercase" }}>{moment(item?.last_login).format("YYYY/MM/DD")}</Name>
      )
    },
    {
      key: 5,
      title: "Timezone",
      render: (a, item) => <Name>{item?.time_zone}</Name>
    }
  ];

  useEffect(() => {
    if (selectedCompany) dispatch(GetUsers());
  }, [selectedCompany]);

  useEffect(() => {
    const fetchTimezones = async () => {
      const response = await getAllTimezones();
      setTimezones(response);
    };
    fetchTimezones();
  }, []);

  useEffect(() => {
    if (me) {
      const filterMyData = data.filter((it) => it.id === me.id)[0];
      if (!filterMyData) return;
      if (filterMyData.permission_level !== "Fmtv" && filterMyData.permission_level !== "Admin") {
        navigate("/promotions");
      }
    }
  }, [data]);

  return (
    <Fragment>
      <UsersModalWrapper>
        {/* <PageSubTitle>Users List</PageSubTitle> */}
        {selectedRowKeys.length > 0 && <UsersModal selectedRowKeys={selectedRowKeys} />}
      </UsersModalWrapper>
      <TableWrapper>
        <UserTable>
          <Table
            dataSource={Array.isArray(updatedData) ? updatedData : []}
            columns={columns}
            rowSelection={rowSelection}
            loading={!Array.isArray(updatedData) || loading}
            pagination={{
              showSizeChanger: true, // Enable the dropdown
              pageSizeOptions: ["10", "20", "50", "100"], // Define the available options
              defaultPageSize: 10 // Set the default page size
            }}
            onChange={(a, b, c) => {
              let order = "";

              if (c.order === "ascend") {
                order = "asc";
              } else if (c.order) {
                order = "desc";
              }
              if (c.field) dispatch(SortUsers(c.field, order, selectedCompany));
            }}
          />
        </UserTable>
        {!drawerOpen.open && (
          <div
            className="new-promotion"
            onClick={() =>
              setDrawerOpen({
                ...drawerOpen,
                screen: "Add New User",
                open: true
              })
            }>
            <NewPromotionIcon />
          </div>
        )}
      </TableWrapper>
      <UsersDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} userData={userData} timezones={timezones} />
    </Fragment>
  );
};

export default Users;
