import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./App";
import { promotionsReducer, workflowReducer } from "./App/dataSlice";
import { logsReducer } from "./App/Reducers/Logs";
import { systemSettingsReducer } from "./App/Reducers/System";

const store = configureStore({
  reducer: {
    app: appReducer,
    promotions: promotionsReducer,
    workflow: workflowReducer,
    logs: logsReducer,
    systemSettings: systemSettingsReducer
  },
});

export default store;
