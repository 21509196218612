import styled from "styled-components";

export const TemplateWrapper = styled.div`
.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  border-radius: 4px;
  border: 1px solid  transparent!important; /* Button border color */
  background-color: transparent !important; /* Button background color */
  color: #a9a9a9;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  margin: 0;
  border-radius: 7px;
  color: rgb(255, 255, 255)!important ;
}

 .ant-tabs-tab-btn:active{
  margin: 0;
  border-radius: 4px;
  border: 0px !important; /* Button border color */
  background-color: transparent !important; /* Button background color */
  color: rgb(255, 255, 255)!important ; /* Button text color */
}
 
.ant-tabs-nav .ant-tabs-tab-active{
  margin: 0;
  border-radius: 4px !important;
  background-color: rgb(57, 181, 74) !important;
}

.ant-tabs-nav-wrap{
  left: -1px;
  padding: 7px;
  background: rgba(58,181,74,0.1);
  margin: 32px;
  border-radius: 5px;
}
`;

export const DrawerBodyPadding = styled.div`
  padding: 12px 24px;
  .ant-input {
    height: 46px;
    border-radius: 4px;
  }
  .ant-select {
    margin-top: 10px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 46px;
    border-radius: 4px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-top: 6px;
  }
  .ant-form-item-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #3d3d3d;
  }
`;

export const ChoosFile = styled.span`
font-size:13px;
 background: #39b54a;
 border: 1px sold #39b54a;
  border-radius: 20px;
   padding: 10px;
    margin: 15px;
     color: rgb(255, 255, 255);
      width: 100px;

`;

export const DrawerTabs = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 10px;
  padding: 10px;
  margin: 20px 60px;
  background-color: red;

  span {
    background: rgb(57, 181, 74) !important;
    color: white;
    padding: 15px 30px;
    width: 130px;
    border-radius: 5px;
  }
`
