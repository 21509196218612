import {
  DrawerTitle,
  MainTitle,
  CloseBtnWrapper,
} from "Styles/global";
import CAMP_VALS from "constants/Campaign";
import CloseBtnIcon from "Assets/Icons/CloseBtn";

const DrawerTitleComponent = ({handleCancel}) => {
  return (
    <DrawerTitle height={118}>
      <CloseBtnWrapper onClick={handleCancel}>
        <CloseBtnIcon />
      </CloseBtnWrapper>
      <MainTitle>{CAMP_VALS.CREATE_CAMP}</MainTitle>
    </DrawerTitle>
  )
}

export default DrawerTitleComponent;