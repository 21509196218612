import {Modal} from 'antd';

const MainModal = ({isModalOpen, handleOk, handleCancel}) => (
  <Modal
    title="Company name change confirmation"
    open={isModalOpen}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    <p>
      Turning off couponing will pause any promotion with an associated
      coupon
    </p>
  </Modal>
)

export default MainModal;