import React from 'react';
import { Select } from 'antd';

const DropDown = ({ valArray, ...props }) => {
  return (
    <Select {...props}>
      {valArray.map(({ key, value }) => (
        <Select.Option key={key} value={key}>
          {value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DropDown;
