import { createSlice } from "@reduxjs/toolkit";

const promotionsInitialState = {
  data: [],
};

const workflowInitialState = {
  data: [],
};

// Promotions slice
const promotionsSlice = createSlice({
  name: "promotions",
  initialState: promotionsInitialState,
  reducers: {
    setPromotionsData(state, { payload }) {
      state.data = payload;
    },
    // other actions...
  },
});

// Workflow slice
const workflowSlice = createSlice({
  name: "workflow",
  initialState: workflowInitialState,
  reducers: {
    setWorkflowData(state, { payload }) {
      state.data = payload;
    },
    // other actions...
  },
});

export const {
  setPromotionsData,
  // other actions...
} = promotionsSlice.actions;

export const {
  setWorkflowData,
  // other actions...
} = workflowSlice.actions;

export const promotionsData = (state) => state.promotions.data;
export const workflowData = (state) => state.workflow.data;

export const promotionsReducer = promotionsSlice.reducer;
export const workflowReducer = workflowSlice.reducer;
