import {Row, Col, Button} from 'antd';
import {
  ActionButton,
} from 'Styles/global';

const SubmitSection = ({handleCancelClick}) => (
  <Row className="action-buttons ">
  <Col span="12">
    <ActionButton background="#FFFFFF" color=" #9B9B9B" width="140">
      <Button onClick={handleCancelClick}>
        Cancel
      </Button>
    </ActionButton>
  </Col>
  <Col span="12">
    <ActionButton background="#39B54A" color="#FFFFFF" width="140">
      <Button htmlType="submit">Submit</Button>
    </ActionButton>
  </Col>
</Row>
)

export default SubmitSection;