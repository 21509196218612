import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthorized: false,
  loading: false,
  hasErrors: false,
  data: [],
  userProfile: null,
  selectedCompany: "",
  isTemplate: false,
  isEditor: false,
  isModalOpened: false,
  errorMessage: "",
  selectCompanyCompaign: ""
};

const appReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTheModalOpened: (state) => {
      state.isModalOpened = true;
    },
    setTheModalClose: (state) => {
      state.isModalOpened = false;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    requestStart: (state) => {
      state.loading = true;
    },
    requestCompleted: (state) => {
      state.loading = false;
    },
    requestSuccess(state, { payload }) {
      if (payload) state.data = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    requestFailure(state) {
      state.loading = false;
      state.hasErrors = true;
    },
    setSearchQuery(state, { payload }) {
      state.searchQuery = payload;
    },
    resetData(state) {
      state.data = [];
      state.userProfile = null;
    },
    setUserProfile(state, { payload }) {
      state.userProfile = payload;
    },
    setCompany(state, { payload }) {
      state.selectedCompany = payload;
    },
    setCompanySelected(state, { payload }) {
      state.selectCompanyCompaign = payload;
    },

    setIsTemplate(state, { payload }) {
      state.isTemplate = payload;
    },
    setIsEditor(state, { payload }) {
      state.isEditor = payload;
    }
  }
});

export const data = (state) => state.app.data;
export const loading = (state) => state.app.loading;
export const searchQuery = (state) => state.app.searchQuery;
export const searchResults = (state) => state.app.searchResults;
export const userProfileData = (state) => state.app.userProfile;
export const companyName = (state) => state.app.selectedCompany;
export const isTemplate = (state) => state.app.isTemplate;
export const isEditor = (state) => state.app.isEditor;
export const isModalOpened = (state) => state.app.isModalOpened;
export const isErrorMessage = (state) => state.app.errorMessage;
export const selectedCompaign = (state) => state.app.selectCompanyCompaign;

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  requestCompleted,
  setSearchQuery,
  resetData,
  setUserProfile,
  setCompany,
  setIsTemplate,
  setIsEditor,
  setCompanySelected,
  setTheModalOpened,
  setTheModalClose,
  setErrorMessage
} = appReducer.actions;

export default appReducer.reducer;
