import { Form, Input } from "antd";
import { SETUP_VALUES } from "constants/Campaign";

const VistarAdvertiserOverride = () => (
  <Form.Item
    label={SETUP_VALUES.ADVERTISER_OVERRIDE}
    name={SETUP_VALUES.ADVERTISER_OVERRIDE_}
    className="campaign-form-labels">
    <Input />
  </Form.Item>
);
export default VistarAdvertiserOverride;
