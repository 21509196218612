import {
  DrawerTitle,
  MainTitle,
  CloseBtnWrapper,
} from 'Styles/global';
import CloseBtnIcon from 'Assets/Icons/CloseBtn';

const TitleSection = ({screen, handleClick}) => (
  <DrawerTitle height={118}>
    <CloseBtnWrapper onClick={handleClick}>
      <CloseBtnIcon />
    </CloseBtnWrapper>
    <MainTitle>
      <p className="main-title">{screen}</p>
    </MainTitle>
  </DrawerTitle>
)

export default TitleSection;