import { Form, Input } from 'antd';
import { SETUP_VALUES } from "constants/Campaign";
import getRequiredMsg from "utils/formRequiredMsg";

const OrderLineID = ({ required }) => (
  <Form.Item
    label={SETUP_VALUES.ORDER_LINE_ID}
    name={SETUP_VALUES.ORDER_LINE_ID_}
    className="campaign-form-labels"
    rules={getRequiredMsg(SETUP_VALUES.ERR_ORDER_LINE_ID, required)}
  >
    <Input />
  </Form.Item>
)
export default OrderLineID;