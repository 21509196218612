import React, { useEffect, useState, useRef } from "react";
import { Table } from "antd";
import { TableWrapper } from "Styles/global";
import { useDispatch, useSelector } from "react-redux";
import { loading as stateLoading, data as stateData } from "Redux/App";
import { GetImageTemplates } from "Redux/App/Actions/ImageTemplates";
import AddTemplateDrawer from "./AdddTemplate";
import { TABLE_IMAGE } from "constants/Images";
import ImgTableModal from "./table/Modal";
import tableColumns from "./table/columns";
import getColumnSearchPropsFn from "./table/getColumnProps";
import NewPromoIcon from "./table/NewPromoIcon";
import FileNameModal from "Components/Shared/FileNameModal/FileNameModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { ExportButton } from "Pages/Setting/styles";

// File Type
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const VideoLibrary = () => {
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const data = useSelector(stateData);
  const tableLoading = useSelector(stateLoading);
  const [drawerOpen, setDrawerOpen] = useState(TABLE_IMAGE.DRAWER_INITIAL_VALUE);
  const [promoVideo, setPromoVideo] = useState(TABLE_IMAGE.PROMO_INITIAL_STATE);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [openFileModal, setOpenFileModal] = useState(false);
  const [exportedData, setExportedData] = useState(null);
  const [fileName, setFileName] = useState(`Image_Libraries_${moment().format("YYYYMMDD_HHmmss")}`);

  // Exporting
  const exportToCSV = (csvData, fileName) => {
    const myHeader = ["Id", "Name", "Status", "Company", "Tags"];
    // Extension
    const fileExtension = ".xlsx";

    const finalData = csvData.map((item) => {
      return {
        Id: item.id,
        Name: item.name,
        Status: item.status,
        Company: item.company_name,
        Tags: item?.tags
      };
    });

    const ws = XLSX.utils.json_to_sheet(finalData, { header: myHeader });

    const wb = {
      Sheets: { Image_Libraries: ws },
      SheetNames: ["Image_Libraries"]
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const e_data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(e_data, fileName + fileExtension);
    setOpenFileModal(false);
    setFileName(`Image_Libraries_${moment().format("YYYYMMDD_HHmmss")}`);
  };

  const clickExport = () => {
    setOpenFileModal(true);
  };

  const handleExport = () => {
    const finalData = exportedData ? exportedData : data;
    exportToCSV(finalData, fileName ?? "Image_Libraries");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) =>
    getColumnSearchPropsFn(searchInput, dataIndex, handleSearch, buttonRef, handleReset, searchedColumn);

  useEffect(() => {
    dispatch(GetImageTemplates());
  }, [dispatch]);

  const handleImgClick = (record) => {
    setModalVisible(true);
    setPromoVideo(data.find((element) => element.id === record.id));
  };

  const nameColumnClick = (item) => {
    setSelectedTemplate(item);
    setDrawerOpen({
      ...drawerOpen,
      screen: "Edit Image",
      open: true
    });
  };

  const columns = tableColumns(getColumnSearchProps, nameColumnClick, handleImgClick);

  const handleModalOk = () => {
    setPromoVideo({ ...promoVideo, data: "", mediaType: "" });
    setModalVisible(false);
  };

  const handleNewPromoIconClick = () =>
    setDrawerOpen({
      ...drawerOpen,
      screen: "Upload Image",
      open: true
    });

  const handleSortChange = (pagination, filters, sorter, extra) => {
    setExportedData(extra.currentDataSource);
  };

  return (
    <TableWrapper>
      <FileNameModal
        fileName={fileName}
        setFileName={setFileName}
        open={openFileModal}
        handleOk={handleExport}
        handleCancel={() => setOpenFileModal(false)}
      />
      <NewPromoIcon open={!drawerOpen.open} handleClick={handleNewPromoIconClick} />
      <div style={{ textAlign: "end", marginBottom: "10px" }}>
        <ExportButton onClick={clickExport}>Export Table</ExportButton>
      </div>
      <Table
        loading={tableLoading || !Array.isArray(data)}
        dataSource={Array.isArray(data) ? data : []}
        columns={columns}
        onChange={handleSortChange}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          defaultPageSize: 10
        }}
      />
      <AddTemplateDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} selectedTemplate={selectedTemplate} />
      <ImgTableModal open={modalVisible} onOk={handleModalOk} promoVideo={promoVideo} />
    </TableWrapper>
  );
};

export default VideoLibrary;
