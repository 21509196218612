import axios from "axios";
import { requestStart, requestCompleted, requestSuccess } from "../..";
import { setWorkflowData } from "Redux/App/dataSlice";
import { notification } from "antd";
export function GetWorkflowData() {
  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/workflow`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            dispatch(setWorkflowData(res.data));
            dispatch(requestCompleted());
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetSortedWorkflowData(column, order, offset = 0, limit = 100) {
  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/workflow/sort/${column}?order=${order}&offset=${offset}&limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res) {
            dispatch(setWorkflowData(res.data));
            dispatch(requestCompleted());
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function EditStatus(values, sendEmail = true) {
  const finalValues = values.map((it) => {
    if (it?.status === "done" && sendEmail) {
      return { ...it, send_email: true };
    }
    return it;
  });
  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + `/workflow/editstatus`, finalValues, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            dispatch(GetWorkflowData());
            dispatch(requestSuccess());
            notification.success({
              message: "Status updated successfully"
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function SendEmail(id) {
  return async (dispatch, state) => {
    dispatch(requestStart());
    try {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + `/workflow/sendemail?workflow_id=${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {})
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
