export default function SmartHomeIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="smart-home-icon"
    >
      <path
        d="M11.7332 15.04H7.59989C7.32655 15.04 7.09989 14.8133 7.09989 14.54C7.09989 14.2667 7.32655 14.04 7.59989 14.04H11.7332C12.4466 14.04 13.1466 13.4466 13.2666 12.74L14.1532 7.43332C14.2332 6.95332 13.9666 6.26661 13.5932 5.96661L8.97322 2.27333C8.43988 1.84666 7.55323 1.84666 7.02656 2.26666L2.40655 5.96661C2.02655 6.27327 1.76655 6.95332 1.84655 7.43332L2.03322 8.55995C2.07988 8.83328 1.89322 9.09327 1.61989 9.13327C1.34655 9.18661 1.09323 8.99328 1.04656 8.71994L0.859896 7.59999C0.719896 6.77332 1.1199 5.71333 1.7799 5.18666L6.39989 1.48663C7.29323 0.766626 8.69989 0.773299 9.59989 1.4933L14.2199 5.18666C14.8732 5.71333 15.2732 6.77332 15.1399 7.59999L14.2532 12.9066C14.0532 14.0866 12.9266 15.04 11.7332 15.04Z"
        fill="#9B9B9B"
      />
      <path
        d="M3.33346 15.1667C3.06013 15.1667 2.84013 14.9467 2.83346 14.68C2.81346 13.78 2.22013 13.1867 1.32013 13.1667C1.0468 13.16 0.826794 12.9333 0.833461 12.6533C0.840127 12.38 1.06013 12.1667 1.33346 12.1667C1.34013 12.1667 1.34012 12.1667 1.34679 12.1667C2.78012 12.2 3.80679 13.22 3.83346 14.6533C3.84013 14.9267 3.62012 15.16 3.34679 15.1667C3.34012 15.1667 3.34013 15.1667 3.33346 15.1667Z"
        fill="#9B9B9B"
      />
      <path
        d="M5.33346 15.1667C5.06012 15.1667 4.83346 14.9467 4.83346 14.6734C4.82679 14.2334 4.75345 13.8134 4.62012 13.42C4.28012 12.4467 3.55345 11.7267 2.58012 11.38C2.18679 11.24 1.76678 11.1667 1.32678 11.1667C1.05345 11.1667 0.82679 10.94 0.833456 10.66C0.833456 10.3867 1.06012 10.1667 1.33346 10.1667H1.34013C1.89346 10.1734 2.42012 10.2667 2.91345 10.44C4.17345 10.8867 5.11345 11.8267 5.56012 13.0867C5.73345 13.58 5.82679 14.1133 5.83346 14.66C5.83346 14.94 5.61346 15.1667 5.33346 15.1667C5.34012 15.1667 5.33346 15.1667 5.33346 15.1667Z"
        fill="#9B9B9B"
      />
      <path
        d="M1.33335 15.3333C0.96002 15.3333 0.666687 15.0333 0.666687 14.6667C0.666687 14.3 0.966687 14 1.33335 14C1.70002 14 2.00002 14.3 2.00002 14.6667C2.00002 15.0333 1.70669 15.3333 1.33335 15.3333Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
