import { SETUP_VALUES } from "constants/Campaign";
import { Form, Input } from 'antd';

const CurrentDescription = () => (
  <Form.Item
    name={SETUP_VALUES.CUR_DESC_}
    className="campaign-form-labels"
    label={SETUP_VALUES.CUR_DESC}
  >
    <Input.TextArea rows={6} style={SETUP_VALUES.HEIGHT_150} />
  </Form.Item>
)

export default CurrentDescription;